import { Stack, Tab, Tabs, Typography } from "@mui/material";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { type RenderServiceWithDeploysEntity } from "../../generated/graphql";

type ServiceGroup = {
  services: RenderServiceWithDeploysEntity[];
  isUniformBranch: boolean;
};

type ServiceGroups = {
  staging: ServiceGroup;
  release: ServiceGroup;
  preview: ServiceGroup;
  stable: ServiceGroup;
  other: ServiceGroup;
};

const ServiceEnvGrouping = ({
  groupedServices,
}: {
  readonly groupedServices: ServiceGroups;
}) => {
  const [selectedTab, setSelectedTab] =
    useState<keyof ServiceGroups>("staging");

  const selectedGroup = groupedServices[selectedTab];

  const header = (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        border: 1,
        borderBottom: 0,
        borderColor: "#E9ECEF",
        backgroundColor: "#f8f9fa",
        p: 0.5,
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
          color: selectedGroup.isUniformBranch ? "inherit" : "red",
        }}
      >
        {selectedGroup.isUniformBranch
          ? `All services are on the ${selectedGroup.services[0]?.branch} branch.`
          : `Services are on different branches.`}
      </Typography>
    </Stack>
  );

  const renderDataTable = (services: RenderServiceWithDeploysEntity[]) => (
    <DataTable value={services} header={header}>
      <Column field="name" header="Name" style={{ width: "35%" }} />
      <Column field="branch" header="Branch" style={{ width: "35%" }} />
      <Column
        sortable
        field="updatedAt"
        header="Last Updated"
        body={(rowData) => new Date(rowData.updatedAt).toLocaleString()}
        style={{ width: "20%" }}
      />
    </DataTable>
  );

  return (
    <div>
      <Tabs
        value={selectedTab}
        aria-label="Service Environment Tabs"
        onChange={(e, value) => {
          setSelectedTab(value);
        }}
      >
        <Tab label="Staging" value="staging" />
        <Tab label="Release" value="release" />
        <Tab label="Preview" value="preview" />
        <Tab label="Stable" value="stable" />
        <Tab label="Other" value="other" />
      </Tabs>
      {selectedTab === "staging" &&
        renderDataTable(groupedServices.staging.services)}
      {selectedTab === "release" &&
        renderDataTable(groupedServices.release.services)}
      {selectedTab === "preview" &&
        renderDataTable(groupedServices.preview.services)}
      {selectedTab === "stable" &&
        renderDataTable(groupedServices.stable.services)}
      {selectedTab === "other" &&
        renderDataTable(groupedServices.other.services)}
    </div>
  );
};

export default ServiceEnvGrouping;
