import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { isEmpty, isNil } from "lodash";
import { type Dispatch, type SetStateAction } from "react";
import { type SearchSchemasQuery } from "../../../generated/graphql";
import useSchemaStore from "../schema-testing/schema-store";

const ConfirmBulkMatchDialog = ({
  open,
  setOpen,
  selectedSchemas,
}: {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly selectedSchemas: SearchSchemasQuery["searchSchemas"];
}) => {
  const matchSchemaDocument = useSchemaStore(
    (state) => state.matchSchemaDocument,
  );
  const schemasToMatch = selectedSchemas.filter(
    (s) => s.isActive && !isNil(s.firstTestSchemaDocument),
  );

  const schemasUnableToMatch = selectedSchemas.filter(
    (s) => !s.isActive || isNil(s.firstTestSchemaDocument),
  );

  const onConfirm = () => {
    for (const s of schemasToMatch) {
      if (!isNil(s.firstTestSchemaDocument)) {
        matchSchemaDocument(s, s.firstTestSchemaDocument);
      }
    }
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="sm"
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle sx={{ fontWeight: "bold" }}>Bulk match schemas</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          {!isEmpty(schemasToMatch) && (
            <Stack>
              <Typography sx={{ fontWeight: "bold" }}>
                {schemasToMatch.length} schema(s) will be run:
              </Typography>
              <Box sx={{ maxHeight: "200px", overflowY: "scroll" }}>
                <Table size="small">
                  <TableBody>
                    {schemasToMatch.map((s) => (
                      <TableRow key={s.uuid}>
                        <TableCell>{s.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Stack>
          )}
          {!isEmpty(schemasUnableToMatch) && (
            <Stack>
              <Typography sx={{ fontWeight: "bold" }}>
                {schemasUnableToMatch.length} schema(s) cannot be run:
              </Typography>
              <Box sx={{ maxHeight: "200px", overflowY: "scroll" }}>
                <Table size="small">
                  <TableBody>
                    {schemasUnableToMatch.map((s) => (
                      <TableRow key={s.uuid}>
                        <TableCell>{s.name}</TableCell>
                        <TableCell>
                          <Typography color="error" variant="caption">
                            {}
                            {s.isActive
                              ? isNil(s.firstTestSchemaDocument)
                                ? "No test documents"
                                : ""
                              : "Inactive"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button variant="contained" onClick={onConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmBulkMatchDialog;
