import { isNil } from "lodash";
import { useState } from "react";
import { filterNotNil } from "shared/array";
import {
  type ScannedOrderFromAdminCreateInput,
  useCreateScannedOrdersFromAdminMutation,
} from "../../../generated/graphql";
import useSchemaStore from "./schema-store";
import { type Schema, type SchemaDocument } from "./types";

const useScannedOrders = () => {
  const [errorMessage, setErrorMessage] = useState<string | null | undefined>();
  const [
    createScannedOrdersFromAdmin,
    { loading: loadingCreateScannedOrders },
  ] = useCreateScannedOrdersFromAdminMutation();
  const addScanSchemaDocumentJob = useSchemaStore(
    (state) => state.addScanSchemaDocumentJob,
  );

  const createScannedOrders = async ({
    schemaDocuments,
  }: {
    schemaDocuments: Array<{
      schemaDocument: SchemaDocument;
      schema: Schema;
    }>;
  }) => {
    const scannedOrderCreateInputs: ScannedOrderFromAdminCreateInput[] =
      filterNotNil(
        schemaDocuments.map(({ schemaDocument: doc }) => {
          if (isNil(doc.company)) return null;
          return {
            bucket: doc.bucket,
            key: doc.key,
            filename: doc.key.split("/").at(-1) ?? "",
            filetype: "application/pdf",
            companyUuid: doc.company.uuid,
          };
        }),
      );
    const res = await createScannedOrdersFromAdmin({
      variables: {
        createScannedOrdersFromAdminInput: {
          scannedOrderCreateInputs,
        },
      },
    });
    if (!isNil(res.errors)) {
      setErrorMessage(
        `Failed to create scanned orders ${res.errors[0]?.message}`,
      );
      return;
    }
    for (const [
      i,
      createScannedOrderResult,
    ] of res.data?.createScannedOrdersFromAdmin.entries() ?? []) {
      if (isNil(createScannedOrderResult.scannedOrderEntity)) {
        setErrorMessage(
          isNil(errorMessage)
            ? createScannedOrderResult.error
            : `${errorMessage}\n${createScannedOrderResult.error ?? ""}`,
        );
      } else {
        addScanSchemaDocumentJob({
          schema: schemaDocuments[i]?.schema,
          schemaDocument: schemaDocuments[i]?.schemaDocument,
          scannedOrderUuid: createScannedOrderResult.scannedOrderEntity.uuid,
        });
      }
    }
  };

  const createScannedOrderFromSchema = async ({
    schemaDocument,
    schema,
  }: {
    schemaDocument: SchemaDocument | null | undefined;
    schema: Schema | null | undefined;
  }) => {
    if (isNil(schemaDocument) || isNil(schema)) return;
    await createScannedOrders({
      schemaDocuments: [{ schemaDocument, schema }],
    });
  };

  return {
    createScannedOrders,
    createScannedOrderFromSchema,
    loadingCreateScannedOrders,
    errorMessage,
    setErrorMessage,
  };
};

export default useScannedOrders;
