import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { get, isNil } from "lodash";
import React from "react";

const getString = (
  json: any,
  {
    path,
    isAddress,
    isContactPerson,
    isPackages,
  }: {
    path: string[];
    isAddress?: boolean;
    isContactPerson?: boolean;
    isPackages?: boolean;
  },
) => {
  const object = get(json, path);
  if (isNil(object)) return "";

  if (!isNil(isAddress)) {
    return (
      <Stack>
        <span>{object.name}</span>
        <span>{object.line1}</span>
        <span>{object.line2}</span>
        <span>
          {object.city}, {object.state ?? "-"} {object.zip}
        </span>
      </Stack>
    );
  }

  if (!isNil(isContactPerson)) {
    return `${object.firstName ?? ""} ${object.lastName ?? ""} - ${object.phone ?? ""}`;
  }

  if (!isNil(isPackages)) {
    return (
      <Stack>
        {object?.map((package_: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Typography key={index} variant="caption">
            {package_.quantity ?? "-"} / {package_.length ?? "-"}x
            {package_.width ?? "-"}x{package_.height ?? "-"} /{" "}
            {package_.weight ?? ""}lbs / {package_.description ?? ""}
          </Typography>
        ))}
      </Stack>
    );
  }

  return get(json, path);
};

const OrderScanFormattedPayloadResult = ({ json }: { readonly json: any }) => {
  const stopPath = ["shipments", "0", "legs", "0", "endStop"];
  const columns = [
    {
      name: "Contact",
      path: ["billingPartyContact", "displayName"],
    },
    {
      name: "HAWB",
      path: ["standardOrderFields", "shipperBillOfLadingNumber"],
    },
    {
      name: "MAWB",
      path: ["standardOrderFields", "masterAirwayBillOfLadingNumber"],
    },
    {
      name: "Stop type",
      path: ["shipments", "0", "standardShipmentFields", "pickupOrDelivery"],
    },
    {
      name: "Deadline date",
      path: ["shipments", "0", "standardShipmentFields", "deadlineDate"],
    },
    {
      name: "Service date",
      path: [...stopPath, "serviceDate"],
    },
    {
      name: "Special instructions",
      path: [...stopPath, "specialInstructions"],
    },
    {
      name: "Shipper Address",
      path: [...stopPath, "shipperAddress"],
      isAddress: true,
    },
    {
      name: "Shipper Contact Person",
      path: [...stopPath, "shipperContactPerson"],
      isContactPerson: true,
    },
    {
      name: "Consignee Address",
      path: [...stopPath, "consigneeAddress"],
      isAddress: true,
    },
    {
      name: "Consignee Contact Person",
      path: [...stopPath, "consigneeContactPerson"],
      isContactPerson: true,
    },
    {
      name: "Packages",
      path: ["packages"],
      isPackages: true,
    },
  ];

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Field</TableCell>
          <TableCell>Result value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {columns.map((c, index: number) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={index}>
              <TableCell>{c.name}</TableCell>
              <TableCell>{getString(json, c)}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default OrderScanFormattedPayloadResult;
