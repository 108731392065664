import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import {
  OutlookIntegrationsDocument,
  type OutlookIntegrationsQuery,
  useUpdateOutlookIntegrationMutation,
} from "../../../generated/graphql";

const OutboundEmailDomainsDialog = ({
  isOpen,
  onClose,
  outlookIntegration,
}: {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly outlookIntegration: OutlookIntegrationsQuery["outlookIntegrations"][0];
}) => {
  const [outboundEmailDomains, setOutboundEmailDomains] = useState<string[]>(
    outlookIntegration.outboundEmailDomains,
  );
  const [updateOutlookIntegration] = useUpdateOutlookIntegrationMutation({
    refetchQueries: [OutlookIntegrationsDocument],
  });

  const onSave = () => {
    updateOutlookIntegration({
      variables: {
        outlookIntegrationUpdateInput: {
          uuid: outlookIntegration.uuid,
          outboundEmailDomains,
        },
      },
    });
    onClose();
  };

  return (
    <Dialog fullWidth open={isOpen} maxWidth="xs" onClose={onClose}>
      <DialogTitle sx={{ fontWeight: "bold" }}>Blacklisted emails</DialogTitle>
      <Stack sx={{ px: 3 }} spacing={1}>
        {outboundEmailDomains.map((blacklistedEmail, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <Stack key={idx} direction="row">
            <TextField
              sx={{ width: "100%" }}
              size="small"
              value={blacklistedEmail}
              onChange={(e) => {
                setOutboundEmailDomains((prev) => {
                  const newDomains = [...prev];
                  newDomains[idx] = e.target.value;
                  return newDomains;
                });
              }}
            />
            <IconButton
              onClick={() => {
                setOutboundEmailDomains((prev) => {
                  const newDomains = [...prev];
                  newDomains.splice(idx, 1);
                  return newDomains;
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        ))}
        <Button
          onClick={() => {
            setOutboundEmailDomains((prev) => {
              const newDomains = [...prev];
              newDomains.push("");
              return newDomains;
            });
          }}
        >
          Add
        </Button>
      </Stack>
      <DialogActions>
        <Button onClick={onSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OutboundEmailDomainsDialog;
