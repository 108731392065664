import { Button, Grid, Link, Stack } from "@mui/material";
import dayjs from "dayjs";
import { isEmpty, isNil } from "lodash";
import { Column, type ColumnBodyOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import {
  type OutlookIntegrationsQuery,
  useOutlookIntegrationsQuery,
} from "../../../generated/graphql";
import CompanySelect, { GLOBAL_SCHEMA_NO_COMPANY } from "../company-select";
import CreateOutlookIntegrationDialog from "./create-outlook-integration-dialog";
import OutboundEmailDomainsDialog from "./outbound-email-domains-dialog";

const OutlookIntegrations = () => {
  const [companyUuid, setCompanyUuid] = useState<string>();
  const [selectedOutlookIntegration, setSelectedOutlookIntegration] = useState<
    OutlookIntegrationsQuery["outlookIntegrations"][0] | undefined
  >();
  const [
    showCreateOutlookIntegrationDialog,
    setShowCreateOutlookIntegrationDialog,
  ] = useState<boolean>(false);
  const { data: outlookIntegrationsData, loading } =
    useOutlookIntegrationsQuery({
      variables: {
        getOutlookIntegrationsInput: {
          companyUuid:
            companyUuid === GLOBAL_SCHEMA_NO_COMPANY ? null : companyUuid,
        },
      },
    });

  const dateFormatter = (
    rowData: OutlookIntegrationsQuery["outlookIntegrations"][0],
    option: ColumnBodyOptions,
  ) => {
    return isNil(rowData[option.field as keyof typeof rowData])
      ? undefined
      : dayjs(rowData[option.field as keyof typeof rowData] ?? "").format(
          "MM/DD/YY",
        );
  };

  const listFormatter = (
    rowData: OutlookIntegrationsQuery["outlookIntegrations"][0],
  ) => {
    return (
      <Link
        sx={{ cursor: "pointer" }}
        onClick={() => {
          setSelectedOutlookIntegration(rowData);
        }}
      >
        {isEmpty(rowData.outboundEmailDomains) ? "-" : ""}
        {rowData.outboundEmailDomains.slice(0, 3).join(", ")}
        {rowData.outboundEmailDomains.length > 3
          ? ` + ${rowData.outboundEmailDomains.length - 3}`
          : ""}
      </Link>
    );
  };

  return (
    <Grid container sx={{ p: 2 }} spacing={1}>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between">
          <CompanySelect
            allowNoCompany
            noLabel
            currentCompanyUuid={companyUuid}
            onChange={(option) => {
              setCompanyUuid(option?.value);
            }}
          />
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setShowCreateOutlookIntegrationDialog(true);
            }}
          >
            Create
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <DataTable
          removableSort
          showGridlines
          scrollable
          stripedRows
          value={outlookIntegrationsData?.outlookIntegrations}
          sortMode="multiple"
          rows={10}
          loading={loading}
          dataKey="id"
          emptyMessage="No data"
          size="small"
          scrollHeight="80vh"
          virtualScrollerOptions={{ itemSize: 25 }}
        >
          <Column
            sortable
            dataType="date"
            field="createdAt"
            header="Created at"
            body={dateFormatter}
          />
          <Column
            sortable
            filter
            field="company.name"
            header="Company"
            filterPlaceholder="Search by company"
          />
          <Column field="entraUserId" header="Email" />
          <Column field="entraTenantId" header="Tenant ID" />
          <Column field="terminal.name" header="Terminal" />
          <Column
            dataType="date"
            field="emailCreatedAtStartDate"
            header="Start ingesting from"
            body={dateFormatter}
          />
          <Column
            field="outboundEmailDomains"
            header="Blacklisted emails"
            body={listFormatter}
          />
        </DataTable>
      </Grid>
      {!isNil(selectedOutlookIntegration) && (
        <OutboundEmailDomainsDialog
          isOpen={!isNil(selectedOutlookIntegration)}
          outlookIntegration={selectedOutlookIntegration}
          onClose={() => {
            setSelectedOutlookIntegration(undefined);
          }}
        />
      )}
      {showCreateOutlookIntegrationDialog && (
        <CreateOutlookIntegrationDialog
          isOpen={showCreateOutlookIntegrationDialog}
          onClose={() => {
            setShowCreateOutlookIntegrationDialog(false);
          }}
        />
      )}
    </Grid>
  );
};

export default OutlookIntegrations;
