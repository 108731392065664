import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { isNil } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import SftpUploadsTable from "../components/sftp-uploads-table";
import {
  SftpUploadStatus,
  useCompaniesQuery,
  useCompanyContactsLazyQuery,
  useSftpUploadsLazyQuery,
} from "../generated/graphql";
import { RequireAuth } from "../hooks/use-auth";

const uploads_page_size = 10;

const SftpUploadsPage = () => {
  const [canGoPrevious, setCanGoPrevious] = useState(false);
  const [canGoNext, setCanGoNext] = useState(false);
  const [companyUuid, setCompanyUuid] = useState<string | null>(null);
  const [contactUuid, setContactUuid] = useState<string | null>(null);
  const [status, setStatus] = useState<SftpUploadStatus | null>(null);
  const { data: companiesData } = useCompaniesQuery();
  const [
    findSftpUploads,
    { data: SftpUploadsData, loading: SftpUploadsLoading, refetch },
  ] = useSftpUploadsLazyQuery();
  const [findCompanyContacts, { data: contactsData }] =
    useCompanyContactsLazyQuery();
  const companyOptions = useMemo(() => {
    return companiesData?.companies?.map((company) => {
      return {
        value: company.uuid,
        label: company.name,
      };
    });
  }, [companiesData]);
  const contactOptions = useMemo(() => {
    return contactsData?.companyContacts?.map((contact) => {
      return {
        value: contact.uuid,
        label: contact.displayName,
      };
    });
  }, [contactsData]);
  const statusMap = (statusTypes: SftpUploadStatus) => {
    switch (statusTypes) {
      case SftpUploadStatus.Completed: {
        return "Completed";
      }
      case SftpUploadStatus.Failed: {
        return "Failed";
      }
      case SftpUploadStatus.InProgress: {
        return "InProgress";
      }
      case SftpUploadStatus.Retried: {
        return "Retried";
      }
    }
    return "Unkown Status";
  };
  const statusList = [
    SftpUploadStatus.Completed,
    SftpUploadStatus.Failed,
    SftpUploadStatus.InProgress,
    SftpUploadStatus.Retried,
  ];
  const statusOptions = statusList.map((statusOp) => {
    return {
      value: statusOp,
      label: statusMap(statusOp),
    };
  });

  type FetchSftpUploadsParameters = {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
  };

  const FetchSftpUploads = async ({
    first,
    after,
    last,
    before,
  }: FetchSftpUploadsParameters) => {
    await findSftpUploads({
      variables: {
        companyUuid,
        contactUuid,
        status,
        first,
        after,
        last,
        before,
      },
    });
  };

  useEffect(() => {
    FetchSftpUploads({ first: uploads_page_size });
  }, [companyUuid, contactUuid, status]);

  useEffect(() => {
    if (!isNil(companyUuid)) {
      findCompanyContacts({
        variables: { companyUuid, excludeNonActiveContacts: true },
      });
    }
  }, [companyUuid, findCompanyContacts]);

  const handlePreviousPage = async () => {
    await FetchSftpUploads({
      last: uploads_page_size,
      before: SftpUploadsData?.sftpUploads.pageInfo.startCursor,
    });
    setCanGoNext(true);
    setCanGoPrevious(false);
  };
  const handleNextPage = async () => {
    await FetchSftpUploads({
      first: uploads_page_size,
      after: SftpUploadsData?.sftpUploads.pageInfo.endCursor,
    });
    setCanGoPrevious(true);
    setCanGoNext(false);
  };

  const refetchUploads = async () => {
    await refetch();
  };

  const paginationButtons = (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Button
          disabled={
            (!canGoPrevious &&
              SftpUploadsData?.sftpUploads.pageInfo.hasPreviousPage !== true) ||
            SftpUploadsLoading
          }
          variant="contained"
          sx={{ mr: 2 }}
          startIcon={<ArrowLeft />}
          onClick={handlePreviousPage}
        >
          Previous Page
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          disabled={
            (!canGoNext &&
              SftpUploadsData?.sftpUploads.pageInfo.hasNextPage !== true) ||
            SftpUploadsLoading
          }
          variant="contained"
          sx={{ mr: 2 }}
          startIcon={<ArrowRight />}
          onClick={handleNextPage}
        >
          Next page
        </Button>
      </Grid>
    </Grid>
  );
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" component="div">
          SFTP Uploads
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" component="div">
          Select Company
        </Typography>
        <Select
          options={companyOptions}
          placeholder="Select company"
          onChange={(option) => {
            if (!isNil(option) && !isNil(option.value)) {
              setCompanyUuid(option.value);
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" component="div">
          Select Contact
        </Typography>
        <Select
          options={contactOptions}
          placeholder="Select contact"
          onChange={(option) => {
            if (!isNil(option) && !isNil(option.value)) {
              setContactUuid(option.value);
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" component="div">
          Select Status
        </Typography>
        <Select
          options={statusOptions}
          placeholder="Select status"
          onChange={(option) => {
            if (!isNil(option) && !isNil(option.value)) {
              setStatus(option.value);
            }
          }}
        />
      </Grid>
      <Grid item xs={6}>
        {paginationButtons}
      </Grid>
      {SftpUploadsLoading ? (
        <CircularProgress />
      ) : (
        <Grid item xs={12}>
          <RequireAuth>
            <SftpUploadsTable
              uploads={SftpUploadsData?.sftpUploads.edges}
              refetch={refetchUploads}
            />
          </RequireAuth>
        </Grid>
      )}
    </Grid>
  );
};

export default SftpUploadsPage;
