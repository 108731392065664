import { useEffect, useState } from "react";
import Modal from "react-modal";
import Select from "react-select";
import {
  type FieldMatcherEntity,
  MatcherType,
} from "../../../generated/graphql";
import { enumKeys } from "../../../utils/enum";

type EditFieldMatcherModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly fieldMatcher: FieldMatcherEntity | undefined;
  readonly handleSave: ({
    type,
    value,
  }: {
    type: MatcherType;
    value: string;
  }) => void;
};

const EditFieldMatcherModal = ({
  isOpen,
  onClose,
  handleSave,
  fieldMatcher,
}: EditFieldMatcherModalProps) => {
  const [matcherType, setMatcherType] = useState<MatcherType>();
  const [matcherValue, setMatcherValue] = useState<string>("");

  useEffect(() => {
    if (isOpen && fieldMatcher) {
      setMatcherType(fieldMatcher.type);
      setMatcherValue(fieldMatcher.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const matcherTypeOptions = enumKeys(MatcherType).map((key) => ({
    value: MatcherType[key],
    label: MatcherType[key],
  }));

  return (
    <Modal
      contentLabel="Edit field matcher"
      style={{
        content: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "600px",
          width: "800px",
        },
      }}
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={onClose}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "black",
        }}
      >
        <h3>Select extractor type</h3>
        <Select
          required
          options={matcherTypeOptions}
          value={matcherTypeOptions.find(
            (option) => option.value === matcherType,
          )}
          styles={{
            control: (base) => ({
              ...base,
              width: "max-content",
              minWidth: "100%",
            }),
            menu: (base) => ({
              ...base,
              width: "max-content",
              minWidth: "100%",
            }),
          }}
          onChange={(option) => {
            if (option) {
              setMatcherType(option.value);
            }
          }}
        />
        <h3>Set value</h3>
        <input
          required
          value={matcherValue}
          onChange={(e) => {
            setMatcherValue(e.target.value);
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <button
          style={{ marginRight: "10px", height: "30px" }}
          onClick={() => {
            if (matcherType == null) {
              alert("All values are required");
            } else {
              handleSave({
                type: matcherType,
                value: matcherValue,
              });
            }
          }}
        >
          Save changes
        </button>
        <button onClick={onClose}>Discard</button>
      </div>
    </Modal>
  );
};

export default EditFieldMatcherModal;
