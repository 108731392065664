import { ApolloProvider } from "@apollo/client";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/saga-blue/theme.css";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import apolloClient from "./apollo-client";
import AccessorialImporterPage from "./pages/accessorial-importer/accessorial-importer-page";
import CodeGenPage from "./pages/code-gen-page";
import CompanyConfigurationsPage from "./pages/company-configurations/company-configurations-page";
import ContactsImporterPage from "./pages/contacts-importer/contact-importer-page";
import CrownChargesValidatorPage from "./pages/crown-charges-validator/crown-charges-validator-page";
import DocsPage from "./pages/docs-page";
import DriversImporterPage from "./pages/drivers-importer/driver-importer-page";
import EdiPage from "./pages/edi/edi-page";
import ViewEdiMessagesPage from "./pages/edi/view/view-edi-messages-page";
import HomePage from "./pages/home-page";
import InvoiceEmailsPage from "./pages/invoice-emails/invoice-emails-page";
import LoginPage from "./pages/login-page";
import OrdersImporterPage from "./pages/orders-importer/order-importer-page";
import RenderPage from "./pages/render-page";
import { SchemaRunPage } from "./pages/schema-run-page";
import { SchemaTestListPage } from "./pages/schema-test-list-page";
import SftpUploadsPage from "./pages/sftp-uploads-page";
import StorybookPage from "./pages/storybook";
import TariffImporterPage from "./pages/tariff-importer/tariff-importer-page";
import TestOrderEditPage from "./pages/test-orders/[uuid]/test-order-edit-page";
import TestOrderCreatePage from "./pages/test-orders/create/test-order-create-page";
import TestOrderPage from "./pages/test-orders/test-order-page";
import UsersImporterPage from "./pages/users-importer/user-importer-page";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/home",
    element: <HomePage />,
  },
  {
    path: "/docs",
    element: <DocsPage />,
  },
  {
    path: "/edi",
    element: <EdiPage />,
  },
  {
    path: "/edi/view",
    element: <ViewEdiMessagesPage />,
  },
  {
    path: "/invoice-transmissions",
    element: <InvoiceEmailsPage />,
  },
  {
    path: "/code-gen",
    element: <CodeGenPage />,
  },
  {
    path: "/render",
    element: <RenderPage />,
  },
  {
    path: "/sftp-uploads",
    element: <SftpUploadsPage />,
  },
  {
    path: "/test-orders",
    element: <TestOrderPage />,
  },
  {
    path: "/test-orders/create",
    element: <TestOrderCreatePage />,
  },
  {
    path: "/test-orders/:uuid",
    element: <TestOrderEditPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/schema-runs/:uuid",
    element: <SchemaRunPage />,
  },
  {
    path: "/schema-test-lists",
    element: <SchemaTestListPage />,
  },
  {
    path: "/schema-test-lists/:uuid",
    element: <SchemaTestListPage />,
  },
  {
    path: "/company-configurations",
    element: <CompanyConfigurationsPage />,
  },
  {
    path: "/crown-charges-validator",
    element: <CrownChargesValidatorPage />,
  },
  {
    path: "/contacts-importer",
    element: <ContactsImporterPage />,
  },
  {
    path: "/tariff-importer",
    element: <TariffImporterPage />,
  },
  {
    path: "/accessorial-importer",
    element: <AccessorialImporterPage />,
  },
  {
    path: "/orders-importer",
    element: <OrdersImporterPage />,
  },
  {
    path: "/users-importer",
    element: <UsersImporterPage />,
  },
  {
    path: "/drivers-importer",
    element: <DriversImporterPage />,
  },
  {
    path: "/storybook",
    element: <StorybookPage />,
  },
]);

const container = document.querySelector("#root");
const root = createRoot(container!);
root.render(
  <PrimeReactProvider>
    <ApolloProvider client={apolloClient}>
      <RouterProvider router={router} />
    </ApolloProvider>
  </PrimeReactProvider>,
);
