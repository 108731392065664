import ClearAllIcon from "@mui/icons-material/ClearAll";
import {
  IconButton,
  Stack,
  Table,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { sumBy } from "lodash";
import React, { useEffect, useState } from "react";
import {
  ScannedOrderResultStatus,
  useScannedOrderResultsForAdminLazyQuery,
} from "../../../../generated/graphql";
import { filterNotNil } from "../../../../utils/array";
import useSchemaStore from "../schema-store";
import ScanJobTableRow from "./scan-job-table-row";

const rowsPerPage = 5;

type CursorArgs = {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
};

const DocScanningScanJobTable = ({
  isMinimized,
}: {
  readonly isMinimized: boolean;
}) => {
  const scanSchemaDocumentJobs = useSchemaStore(
    (state) => state.scanSchemaDocumentJobs,
  );
  const clearAllScanSchemaDocumentJobs = useSchemaStore(
    (state) => state.clearAllScanSchemaDocumentJobs,
  );
  const [uuidInput, setUuidInput] = useState<string | undefined>();
  const [
    getScannedOrderResults,
    { data: scannedOrderResultsForAdminData, startPolling, stopPolling },
  ] = useScannedOrderResultsForAdminLazyQuery();
  const [page, setPage] = useState<number>(0);

  const scannedOrderResultsData =
    scannedOrderResultsForAdminData?.scannedOrderResultsForAdmin;

  const uuidsToFetch = filterNotNil([
    uuidInput?.length === 36 ? uuidInput : null,
    ...scanSchemaDocumentJobs.map((s) => s.resultScannedOrderUuid),
  ]);

  const fetchScannedOrderResults = async (
    args: CursorArgs & {
      scannedOrderUuids: string[];
      orderUuids: string[];
    },
  ) => {
    await getScannedOrderResults({
      variables: {
        args,
      },
    });
  };

  useEffect(() => {
    startPolling(7000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  useEffect(() => {
    fetchScannedOrderResults({
      first: rowsPerPage,
      scannedOrderUuids: uuidsToFetch,
      orderUuids: uuidsToFetch,
    });
    setPage(0);
  }, [uuidsToFetch.length]);

  const prev = async (newPage: number) => {
    await fetchScannedOrderResults({
      last: rowsPerPage,
      before: scannedOrderResultsData?.pageInfo?.startCursor ?? undefined,
      scannedOrderUuids: uuidsToFetch,
      orderUuids: uuidsToFetch,
    });
    setPage(newPage);
  };
  const next = async (newPage: number) => {
    await fetchScannedOrderResults({
      first: rowsPerPage,
      after: scannedOrderResultsData?.pageInfo?.endCursor ?? undefined,
      scannedOrderUuids: uuidsToFetch,
      orderUuids: uuidsToFetch,
    });
    setPage(newPage);
  };

  const countFinished = sumBy(scannedOrderResultsData?.edges, (e) =>
    e.node.status === ScannedOrderResultStatus.InProgress ? 0 : 1,
  );
  const countFailed = sumBy(scannedOrderResultsData?.edges, (e) =>
    e.node.status === ScannedOrderResultStatus.Failed ? 1 : 0,
  );
  const countTotal = scannedOrderResultsData?.edges.length ?? 0;

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack>
          <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
            Scan
            {countFinished === countTotal ? "ned" : "ning"} Documents{" "}
            {countTotal > 0 ? `(${countFinished} / ${countTotal})` : ""}
          </Typography>
          {countFailed > 0 && (
            <Typography variant="caption" color="error">
              {countFailed} failed
            </Typography>
          )}
        </Stack>
        <Stack direction="row" alignItems="center">
          {!isMinimized && (
            <>
              <TextField
                sx={{ width: 230 }}
                label="UUID (scanned or order)"
                size="small"
                value={uuidInput ?? ""}
                onChange={(e) => {
                  setUuidInput(e.target.value);
                }}
              />
              <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={scannedOrderResultsData?.totalCount ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  disabled: page === 0,
                }}
                nextIconButtonProps={{
                  disabled:
                    page + 1 ===
                    Math.ceil(
                      (scannedOrderResultsData?.totalCount ?? 0) / rowsPerPage,
                    ),
                }}
                onPageChange={(e, newPage: number) => {
                  if (newPage > page) {
                    next(newPage);
                  } else {
                    prev(newPage);
                  }
                }}
              />
            </>
          )}
        </Stack>
      </Stack>
      {!isMinimized && (
        <Stack spacing={1} sx={{ mb: 2 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Created at</TableCell>
                <TableCell>Schema</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>File name</TableCell>
                <TableCell>HAWB</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Result</TableCell>
                <TableCell />
                <TableCell>
                  <Tooltip title="Clear All">
                    <IconButton
                      onClick={() => {
                        clearAllScanSchemaDocumentJobs();
                        setUuidInput(undefined);
                      }}
                    >
                      <ClearAllIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            {scannedOrderResultsData?.edges.map(
              ({ node: scannedOrderResult }) => {
                const scanSchemaDocumentJob = scanSchemaDocumentJobs?.find(
                  (job) =>
                    job.resultScannedOrderUuid ===
                    scannedOrderResult.scannedOrder.uuid,
                );
                return (
                  <ScanJobTableRow
                    key={scannedOrderResult.uuid}
                    scannedOrderResult={scannedOrderResult}
                    scanSchemaDocumentJob={scanSchemaDocumentJob}
                  />
                );
              },
            )}
          </Table>
        </Stack>
      )}
    </>
  );
};

export default DocScanningScanJobTable;
