import { Typography } from "@mui/material";
import { isNil } from "lodash";
import React, { useMemo } from "react";
import { type FieldError } from "react-hook-form";
import Select from "react-select";
import { useCompaniesQuery } from "../../generated/graphql";
import { type SelectOption } from "./schema-testing/types";

export const GLOBAL_SCHEMA_NO_COMPANY = "GLOBAL_SCHEMA_NO_COMPANY";

const CompanySelect = ({
  currentCompanyUuid,
  onChange,
  error,
  isText = false,
  allowNoCompany = false,
  noLabel = false,
  minWidth,
  maxWidth,
}: {
  readonly currentCompanyUuid: string | undefined | null;
  readonly onChange: (option: SelectOption | undefined | null) => void;
  readonly error?: FieldError | undefined;
  readonly isText?: boolean;
  readonly allowNoCompany?: boolean;
  readonly noLabel?: boolean;
  readonly minWidth?: number;
  readonly maxWidth?: number;
}) => {
  const { data: companiesData } = useCompaniesQuery({
    fetchPolicy: "cache-first",
  });

  const companyOptions = useMemo(() => {
    const options = companiesData?.companies.map((company) => {
      return {
        value: company.uuid,
        label: company.name,
      };
    });
    if (allowNoCompany) {
      options?.unshift({
        value: GLOBAL_SCHEMA_NO_COMPANY,
        label: "All",
      });
    }
    return options;
  }, [allowNoCompany, companiesData?.companies]);

  const currentOption = companyOptions?.find(
    (option) =>
      option.value === currentCompanyUuid ||
      (allowNoCompany &&
        option.value === GLOBAL_SCHEMA_NO_COMPANY &&
        isNil(currentCompanyUuid)),
  );

  if (isText) {
    return <Typography>{currentOption?.label}</Typography>;
  }

  return (
    <div style={{ minWidth, maxWidth, fontWeight: "normal" }}>
      {!noLabel && <span>Select company</span>}

      <Select
        menuPosition="fixed"
        options={companyOptions}
        placeholder="Select company"
        value={currentOption}
        onChange={(option) => {
          onChange(option);
        }}
      />
      {!isNil(error) && (
        <Typography sx={{ color: "red", fontSize: 12 }}>
          Company is required
        </Typography>
      )}
    </div>
  );
};

export default React.memo(CompanySelect);
