import { Box, Button, Grid, Stack, TextField } from "@mui/material";
import { debounce, isEmpty, isNil } from "lodash";
import { type Dispatch, type SetStateAction, useEffect, useState } from "react";
import { useSearchSchemasQuery } from "../../../generated/graphql";
import CompanySelect, { GLOBAL_SCHEMA_NO_COMPANY } from "../company-select";
import AddSchemaCompanyMappingModal from "../schema-testing/schema-company-mappings/add-schema-company-mapping-modal";
import { type SelectOption } from "../schema-testing/types";
import ConfirmBulkMatchDialog from "./confirm-bulk-match-dialog";
import SchemaTable, { type Schema } from "./schema-table";

const SchemaList = ({
  companyUuid,
  setCompanyUuid,
  setSchemaUuidToGoTo,
}: {
  readonly companyUuid: string | undefined;
  readonly setCompanyUuid: Dispatch<SetStateAction<string | undefined>>;
  readonly setSchemaUuidToGoTo: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const [showBulkMatchDialog, setShowBulkMatchDialog] =
    useState<boolean>(false);
  const [showCreateSchemaMappingDialog, setShowCreateSchemaMappingDialog] =
    useState<boolean>(false);
  const [selectedSchemas, setSelectedSchemas] = useState<Schema[]>([]);
  const { data: schemasData, refetch: searchSchemas } = useSearchSchemasQuery();

  const companyUuidToSearch =
    companyUuid === GLOBAL_SCHEMA_NO_COMPANY ? null : companyUuid;

  useEffect(() => {
    searchSchemas({
      companyUuid: companyUuidToSearch,
    });
  }, [companyUuidToSearch]);

  const onSearchSchema = debounce((value: string) => {
    searchSchemas({
      searchText: value,
      companyUuid: companyUuidToSearch,
    });
  }, 200);

  const schemas = schemasData?.searchSchemas;

  return (
    <Stack sx={{ p: 2 }} spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={1} sx={{ zIndex: 200 }}>
          <TextField
            size="small"
            label="Search"
            onChange={(e) => {
              onSearchSchema(e.target.value);
            }}
          />
          <Box sx={{ minWidth: 200 }}>
            <CompanySelect
              allowNoCompany
              noLabel
              currentCompanyUuid={companyUuid}
              onChange={(option: SelectOption | undefined | null) => {
                setCompanyUuid(option?.value);
              }}
            />
          </Box>
          <Button
            disabled={isNil(companyUuid) || isEmpty(selectedSchemas)}
            onClick={() => {
              setShowBulkMatchDialog(true);
            }}
          >
            Match
          </Button>
        </Stack>
        <Stack direction="row" spacing={1} onClick={() => {}}>
          <Button
            variant="contained"
            onClick={() => {
              setShowCreateSchemaMappingDialog(true);
            }}
          >
            Create
          </Button>
        </Stack>
      </Stack>
      <Grid container>
        <Grid item xs={12} sx={{ maxHeight: "87vh", overflowY: "scroll" }}>
          <SchemaTable
            companyUuid={companyUuid}
            schemas={schemas ?? []}
            selectedSchemas={selectedSchemas}
            setSelectedSchemas={setSelectedSchemas}
            setSchemaUuidToGoTo={setSchemaUuidToGoTo}
          />
        </Grid>
      </Grid>
      {showBulkMatchDialog && (
        <ConfirmBulkMatchDialog
          open={showBulkMatchDialog}
          setOpen={setShowBulkMatchDialog}
          selectedSchemas={selectedSchemas}
        />
      )}
      {showCreateSchemaMappingDialog && (
        <AddSchemaCompanyMappingModal
          open={showCreateSchemaMappingDialog}
          setOpen={setShowCreateSchemaMappingDialog}
          initialCompanyUuid={companyUuid}
        />
      )}
    </Stack>
  );
};

export default SchemaList;
