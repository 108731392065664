import { isEmpty, isNil } from "lodash";
import { type Resolver, useForm } from "react-hook-form";
import { StopType } from "../../generated/graphql";

export type ImportedOrderTypeMappingFormValues = {
  // uuid: string;
  moveType: string;
  serviceLevel: string;
  inboundStopType: StopType;
  outboundStopType: StopType;
  lineHaul: boolean;
  defaultToInbound: boolean;
  defaultToOutbound: boolean;
};

const resolver: Resolver<ImportedOrderTypeMappingFormValues> = async (
  formValues,
) => {
  const errors: {
    [property in keyof ImportedOrderTypeMappingFormValues]?: {
      type: string;
      message: string;
    };
  } = {};

  const {
    inboundStopType,
    outboundStopType,
    lineHaul,
    defaultToInbound,
    defaultToOutbound,
  } = formValues;

  if (isNil(inboundStopType) || isEmpty(inboundStopType)) {
    errors.inboundStopType = {
      type: "required",
      message: "Inbound Stop Type is required",
    };
  }

  if (isNil(outboundStopType) || isEmpty(outboundStopType)) {
    errors.outboundStopType = {
      type: "required",
      message: "Outbound Stop Type is required",
    };
  }

  if (isNil(lineHaul)) {
    errors.lineHaul = {
      type: "required",
      message: "Line Haul is required",
    };
  }

  if (isNil(defaultToInbound)) {
    errors.defaultToInbound = {
      type: "required",
      message: "Default To Inbound is required",
    };
  }

  if (isNil(defaultToOutbound)) {
    errors.defaultToOutbound = {
      type: "required",
      message: "Default To Outbound is required",
    };
  }

  if (
    !defaultToInbound &&
    !defaultToOutbound &&
    (inboundStopType !== StopType.None || outboundStopType !== StopType.None)
  ) {
    errors.defaultToInbound = {
      type: "required",
      message:
        "defaultToInbound or defaultToOutbound must be true for orders with a stop",
    };
    errors.defaultToOutbound = {
      type: "required",
      message:
        "defaultToInbound or defaultToOutbound must be true for orders with a stop",
    };
  }

  return {
    values: formValues,
    errors,
  };
};

const useImportOrdersForm = () => {
  return useForm({ resolver });
};

export default useImportOrdersForm;
