import { Tab, Tabs } from "@mui/material";
import { isNil } from "lodash";
import type React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TabPanel from "../components/common/tab-panel/tab-panel";
import Logs from "../components/doc-scanning/logs/logs";
import OutlookIntegrations from "../components/doc-scanning/outlook-integrations/outlook-integrations";
import Docs from "../components/doc-scanning/schema-editing/docs";
import SchemaList from "../components/doc-scanning/schema-list/schema-list";
import DocScanningMatchingProgress from "../components/doc-scanning/schema-testing/job-progress/doc-scanning-job-progress";
import Statistics from "../components/doc-scanning/stats/statistics";
import { useCompaniesQuery, useSchemasQuery } from "../generated/graphql";
import { RequireAuth } from "../hooks/use-auth";

enum DocScanningTab {
  SCHEMA_EDITOR,
  SCHEMA_LIST,
  OUTLOOK_INTEGRATIONS,
  STATISTICS,
  LOGS,
}

const DocsPage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { data: schemasData } = useSchemasQuery({
    fetchPolicy: "cache-first",
  });
  const { data: companiesData } = useCompaniesQuery({
    fetchPolicy: "cache-first",
  });
  const [tab, setTab] = useState<DocScanningTab>(DocScanningTab.SCHEMA_EDITOR);
  const [companyUuid, setCompanyUuid] = useState<string | undefined>(undefined);
  const [selectedSchemaUuid, setSelectedSchemaUuid] = useState<
    string | undefined
  >(undefined);
  const query = new URLSearchParams(search);

  useEffect(() => {
    const currentTab = query.get("tab");
    if (!isNil(currentTab)) {
      setTab(Number.parseInt(currentTab, 10));
    }
  }, [query]);

  return (
    <RequireAuth>
      <Tabs
        value={tab}
        onChange={(event: React.SyntheticEvent, newValue: number) => {
          setTab(newValue);
          navigate(`/docs/?tab=${newValue}`);
        }}
      >
        <Tab value={DocScanningTab.SCHEMA_EDITOR} label="Schema Editor" />
        <Tab value={DocScanningTab.SCHEMA_LIST} label="Schema List" />
        <Tab
          value={DocScanningTab.OUTLOOK_INTEGRATIONS}
          label="Outlook Integrations"
        />
        <Tab value={DocScanningTab.STATISTICS} label="Statistics" />
        <Tab value={DocScanningTab.LOGS} label="Logs" />
      </Tabs>
      <TabPanel panelValue={DocScanningTab.SCHEMA_EDITOR} selectedValue={tab}>
        <Docs
          selectedSchemaUuid={selectedSchemaUuid}
          setSelectedSchemaUuid={setSelectedSchemaUuid}
          companies={companiesData?.companies}
          schemas={schemasData?.schemas}
        />
      </TabPanel>
      <TabPanel panelValue={DocScanningTab.SCHEMA_LIST} selectedValue={tab}>
        {tab === DocScanningTab.SCHEMA_LIST && (
          <SchemaList
            companyUuid={companyUuid}
            setCompanyUuid={setCompanyUuid}
            setSchemaUuidToGoTo={setSelectedSchemaUuid}
          />
        )}
      </TabPanel>
      <TabPanel
        panelValue={DocScanningTab.OUTLOOK_INTEGRATIONS}
        selectedValue={tab}
      >
        {tab === DocScanningTab.OUTLOOK_INTEGRATIONS && <OutlookIntegrations />}
      </TabPanel>
      <TabPanel panelValue={DocScanningTab.STATISTICS} selectedValue={tab}>
        {tab === DocScanningTab.STATISTICS && <Statistics />}
      </TabPanel>
      <TabPanel panelValue={DocScanningTab.LOGS} selectedValue={tab}>
        {tab === DocScanningTab.LOGS && <Logs />}
      </TabPanel>
      <DocScanningMatchingProgress />
    </RequireAuth>
  );
};

export default DocsPage;
