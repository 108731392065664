import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { isEmpty } from "lodash";
import ReactJson from "react-json-view";

type ViewEdiPayloadDialogProps = {
  readonly open: boolean;

  readonly onClose: () => void;

  readonly payload: string;
};

const ViewEdiPayloadDialog = ({
  open,
  onClose,
  payload,
}: ViewEdiPayloadDialogProps) => {
  if (isEmpty(payload)) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>EDI Payload</DialogTitle>
      <DialogContent>
        <ReactJson src={JSON.parse(payload)} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewEdiPayloadDialog;
