import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import { startCase, toLower } from "lodash";
import { useState } from "react";
import {
  EdiTransactionCode,
  type EdiTransactionsByCompanyQuery,
} from "../../generated/graphql";
import ViewEdiPayloadDialog from "./view-edi-payload-dialog";

type EdiMessagesTableProps = {
  readonly ediTransactionsData?: EdiTransactionsByCompanyQuery;
};

const mapEDITransactionCodeToEvent = (code: EdiTransactionCode) => {
  switch (code) {
    case EdiTransactionCode.MotorCarrierLoadTender: {
      return "Load Tender (204)";
    }
    case EdiTransactionCode.MotorCarrierBillOfLading: {
      return "Bill of Lading (211)";
    }
    case EdiTransactionCode.ResponseToALoadTender: {
      return "Response to Load Tender (990)";
    }
    case EdiTransactionCode.TransportationCarrierShipmentStatusMessage: {
      return "Shipment Status Update (214)";
    }
    case EdiTransactionCode.MotorCarrierFreightDetailsAndInvoice: {
      return "Invoice (210)";
    }
  }
  return "Unknown Transaction";
};

const INBOUND_EDI_TRANSACTIONS = new Set([
  EdiTransactionCode.MotorCarrierLoadTender,
  EdiTransactionCode.MotorCarrierBillOfLading,
]);

const EdiMessagesTable = ({ ediTransactionsData }: EdiMessagesTableProps) => {
  const [ediPayloadDialogOpen, setEdiPayloadDialogOpen] = useState(false);
  const [ediPayload, setEdiPayload] = useState("");

  return (
    <Grid container spacing={2}>
      <ViewEdiPayloadDialog
        open={ediPayloadDialogOpen}
        payload={ediPayload}
        onClose={() => {
          setEdiPayloadDialogOpen(false);
        }}
      />
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableCell>EDI Transaction</TableCell>
            <TableCell>Sent At</TableCell>
            <TableCell>Direction</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Order</TableCell>
            <TableCell>Shipper Bill of Lading #</TableCell>
            <TableCell>Reference #</TableCell>
            <TableCell />
          </TableHead>
          <TableBody>
            {ediTransactionsData?.ediTransactionsByCompany.edges.map(
              ({ node: ediTransaction }) => {
                return (
                  <TableRow key={ediTransaction.uuid}>
                    <TableCell>
                      {mapEDITransactionCodeToEvent(ediTransaction.code)}
                    </TableCell>
                    <TableCell>
                      {dayjs(ediTransaction.updatedAt).format(
                        "MM/DD/YYYY [at] hh:mmA",
                      )}
                    </TableCell>
                    <TableCell>
                      {INBOUND_EDI_TRANSACTIONS.has(ediTransaction.code)
                        ? "Received"
                        : "Sent"}
                    </TableCell>
                    <TableCell>
                      {startCase(toLower(ediTransaction.status))}
                    </TableCell>
                    <TableCell>{ediTransaction.order?.name}</TableCell>
                    <TableCell>
                      {
                        ediTransaction.order?.standardOrderFields
                          .shipperBillOfLadingNumber
                      }
                    </TableCell>
                    <TableCell>
                      {ediTransaction.order?.secondaryRefNumber}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setEdiPayload(ediTransaction.payload);
                          setEdiPayloadDialogOpen(true);
                        }}
                      >
                        View Payload
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              },
            )}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};
export default EdiMessagesTable;
