import { Grid, Stack, Tab, Tabs, TextField } from "@mui/material";
import { debounce, sortBy } from "lodash";
import type React from "react";
import { useState } from "react";
import { useCompaniesQuery } from "../../../generated/graphql";
import TabPanel from "../../common/tab-panel/tab-panel";
import CompanyStatisticsCard from "./company-statistics-card";
import OverallStatistics from "./overall-statistics";
import { StatisticsView } from "./utils";

const CASHEW_DEMO = "7b32b116-30fb-47e9-acaa-74ccf5ebe00a";

enum StatisticsTab {
  OVERALL,
  COMPANIES,
}

const Statistics = () => {
  const { data: companiesData } = useCompaniesQuery({
    fetchPolicy: "cache-first",
  });
  const [statsTab, setStatsTab] = useState<StatisticsTab>(
    StatisticsTab.COMPANIES,
  );
  const [viewTab, setViewTab] = useState<StatisticsView>(StatisticsView.CHART);
  const [searchText, setSearchText] = useState<string>("");

  const onChangeSearchText = debounce((value: string) => {
    setSearchText(value);
  }, 200);

  return (
    <Grid container sx={{ pt: 2 }} spacing={2}>
      <Grid item xs={1}>
        <Tabs
          orientation="vertical"
          value={statsTab}
          onChange={(event: React.SyntheticEvent, newValue: number) => {
            setStatsTab(newValue as StatisticsTab);
          }}
        >
          <Tab value={StatisticsTab.COMPANIES} label="Companies" />
          <Tab value={StatisticsTab.OVERALL} label="Overall" />
        </Tabs>
      </Grid>
      <Grid item xs={11}>
        <TabPanel panelValue={StatisticsTab.COMPANIES} selectedValue={statsTab}>
          <Grid container>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} justifyContent="space-between">
                <TextField
                  size="small"
                  label="Search company"
                  onChange={(e) => {
                    onChangeSearchText(e.target.value);
                  }}
                />
                <Tabs
                  sx={{ height: 0, pr: 1 }}
                  value={viewTab}
                  onChange={(event: React.SyntheticEvent, newValue: number) => {
                    setViewTab(newValue);
                  }}
                >
                  <Tab value={StatisticsView.CHART} label="Chart" />
                  <Tab value={StatisticsView.TABLE} label="Table" />
                </Tabs>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                spacing={1}
                sx={{
                  height: "calc(100vh - 120px)",
                  overflowY: "scroll",
                }}
              >
                {sortBy(companiesData?.companies, "name")
                  .filter(
                    (c) =>
                      c.name.toLowerCase().includes(searchText.toLowerCase()) &&
                      c.uuid !== CASHEW_DEMO,
                  )
                  .map((company) => (
                    <CompanyStatisticsCard
                      key={company.uuid}
                      tab={viewTab}
                      companyName={company.name}
                      companyUuid={company.uuid}
                    />
                  ))}
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel panelValue={StatisticsTab.OVERALL} selectedValue={statsTab}>
          <OverallStatistics />
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default Statistics;
