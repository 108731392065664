import { Grid, Stack, Typography } from "@mui/material";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import {
  getCreateDTOCode,
  getEntityCode,
  getModuleCode,
  getRepositoryCode,
  getResolverCode,
  getServiceCode,
  getUpdateDTOCode,
} from "./code-gen-utils";

const CodeGen = () => {
  const [code, setCode] = useState("");
  const [enumNames, setEnumNames] = useState<string[]>([]);
  const [enumCode, setEnumCode] = useState("");
  useEffect(() => {
    setCode(code.trim());
  }, [code]);

  const entityCode = useMemo(() => {
    return getEntityCode(code, enumNames);
  }, [code, enumNames]);

  const createDtoCode = useMemo(() => {
    return getCreateDTOCode(code, enumNames);
  }, [code, enumNames]);

  const updateDtoCode = useMemo(() => {
    return getUpdateDTOCode(code, enumNames);
  }, [code, enumNames]);

  const moduleCode = useMemo(() => {
    return getModuleCode(code);
  }, [code]);

  const repositoryCode = useMemo(() => {
    return getRepositoryCode(code);
  }, [code]);

  const serviceCode = useMemo(() => {
    return getServiceCode(code);
  }, [code]);

  const resolverCode = useMemo(() => {
    return getResolverCode(code);
  }, [code]);

  return (
    <Stack sx={{ p: 3, px: 5 }} spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <CodeEditor
            value={code}
            language="js"
            placeholder="Enter your schema.prisma table"
            padding={15}
            style={{
              fontSize: 12,
              backgroundColor: "#f5f5f5",
              fontFamily:
                "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
            }}
            onChange={(evn) => {
              setCode(evn.target.value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CodeEditor
            value={enumCode}
            language="js"
            placeholder="Enter enum names separated by commas"
            padding={15}
            style={{
              fontSize: 12,
              backgroundColor: "#f5f5f5",
              fontFamily:
                "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
            }}
            onChange={(evn) => {
              if (isEmpty(evn.target.value)) {
                setEnumNames([]);
              } else {
                setEnumNames(
                  evn.target.value.split(",").map((name) => name.trim()),
                );
              }
              setEnumCode(evn.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Typography sx={{ fontWeight: "bold" }}>Entity</Typography>
      <CodeEditor
        value={entityCode}
        language="js"
        placeholder="Entity Code"
        padding={15}
        style={{
          fontSize: 12,
          backgroundColor: "#f5f5f5",
          fontFamily:
            "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
        }}
        onChange={() => {}}
      />
      <Typography sx={{ fontWeight: "bold" }}>Create DTO</Typography>
      <CodeEditor
        value={createDtoCode}
        language="js"
        placeholder="Create DTO Code"
        padding={15}
        style={{
          fontSize: 12,
          backgroundColor: "#f5f5f5",
          fontFamily:
            "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
        }}
        onChange={() => {}}
      />

      <Typography sx={{ fontWeight: "bold" }}>Update DTO</Typography>
      <CodeEditor
        value={updateDtoCode}
        language="js"
        placeholder="Update DTO Code"
        padding={15}
        style={{
          fontSize: 12,
          backgroundColor: "#f5f5f5",
          fontFamily:
            "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
        }}
        onChange={() => {}}
      />

      <Typography sx={{ fontWeight: "bold" }}>Module</Typography>
      <CodeEditor
        value={moduleCode}
        language="js"
        placeholder="Module Code"
        padding={15}
        style={{
          fontSize: 12,
          backgroundColor: "#f5f5f5",
          fontFamily:
            "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
        }}
        onChange={() => {}}
      />

      <Typography sx={{ fontWeight: "bold" }}>Repository</Typography>
      <CodeEditor
        value={repositoryCode}
        language="js"
        placeholder="Repository Code"
        padding={15}
        style={{
          fontSize: 12,
          backgroundColor: "#f5f5f5",
          fontFamily:
            "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
        }}
        onChange={() => {}}
      />

      <Typography sx={{ fontWeight: "bold" }}>Service</Typography>
      <CodeEditor
        value={serviceCode}
        language="js"
        placeholder="Service Code"
        padding={15}
        style={{
          fontSize: 12,
          backgroundColor: "#f5f5f5",
          fontFamily:
            "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
        }}
        onChange={() => {}}
      />

      <Typography sx={{ fontWeight: "bold" }}>Resolver</Typography>
      <CodeEditor
        value={resolverCode}
        language="js"
        placeholder="Resolver Code"
        padding={15}
        style={{
          fontSize: 12,
          backgroundColor: "#f5f5f5",
          fontFamily:
            "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
        }}
        onChange={() => {}}
      />
    </Stack>
  );
};

export default CodeGen;
