import { isNil } from "lodash";
import React from "react";
import Select from "react-select";
import { useSearchSchemasQuery } from "../../generated/graphql";

const SchemaSelect = ({
  schemaUuid,
  setSchemaUuid,
}: {
  readonly schemaUuid: string | null | undefined;
  readonly setSchemaUuid: (uuid: string) => void;
}) => {
  const { data: schemasData } = useSearchSchemasQuery({
    variables: {
      isActive: true,
    },
    fetchPolicy: "cache-first",
  });
  const schemaOptions = schemasData?.searchSchemas.map((s) => ({
    label: s.name,
    value: s.uuid,
  }));
  const selectedSchema = schemaOptions?.find((s) => s.value === schemaUuid);

  return (
    <div>
      <div>Select schema</div>
      <Select
        menuPosition="fixed"
        options={schemaOptions}
        placeholder="Select schema"
        value={selectedSchema ?? null}
        onChange={(option) => {
          if (!isNil(option) && !isNil(option.value)) {
            setSchemaUuid(option.value);
          }
        }}
      />
    </div>
  );
};

export default SchemaSelect;
