import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { sentenceCase } from "change-case";
import { isEmpty, isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, type SubmitHandler } from "react-hook-form";
import {
  StopType,
  useCreateImportedOrderTypeMappingMutation,
  useMeQuery,
  useRemoveImportedOrderTypeMappingMutation,
  useCreateOrderImportConfigurationMutation,
  useUpdateOrderImportConfigurationMutation,
} from "../../generated/graphql";
import useImportedOrderTypeMappingForm, {
  type ImportedOrderTypeMappingFormValues,
} from "./use-imported-order-type-mapping-form";

export const inboundStopTypeOptions = [
  StopType.Pickup,
  StopType.Recovery,
  StopType.PartnerCarrierDropoff,
  StopType.None,
];

export const outboundStopTypeOptions = [
  StopType.Delivery,
  StopType.Transfer,
  StopType.PartnerCarrierPickup,
  StopType.None,
];

export const stopTypeOptions = (method: string) => {
  return method === "Inbound"
    ? inboundStopTypeOptions
    : outboundStopTypeOptions;
};

const DefaultStopTypeSelection = ({
  method,
  value,
  onChange,
}: {
  readonly method: string;
  readonly value: any;
  readonly onChange: any;
}) => {
  return (
    <RadioGroup value={value} onChange={onChange}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {stopTypeOptions(method).map((stopTypeOption) => {
          return (
            <FormControlLabel
              key={stopTypeOption}
              checked={stopTypeOption === value}
              value={stopTypeOption}
              control={<Radio />}
              label={sentenceCase(stopTypeOption)}
            />
          );
        })}
      </Box>
    </RadioGroup>
  );
};

const OrderImportConfigurationEditor = () => {
  const [createMapping, { loading: createMappingLoading }] =
    useCreateImportedOrderTypeMappingMutation();
  const [deleteMapping, { loading: deleteMappingLoading }] =
    useRemoveImportedOrderTypeMappingMutation();
  const [createConfiguration, { loading: createConfigLoading }] =
    useCreateOrderImportConfigurationMutation();
  const [updateConfiguration, { loading: updateConfigLoading }] =
    useUpdateOrderImportConfigurationMutation();
  const { data: meData, refetch: refetchCompany } = useMeQuery({
    fetchPolicy: "cache-first",
  });
  const company = meData?.me?.company;
  const mappings =
    company?.orderImportConfiguration?.importedOrderTypeMappings ?? [];
  const orderImportConfigurationUuid = company?.orderImportConfiguration?.uuid;

  const [alwaysCreateDummyOrderCharge, setAlwaysCreateDummyOrderCharge] =
    useState(false);
  useEffect(() => {
    setAlwaysCreateDummyOrderCharge(
      company?.orderImportConfiguration?.alwaysCreateDummyOrderCharge ?? false,
    );
  }, [company?.orderImportConfiguration?.alwaysCreateDummyOrderCharge]);

  const {
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = useImportedOrderTypeMappingForm();

  const [successVisible, setSuccessVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);

  const DEFAULT_MESSAGE = "Error creating mapping";
  const [errorMessage, setErrorMessage] = useState(DEFAULT_MESSAGE);

  useEffect(() => {
    reset({
      moveType: "",
      serviceLevel: "",
      inboundStopType: StopType.None,
      outboundStopType: StopType.None,
      lineHaul: false,
      defaultToInbound: false,
      defaultToOutbound: false,
    });
  }, [reset]);

  const onSubmit: SubmitHandler<ImportedOrderTypeMappingFormValues> = async (
    data,
  ) => {
    const {
      moveType,
      serviceLevel,
      inboundStopType,
      outboundStopType,
      lineHaul,
      defaultToInbound,
      defaultToOutbound,
    } = data;

    try {
      if (isNil(orderImportConfigurationUuid)) {
        setErrorMessage("No order import configuration found");
        setErrorVisible(true);
        return;
      }
      const response = await createMapping({
        variables: {
          importedOrderTypeMappingCreateInput: {
            orderImportConfigurationUuid,
            serviceLevel: isNil(serviceLevel) ? null : serviceLevel,
            moveType: isNil(moveType) ? null : moveType,
            inboundStopType,
            outboundStopType,
            lineHaul,
            defaultToInbound,
            defaultToOutbound,
          },
        },
      });
      const { errors: resErrors } = response;
      if (isEmpty(resErrors)) {
        refetchCompany();
        setSuccessVisible(true);
      } else {
        setErrorMessage(
          resErrors?.map((err) => err.message).join(", ") ?? DEFAULT_MESSAGE,
        );
        setErrorVisible(true);
      }
    } catch (error) {
      setErrorVisible(true);
      setErrorMessage(`Error: ${error}`);

      console.error(error);
    }
  };

  return (
    <Grid
      container
      spacing={1}
      sx={{ padding: 1, margin: 2 }}
      border={2}
      borderColor="lightgray"
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={successVisible}
      >
        <Alert
          severity="success"
          onClose={() => {
            setSuccessVisible(false);
          }}
        >
          Successfully updated order import configuration
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={errorVisible}
      >
        <Alert
          severity="error"
          onClose={() => {
            setErrorVisible(false);
          }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
        <Typography variant="h4">Order import configuration editor</Typography>
        <Typography variant="subtitle1">
          <a href="https://www.loom.com/share/15bcf4c661e84f9bb2ab94bec27a3fce">
            Tutorial
          </a>
        </Typography>
        <Typography variant="subtitle1">
          Order import configuration uuid: {orderImportConfigurationUuid}
        </Typography>
        <FormControl fullWidth sx={{ paddingLeft: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={alwaysCreateDummyOrderCharge}
                onChange={(e) => {
                  setAlwaysCreateDummyOrderCharge(e.target.checked);
                }}
              />
            }
            label="Always create dummy order charge shipment"
          />
        </FormControl>
        {isNil(orderImportConfigurationUuid) ? (
          <Button
            variant="contained"
            disabled={createConfigLoading}
            onClick={async () => {
              await createConfiguration({
                variables: {
                  orderImportConfigurationCreateInput: {
                    alwaysCreateDummyOrderCharge,
                  },
                },
              });
              refetchCompany();
            }}
          >
            Create
          </Button>
        ) : (
          <Button
            variant="contained"
            disabled={updateConfigLoading}
            onClick={async () => {
              await updateConfiguration({
                variables: {
                  orderImportConfigurationUpdateInput: {
                    uuid: orderImportConfigurationUuid,
                    alwaysCreateDummyOrderCharge,
                  },
                },
              });
              refetchCompany();
            }}
          >
            Update
          </Button>
        )}
      </Grid>
      <Grid item xs={12} mb={4}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Imported order type mappings</Typography>
        <Typography variant="h6">Notes:</Typography>
        <li>
          defaultToInbound/defaultToOutbound determine which stop will include
          the special, appointment, etc. and be the primary stop for charges.
        </li>
        <li>
          Unless the order is None-None, either defaultToInbound or
          defaultToOutbound must be true.
        </li>
        <li>
          Please ensure the moveType and serviceLevel are entered exactly as
          they will appear in the CSV with no extra spaces / characters
        </li>
      </Grid>
      <Grid item xs={3}>
        <Controller
          name="moveType"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <InputLabel>
                Move type (A2D, D2A, D2D, DEL, LH, MISC, PU, PUDEL, XFR)
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                error={!isNil(errors.moveType)}
                value={value}
                onChange={onChange}
              />
              {!isNil(errors.moveType) && (
                <FormHelperText sx={{ color: "#D32F2F" }}>
                  {errors.moveType.message}
                </FormHelperText>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="serviceLevel"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <InputLabel>Service level (blank = All)</InputLabel>
              <TextField
                fullWidth
                size="small"
                error={!isNil(errors.serviceLevel)}
                value={value}
                onChange={onChange}
              />
              {!isNil(errors.serviceLevel) && (
                <FormHelperText sx={{ color: "#D32F2F" }}>
                  {errors.serviceLevel.message}
                </FormHelperText>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="inboundStopType"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <InputLabel>Inbound stop type</InputLabel>
              <DefaultStopTypeSelection
                method="Inbound"
                value={value}
                onChange={onChange}
              />
            </>
          )}
        />
      </Grid>
      <Grid item xs={8}>
        <Controller
          control={control}
          name="defaultToInbound"
          defaultValue={false}
          render={({ field }) => (
            <FormControl fullWidth sx={{ paddingLeft: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox checked={field.value} onChange={field.onChange} />
                }
                label="Default special/appt/surcharge to inbound"
              />
              {!isNil(errors.defaultToInbound) && (
                <FormHelperText sx={{ color: "#D32F2F" }}>
                  {errors.defaultToInbound.message}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="outboundStopType"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <InputLabel>Outbound stop type</InputLabel>
              <DefaultStopTypeSelection
                method="Outbound"
                value={value}
                onChange={onChange}
              />
            </>
          )}
        />
      </Grid>
      <Grid item xs={8}>
        <Controller
          control={control}
          name="defaultToOutbound"
          defaultValue={false}
          render={({ field }) => (
            <FormControl fullWidth sx={{ paddingLeft: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox checked={field.value} onChange={field.onChange} />
                }
                label="Default special/appt/surcharge to outbound"
              />
              {!isNil(errors.defaultToOutbound) && (
                <FormHelperText sx={{ color: "#D32F2F" }}>
                  {errors.defaultToOutbound.message}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="lineHaul"
          defaultValue={false}
          render={({ field }) => (
            <FormControl fullWidth sx={{ paddingLeft: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox checked={field.value} onChange={field.onChange} />
                }
                label="Line haul"
              />
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
        <Button
          disabled={createMappingLoading}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          Create mapping
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>UUID</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Move type</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Service level</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Inbound stop type
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Outbound stop type
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Line haul</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Default to inbound
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Default to outbound
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {[...mappings]
                .sort((a, b) =>
                  (a.moveType ?? "All").localeCompare(b.moveType ?? "All"),
                )
                .map((mapping) => {
                  return (
                    <TableRow key={mapping.uuid}>
                      <TableCell>{mapping.uuid}</TableCell>
                      <TableCell>{mapping.moveType ?? "All"}</TableCell>
                      <TableCell>{mapping.serviceLevel ?? "All"}</TableCell>
                      <TableCell>{mapping.inboundStopType}</TableCell>
                      <TableCell>{mapping.outboundStopType}</TableCell>
                      <TableCell>{mapping.lineHaul.toString()}</TableCell>
                      <TableCell>
                        {mapping.defaultToInbound.toString()}
                      </TableCell>
                      <TableCell>
                        {mapping.defaultToOutbound.toString()}
                      </TableCell>
                      <TableCell>
                        <Button
                          sx={{ float: "right" }}
                          disabled={deleteMappingLoading}
                          onClick={async () => {
                            await deleteMapping({
                              variables: {
                                uuid: mapping.uuid,
                              },
                            });
                            refetchCompany();
                          }}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default OrderImportConfigurationEditor;
