import { isEmpty, isNil } from "lodash";
import { type Resolver, useForm } from "react-hook-form";

export type ContactImporterFormValues = {
  contactsUrl: string;
  companyUuid: string;
  userUuid: string;
};

const resolver: Resolver<ContactImporterFormValues> = async (formValues) => {
  const errors: {
    [property in keyof ContactImporterFormValues]?: {
      type: string;
      message: string;
    };
  } = {};

  const { contactsUrl } = formValues;

  if (isNil(contactsUrl) || isEmpty(contactsUrl)) {
    errors.contactsUrl = {
      type: "required",
      message: "Contacts URL is required",
    };
  }

  return {
    values: formValues,
    errors,
  };
};

const useImportContactsForm = () => {
  return useForm({ resolver });
};

export default useImportContactsForm;
