import { Check, ExpandMore } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { isNil } from "lodash";
import { useMemo, useState } from "react";
import { useCompaniesQuery } from "../../generated/graphql";
import useStyles from "./general-styles";

export type Option = {
  value: string | undefined;
  label: string | undefined;
};

type CompanyFilterButtonProps = {
  readonly selectedOption: Option | undefined;
  readonly handleChange: (option: Option | undefined) => void;
};

const CompanyFilterButton = ({
  selectedOption,
  handleChange,
}: CompanyFilterButtonProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const styles = useStyles();
  const isAllSelected = selectedOption === undefined;
  const { data: companiesData } = useCompaniesQuery();

  const onChange = (option: Option | null | undefined) => {
    handleChange(option ?? undefined);
  };

  const companyTitle = useMemo(() => {
    const label = selectedOption?.label;
    if (isNil(label)) return "All";
    if (label.length > 20) {
      return `${label.slice(0, 20)}...`;
    }
    return label;
  }, [selectedOption]);

  return (
    <Box>
      <Button
        size="small"
        variant="outlined"
        sx={[styles.filterButton]}
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Typography sx={styles.filterTitle}>Company:</Typography>
          <Typography sx={styles.filterValue}>{companyTitle}</Typography>
          <ExpandMore fontSize="small" sx={{ mr: 0 }} />
        </Box>
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        id="company-menu"
        open={Boolean(menuAnchorEl)}
        sx={{
          "& .MuiMenu-paper": { overflow: "visible" },
          top: "3px",
        }}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
      >
        <MenuList
          dense
          sx={{
            p: 0,
          }}
        >
          <MenuItem
            key="all"
            sx={{
              alignItems: "flex-start",
              display: "flex",
              flexDirection: "column",
              overflow: "visible",
              pl: "10px",
            }}
            onClick={() => {
              onChange(undefined);
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility:
                    selectedOption === undefined ? undefined : "hidden",
                  fontSize: "14px",
                  ml: 0,
                  mr: "6px",
                }}
              />
              <Typography sx={styles.menuText}>All</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            key="custom"
            sx={{
              alignItems: "flex-start",
              display: "flex",
              flexDirection: "column",
              overflow: "visible",
              pl: "10px",
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility: isAllSelected ? "hidden" : undefined,
                  fontSize: "14px",
                  ml: 0,
                  mr: "6px",
                }}
              />
              <Autocomplete
                size="small"
                sx={{
                  backgroundColor: "white",
                  width: "200px",
                }}
                value={selectedOption}
                options={
                  companiesData?.companies?.map((company) => ({
                    label: company.name,
                    value: company.uuid,
                  })) ?? []
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                  />
                )}
                onChange={(_, option) => {
                  onChange(option);
                }}
              />
            </Stack>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default CompanyFilterButton;
