import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { isEmpty, isNil } from "lodash";
import { FileUpload } from "primereact/fileupload";
import React, { useRef } from "react";
import { shallow } from "zustand/shallow";
import { useSchemaDocumentsBySchemaCompanyMappingQuery } from "../../../../generated/graphql";
import CompanySelect from "../../company-select";
import useSchemaStore from "../schema-store";
import { type Schema, type SchemaCompanyMapping } from "../types";
import SchemaDocumentCard from "./schema-document-card";
import useSchemaDocumentUpload from "./use-schema-document-upload";

const SchemaCompanyMappingViewer = ({
  schema,
  schemaCompanyMapping,
}: {
  readonly schema: Schema;
  readonly schemaCompanyMapping: SchemaCompanyMapping;
}) => {
  const fileUploadRef = useRef<FileUpload | null>(null);
  const [deselectAllSchemaDocuments, selectSchemaDocuments] = useSchemaStore(
    (state) => [state.deselectAllSchemaDocuments, state.selectSchemaDocuments],
    shallow,
  );
  const { data: schemaDocumentsData, refetch } =
    useSchemaDocumentsBySchemaCompanyMappingQuery({
      variables: {
        schemaCompanyMappingId: schemaCompanyMapping.id,
      },
    });

  const { loading, uploadFiles } = useSchemaDocumentUpload();
  const schemaDocuments =
    schemaDocumentsData?.schemaDocumentsBySchemaCompanyMapping;
  const allDocumentsSelected = useSchemaStore(
    (state) =>
      !isEmpty(schemaDocuments) &&
      schemaDocuments?.every((s) =>
        state.selectedSchemaDocuments.some(
          (ss) => ss.schemaDocument.uuid === s.uuid,
        ),
      ) === true,
  );

  const schemaUuid = schema.uuid;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box sx={{ py: 1 }}>
          <Typography>
            <CompanySelect
              isText
              currentCompanyUuid={schemaCompanyMapping.companyUuid}
              onChange={() => {}}
            />
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: schemaCompanyMapping.isActive ? "green" : "red",
            }}
          >
            Active: {schemaCompanyMapping.isActive ? "true" : "false"}
          </Typography>
        </Box>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "bold" }}>
              Sample Documents
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ float: "right" }}>
              <Stack direction="row" alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allDocumentsSelected}
                      onClick={() => {
                        if (!isNil(schemaDocuments) && !allDocumentsSelected) {
                          selectSchemaDocuments(schema, schemaDocuments);
                        } else {
                          deselectAllSchemaDocuments();
                        }
                      }}
                    />
                  }
                  label={allDocumentsSelected ? "Deselect all" : "Select all"}
                />
              </Stack>
            </Box>
          </Grid>
          {schemaDocuments?.map((schemaDocument) => (
            <Grid key={schemaDocument.uuid} item xs={4}>
              <SchemaDocumentCard
                schema={schema}
                schemaDocument={schemaDocument}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ position: "absolute", bottom: 20, maxHeight: 300 }}>
          <FileUpload
            ref={fileUploadRef}
            multiple
            customUpload
            name="demo[]"
            uploadHandler={async (e) => {
              await uploadFiles({
                schemaUuid,
                schemaCompanyMappingUuid: schemaCompanyMapping.id,
                files: e.files,
                onFinish: async () => refetch(),
              });
              fileUploadRef.current?.clear();
            }}
            accept="application/pdf"
            maxFileSize={10_000_000}
            emptyTemplate="Drag and drop files here"
            progressBarTemplate={loading ? <LinearProgress /> : null}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(SchemaCompanyMappingViewer);
