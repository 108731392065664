import { Divider, Grid, Stack, TextField } from "@mui/material";
import { debounce, isNil, groupBy, max } from "lodash";
import React, { useMemo, useState } from "react";
import {
  useRenderServicesQuery,
  type RenderServiceWithDeploysEntity,
} from "../generated/graphql";
import { RequireAuth } from "../hooks/use-auth";
import RenderServiceCard from "./render-components/render-service-card";
import ServiceEnvGrouping from "./render-components/service-env-grouping";

const STAGING_SERVICES = new Set([
  "srv-cinj2nlgkuvudi92tri0",
  "srv-cfdfffpa6gdja6bg3hqg",
  "srv-cfkn5shmbjsn9edbc2vg",
  "srv-cn7big021fec73fl0970",
  "srv-cfkn6cpmbjsn9edbd430",
]);

const RELEASE_SERVICES = new Set([
  "srv-cnkbd3tjm4es73a0dt3g",
  "srv-cnkbfa5jm4es73a0e7h0",
  "srv-cnkbl1da73kc73eg3s2g",
  "srv-cnkbirnsc6pc73fpagm0",
]);

const PREVIEW_SERVICES = new Set([
  "srv-cl8ker5b7ptc73da4ka0",
  "srv-cl8kd5f6e7vc73a7bg50",
  "srv-cnfq3u5a73kc73dc2pfg",
  "srv-cnfq3u5a73kc73dc2pe0",
  "srv-cnug6lfjbltc73c3p230",
  "srv-cngs21la73kc73c9fin0",
]);

const STABLE_SERVICES = new Set([
  "srv-ciq3cbd9aq0dcpqea6bg",
  "srv-cmfscivqd2ns73a67np0",
  "srv-cmebhc7109ks73c3kecg",
  "srv-cfdfgdun6mpuopvnepmg",
  "srv-cfljguha6gdjlmpela0g",
  "srv-cmfgvbug1b2c73clutb0",
  "srv-cfljgm1a6gdjlmpeijlg",
]);

const RenderPage = () => {
  const [searchText, setSearchText] = useState<string>("");
  const { data, loading, error } = useRenderServicesQuery();

  const onChangeSearchText = debounce((value: string) => {
    setSearchText(value);
  }, 200);

  const sortedServices = useMemo(() => {
    if (isNil(data)) return [];

    return data.renderServices.sort((a, b) => {
      const maxFinishedAtA =
        max(a.deploys.map((deploy) => deploy?.finishedAt)) ?? 0;
      const maxFinishedAtB =
        max(b.deploys.map((deploy) => deploy?.finishedAt)) ?? 0;
      return maxFinishedAtB - maxFinishedAtA;
    });
  }, [data]);

  const checkUniformBranch = (services: RenderServiceWithDeploysEntity[]) => {
    if (services.length === 0) return true;
    const firstBranch = services[0]!.branch;
    return services.every((service) => service.branch === firstBranch);
  };

  const createServiceGroup = (services?: RenderServiceWithDeploysEntity[]) => ({
    services: services || [],
    isUniformBranch: checkUniformBranch(services || []),
  });

  const groupedServices = useMemo(() => {
    if (isNil(data)) {
      return {
        staging: { services: [], isUniformBranch: true },
        release: { services: [], isUniformBranch: true },
        preview: { services: [], isUniformBranch: true },
        stable: { services: [], isUniformBranch: true },
        other: { services: [], isUniformBranch: true },
      };
    }

    const serviceGroups = groupBy(data.renderServices, (service) => {
      if (STAGING_SERVICES.has(service.id)) return "staging";
      if (RELEASE_SERVICES.has(service.id)) return "release";
      if (PREVIEW_SERVICES.has(service.id)) return "preview";
      if (STABLE_SERVICES.has(service.id)) return "stable";
      return "other";
    });

    return {
      staging: createServiceGroup(serviceGroups.staging),
      release: createServiceGroup(serviceGroups.release),
      preview: createServiceGroup(serviceGroups.preview),
      stable: createServiceGroup(serviceGroups.stable),
      other: createServiceGroup(serviceGroups.other),
    };
    // eslint-disable-next-line
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (!isNil(error)) return <p>Error: {error.message}</p>;

  return (
    <RequireAuth>
      <Grid container sx={{ p: 2 }} spacing={1}>
        <Grid item xs={12}>
          <ServiceEnvGrouping groupedServices={groupedServices} />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mb: 1 }} />
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <TextField
              size="small"
              label="Search service"
              onChange={(e) => {
                onChangeSearchText(e.target.value);
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            spacing={1}
            sx={{
              height: "calc(50vh - 35px)",
              overflowY: "scroll",
              paddingRight: "50px",
            }}
          >
            {sortedServices
              .filter((service) => {
                return service.name
                  ?.toLowerCase()
                  .includes(searchText.toLowerCase());
              })
              .map((service) => (
                <RenderServiceCard key={service.id} service={service} />
              ))}
          </Stack>
        </Grid>
      </Grid>
    </RequireAuth>
  );
};

export default RenderPage;
