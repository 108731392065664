/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  type Block,
  type BoundingBox,
  type EntityType,
} from "@aws-sdk/client-textract";
import Konva from "konva";
import { isEmpty, isNil } from "lodash";
import React, { type Dispatch, type SetStateAction, useEffect } from "react";
import { Image, Layer, Rect, Stage } from "react-konva";
import Modal from "react-modal";
import useStateRef from "react-usestateref";
import { exhaustive } from "shared/switch";
import "../../../style/App.css";
import useImage from "use-image";
import { v4 } from "uuid";
import { AddBlockMode } from "../../../enums/add-block-mode";
import { AddEdgeAnchorMode } from "../../../enums/add-edge-anchor-mode";
import { AppMode } from "../../../enums/app-mode";
import { Side } from "../../../enums/side";
import { UploadFileType } from "../../../enums/upload-file-type";
import {
  type CompaniesQuery,
  type CreateSchemaInput,
  Edge,
  type EdgeAnchorEntity,
  type ExtractionType,
  type FieldMapping,
  type MatcherType,
  type NormalizeDocumentInput,
  type PackageTableColumnEntity,
  type ReanalyzePageInput,
  type ReanalyzeSchemaFileInput,
  type RotateDocumentInput,
  ScannedDocumentType,
  type SchemaFieldEntity,
  type SchemaRunFragment,
  SchemaRunTestSnapshotsDocument,
  type SchemaRunTestSnapshotsQuery,
  SchemasDocument,
  type SchemasQuery,
  type TestSchemaInput,
  TextractType,
  type UpdateSchemaInput,
  useCreateSchemaMutation,
  useDeleteSchemaMutation,
  useDeleteSchemaRunMutation,
  useGetXyTransformLazyQuery,
  useImportTestSnapshotMutation,
  useNormalizeDocumentMutation,
  useReanalyzePageMutation,
  useReanalyzeSchemaFileMutation,
  useRotateDocumentMutation,
  useSchemaFilePutUrlLazyQuery,
  useSchemaRunTestSnapshotsQuery,
  useSchemaTestFilePutUrlLazyQuery,
  useTestAllSnapshotsMutation,
  useTestOneSnapshotMutation,
  useTestSchemaMutation,
  useTextractSchemaFileMutation,
  useTextractSchemaTestFileMutation,
  useUpdateSchemaMutation,
  type XyTransformEntity,
  useAllScannedDocumentsQuery,
  type SchemaWithAnchorsEntity,
} from "../../../generated/graphql";
import { type EdgeAnchor } from "../../../types/edge-anchor";
import { type FingerprintAnchor } from "../../../types/fingerprint-anchor";
import { type PackageTable } from "../../../types/package-table";
import { type SchemaField } from "../../../types/schema-field";
import { type TransformAnchor } from "../../../types/transform-anchor";
import { filterNotNil } from "../../../utils/array";
import { incrementPackageField, isPackageField } from "../../../utils/field";
import { filenameFromKey } from "../../../utils/file";
import { omitTypename, omitUuid } from "../../../utils/graphql";
import Sidebar from "./Sidebar";
import AddFieldExtractorModal from "./add-field-extractor.modal";
import AddFieldMatcherModal from "./add-field-matcher.modal";
import EditFieldExtractorModal from "./edit-field-extractor.modal";
import EditFieldMatcherModal from "./edit-field-matcher.modal";
import EditFingerprintAnchorModal from "./edit-fingerprint-anchor.modal";
import EditPackageTableModal from "./edit-package-table.modal";
import Rectangle from "./rectangle";
import UploadFileModal from "./upload-file.modal";
import KonvaEventObject = Konva.KonvaEventObject;

const HEIGHT = 2200;
const WIDTH = 1700;

type State = {
  addBlockMode: AddBlockMode;
  addEdgeAnchorMode: AddEdgeAnchorMode | null;
  addEdgeAnchorSchemaFieldId: string | null;
  allFilesInS3: Array<string | undefined>;
  appMode: AppMode;
  blocksById: Map<string | undefined, Block>;
  companies: CompaniesQuery["companies"];
  currentBlockType: string;
  currentEntityType: string | null;
  currentCompanyId: string | null;
  currentContactId: string | null;
  currentFieldExtractorId: string | null;
  currentFieldMatcherId: string | null;
  currentFingerprintAnchorId: string | null;
  currentPackageTableId: string | null;
  currentSchemaFieldId: string | null;
  currentSchemaRunTestSnapshot: string | null;
  currentTestFilePage: string | undefined | null;
  currentTestFilePages: Array<string | undefined>;
  currentTestFilename: string | null;
  edgeAnchorBlocks: EdgeAnchor[];
  fingerprintBlocks: FingerprintAnchor[];
  packageTables: PackageTable[];
  scaleXY: boolean;
  schemaFieldBeingDrawn: SchemaField | null;
  schemaFields: SchemaField[];
  schemaIsMultiPage: boolean;
  schemaName: string;
  schemaRunTestSnapshots: SchemaRunTestSnapshotsQuery["schemaRunTestSnapshots"];
  schemaScannedDocumentType: ScannedDocumentType;
  schemaTextractJson: any;
  schemaTextractType: TextractType;
  schemas: SchemasQuery["schemas"];
  selectedFileName: string | null;
  selectedRectangleId: string | null;
  selectedSchema: string | null;
  showAddFieldExtractorModal: boolean;
  showAddFieldMatcherModal: boolean;
  showEditFieldExtractorModal: boolean;
  showEditFieldMatcherModal: boolean;
  showEditFingerprintAnchorModal: boolean;
  showEditPackageTableModal: boolean;
  showFields: boolean;
  showFingerprintAnchors: boolean;
  showPackageTables: boolean;
  showSelectedFileLines: boolean;
  showTestResultModal: boolean;
  showTransformAnchors: boolean;
  showUploadFileModal: boolean;
  testFilenames: Array<string | undefined>;
  textExtractResults: { Blocks: Block[] } | null;
  textractBlockType: string | null;
  textractBlockTypes: string[];
  textractBlocks: Block[];
  textractBlocksById: Map<string, Block>;
  textractEntityTypes: string[];
  textractJson: any;
  transformBlocks: TransformAnchor[];
  uploadFileType: UploadFileType | null;
  xyTransform: XyTransformEntity;
};

const URLImage = ({ url }: { readonly url: string }) => {
  const [image] = useImage(url);
  return (
    <Image
      x={0}
      y={0}
      width={WIDTH}
      height={HEIGHT}
      fill="#fff"
      image={image}
    />
  );
};

const Docs = ({
  selectedSchemaUuid,
  setSelectedSchemaUuid,
  companies,
  schemas,
}: {
  readonly selectedSchemaUuid: string | undefined;
  readonly setSelectedSchemaUuid: Dispatch<SetStateAction<string | undefined>>;
  readonly companies: CompaniesQuery["companies"] | undefined;
  readonly schemas: SchemasQuery["schemas"] | undefined;
}) => {
  const [state, setState, stateRef] = useStateRef<State>({
    addBlockMode: AddBlockMode.ADD_FINGERPRINT_BLOCK,
    addEdgeAnchorMode: null,
    addEdgeAnchorSchemaFieldId: null,
    allFilesInS3: [],
    appMode: AppMode.EDIT,
    blocksById: new Map(),
    companies: [],
    currentBlockType: "LINE",
    currentEntityType: null,
    currentCompanyId: null,
    currentContactId: null,
    currentFieldExtractorId: null,
    currentFieldMatcherId: null,
    currentFingerprintAnchorId: null,
    currentPackageTableId: null,
    currentSchemaFieldId: null,
    currentSchemaRunTestSnapshot: null,
    currentTestFilePage: null,
    currentTestFilePages: [],
    currentTestFilename: null,
    edgeAnchorBlocks: [],
    fingerprintBlocks: [],
    packageTables: [],
    scaleXY: true,
    schemaFieldBeingDrawn: null,
    schemaFields: [],
    schemaIsMultiPage: false,
    schemaName: "",
    schemaRunTestSnapshots: [],
    schemaScannedDocumentType: ScannedDocumentType.None,
    schemaTextractJson: null,
    schemaTextractType: TextractType.DetectDocumentText,
    schemas: [],
    selectedFileName: null,
    selectedRectangleId: null,
    selectedSchema: null,
    showAddFieldExtractorModal: false,
    showAddFieldMatcherModal: false,
    showEditPackageTableModal: false,
    showEditFieldExtractorModal: false,
    showEditFieldMatcherModal: false,
    showEditFingerprintAnchorModal: false,
    showFields: true,
    showFingerprintAnchors: true,
    showPackageTables: true,
    showSelectedFileLines: true,
    showTestResultModal: false,
    showTransformAnchors: true,
    showUploadFileModal: false,
    testFilenames: [],
    textExtractResults: null,
    textractBlockType: null,
    textractBlockTypes: [],
    textractBlocks: [],
    textractBlocksById: new Map(),
    textractEntityTypes: [],
    textractJson: null,
    transformBlocks: [],
    uploadFileType: null,
    xyTransform: {
      xMin: 0,
      yMin: 0,
      xScale: 1,
      yScale: 1,
      xTranslation: 0,
      yTranslation: 0,
      rotation: 0,
    },
  });
  const [createSchemaMutation, { loading: createSchemaLoading }] =
    useCreateSchemaMutation({
      refetchQueries: [SchemasDocument],
    });
  const [updateSchemaMutation, { loading: updateSchemaLoading }] =
    useUpdateSchemaMutation({
      refetchQueries: [SchemasDocument],
    });
  const [deleteSchemaMutation, { loading: deleteSchemaLoading }] =
    useDeleteSchemaMutation({
      refetchQueries: [SchemasDocument],
    });
  const [deleteSchemaRunMutation, { loading: deleteSchemaRunLoading }] =
    useDeleteSchemaRunMutation({
      refetchQueries: [SchemaRunTestSnapshotsDocument],
    });
  const [importTestSnapshotMutation, { loading: importTestSnapshotLoading }] =
    useImportTestSnapshotMutation();
  const [testAllSnapshotsMutation, { loading: testAllSnapshotsLoading }] =
    useTestAllSnapshotsMutation();
  const [testOneSnapshotMutation, { loading: testOneSnapshotLoading }] =
    useTestOneSnapshotMutation();
  const [testSchema, { data: testSchemaData, loading: testSchemaLoading }] =
    useTestSchemaMutation();
  const [normalizeDocument, { loading: normalizeDocumentLoading }] =
    useNormalizeDocumentMutation();
  const [rotateDocument] = useRotateDocumentMutation();
  const [getXyTransform] = useGetXyTransformLazyQuery();
  const [schemaFilePutUrl] = useSchemaFilePutUrlLazyQuery();
  const [schemaTestFilePutUrl] = useSchemaTestFilePutUrlLazyQuery();
  const [reanalyzePage, { loading: reanalyzePageLoading }] =
    useReanalyzePageMutation();
  const [reanalyzeSchemaFile, { loading: reanalyzeSchemaFileLoading }] =
    useReanalyzeSchemaFileMutation();
  const [textractSchemaFile] = useTextractSchemaFileMutation();
  const [textractSchemaTestFile] = useTextractSchemaTestFileMutation();
  const {
    data: schemaRunTestSnapshotsData,
    refetch: schemaRunTestSnapshotsRefetch,
  } = useSchemaRunTestSnapshotsQuery();
  const { data: allScannedDocumentsData, refetch: allScannedDocumentsRefetch } =
    useAllScannedDocumentsQuery();

  useEffect(() => {
    if (allScannedDocumentsData) {
      const allFilesInS3 = allScannedDocumentsData.allScannedDocuments.filter(
        (key) => !key?.endsWith("json"),
      );
      const testFilenames = allScannedDocumentsData.allScannedDocuments.filter(
        (key) => {
          return key?.startsWith("tests/") && !key?.endsWith("json");
        },
      );
      setState((s) => ({
        ...s,
        allFilesInS3,
        testFilenames,
      }));
    }
  }, [allScannedDocumentsData]);

  useEffect(() => {
    if (!isNil(state.selectedFileName)) {
      fetch(
        `https://${process.env.REACT_APP_AWS_SCAN_DATA_BUCKET_NAME}.s3.us-east-1.amazonaws.com/${state.selectedFileName}.textract.json`,
      )
        .then(async (r) => r.json())
        .then((r) => {
          setState((s) => ({
            ...s,
            textractJson: r,
          }));
        });
    }
  }, [state.selectedFileName]);

  useEffect(() => {
    if (!isNil(state.currentTestFilePage)) {
      fetch(
        `https://${process.env.REACT_APP_AWS_SCAN_DATA_BUCKET_NAME}.s3.us-east-1.amazonaws.com/${state.currentTestFilePage}.textract.json`,
      )
        .then(async (r) => r.json())
        .then((r) => {
          setState((s) => ({
            ...s,
            textractJson: r,
          }));
        });
    }
  }, [state.currentTestFilePage]);

  useEffect(() => {
    if (!isNil(state.textractJson)) {
      const blockTypes = new Set<string>(
        state.textractJson.Blocks.map((block: Block) => block.BlockType),
      );
      const blocks = state.textractJson.Blocks;
      const entityTypes = new Set<string>(
        state.textractJson.Blocks.flatMap((block: Block) => block.EntityTypes),
      );
      const blocksById = new Map<string, Block>(
        state.textractJson.Blocks.map((block: Block) => [block.Id, block]),
      );
      setState((s) => {
        return {
          ...s,
          textractBlockTypes: ["HIDE ALL"].concat(Array.from(blockTypes)),
          textractBlocks: blocks,
          textractBlocksById: blocksById,
          textractEntityTypes: Array.from(entityTypes),
        };
      });
    }
  }, [state.textractJson]);

  useEffect(() => {
    if (!isNil(schemas)) {
      setState((s) => ({ ...s, schemas }));
    }
  }, [schemas]);

  useEffect(() => {
    if (!isNil(companies)) {
      setState((s) => ({
        ...s,
        companies,
      }));
    }
  }, [companies]);

  useEffect(() => {
    if (!isNil(schemaRunTestSnapshotsData)) {
      setState((s) => ({
        ...s,
        schemaRunTestSnapshots:
          schemaRunTestSnapshotsData.schemaRunTestSnapshots,
      }));
    }
  }, [schemaRunTestSnapshotsData]);

  useEffect(() => {
    const handleChangeSelectedSchema = async () => {
      if (!isNil(state.selectedSchema)) {
        const schema = state.schemas.find(
          (s) => s.uuid === state.selectedSchema,
        );
        if (!isNil(schema)) {
          const textractJson = await fetch(
            `https://${process.env.REACT_APP_AWS_SCAN_DATA_BUCKET_NAME}.s3.us-east-1.amazonaws.com/${schema.originalFilename}.textract.json`,
          ).then(async (r) => r.json());
          const blocks: Block[] = textractJson.Blocks.filter((block: Block) => {
            return block.BlockType === "LINE";
          });
          const blocksById = new Map(blocks.map((block) => [block.Id, block]));

          setState((s) => {
            return {
              ...s,
              blocksById,
              currentCompanyId: schema.companyUuid ?? null,
              currentContactId: schema.contactUuid ?? null,
              fingerprintBlocks: schema.fingerprintAnchors.map((anchor) => {
                if (isNil(anchor.blockId)) {
                  const block = blocks.find((b) => b.Text === anchor.text);
                  return {
                    ...anchor,
                    blockId: block?.Id,
                  };
                }
                return anchor;
              }),
              transformBlocks: schema.transformAnchors.map((anchor) => {
                if (isNil(anchor.blockId)) {
                  const block = blocks.find((b) => b.Text === anchor.text);
                  return {
                    ...anchor,
                    blockId: block?.Id,
                  };
                }
                return anchor;
              }),
              packageTables: schema.packageTables,
              schemaFields: schema.schemaFields.map((field) => {
                return {
                  ...field,
                  show: true,
                };
              }),
              schemaIsMultiPage: schema.isMultiPage,
              schemaScannedDocumentType: schema.scannedDocumentType,
              schemaTextractJson: textractJson,
              schemaTextractType: schema.textractType,
            };
          });
        }
      }
    };
    handleChangeSelectedSchema();
  }, [state.selectedSchema]);

  useEffect(() => {
    const fetchTransform = async () => {
      if (!isNil(state.selectedSchema) && !isNil(state.selectedFileName)) {
        const xyTransformResult = await getXyTransform({
          variables: {
            getXyTransformInput: {
              documentName: state.selectedFileName,
              schemaUuid: state.selectedSchema,
            },
          },
        });
        const xyTransform = xyTransformResult.data?.getXYTransform;
        if (!isNil(xyTransform)) {
          setState((s) => {
            return {
              ...s,
              xyTransform,
            };
          });
        }
      }
      if (!isNil(state.selectedSchema) && !isNil(state.currentTestFilePage)) {
        const xyTransformResult = await getXyTransform({
          variables: {
            getXyTransformInput: {
              documentName: state.currentTestFilePage,
              schemaUuid: state.selectedSchema,
            },
          },
        });
        const xyTransform = xyTransformResult.data?.getXYTransform;
        if (!isNil(xyTransform)) {
          setState((s) => {
            return {
              ...s,
              xyTransform,
            };
          });
        }
      }
    };
    fetchTransform();
  }, [state.selectedSchema, state.selectedFileName, state.currentTestFilePage]);

  const createSchema = async () => {
    if (stateRef.current.schemaName.length <= 2) {
      alert("Schema name must have length > 2");
      return;
    }

    if (!isNil(stateRef.current.selectedFileName)) {
      const createSchemaInput: CreateSchemaInput = {
        companyUuid: stateRef.current.currentCompanyId,
        contactUuid: stateRef.current.currentContactId,
        schema: {
          name: stateRef.current.schemaName,
          originalFilename: stateRef.current.selectedFileName,
          packageTables: stateRef.current.packageTables.map((p) => {
            return {
              blockId: p.blockId,
              headerRowIndex: p.headerRowIndex,
              title: p.title,
              packageTableColumns: p.packageTableColumns.map((c) => {
                return {
                  columnIndex: c.columnIndex,
                  header: c.header,
                  packageField: c.packageField,
                  required: c.required,
                };
              }),
            };
          }),
          isMultiPage: stateRef.current.schemaIsMultiPage,
          scannedDocumentType: stateRef.current.schemaScannedDocumentType,
          textractType: stateRef.current.schemaTextractType,
          fingerprintAnchors: stateRef.current.fingerprintBlocks.map(
            (anchor) => {
              return omitTypename(anchor);
            },
          ),
          transformAnchors: stateRef.current.transformBlocks.map((anchor) => {
            return omitTypename(anchor);
          }),
          schemaFields: stateRef.current.schemaFields.map((field) => {
            return {
              top: field.top,
              left: field.left,
              width: field.width,
              height: field.height,
              concatenateX: field.concatenateX,
              leftEdgeAnchor: omitUuid(omitTypename(field.leftEdgeAnchor)),
              rightEdgeAnchor: omitUuid(omitTypename(field.rightEdgeAnchor)),
              topEdgeAnchor: omitUuid(omitTypename(field.topEdgeAnchor)),
              bottomEdgeAnchor: omitUuid(omitTypename(field.bottomEdgeAnchor)),
              fieldExtractors: field.fieldExtractors.map((f) => {
                return {
                  type: f.type,
                  value: f.value,
                  mapping: f.mapping,
                };
              }),
              fieldMatchers: field.fieldMatchers.map((f) => {
                return {
                  type: f.type,
                  value: f.value,
                };
              }),
            };
          }),
        },
      };

      await createSchemaMutation({
        variables: { createSchemaInput },
      });
      alert("Schema created!");
    }
  };

  const handleImportSchema = async () => {
    const schemaJson = prompt("Paste schema JSON");
    if (isNil(schemaJson)) {
      return;
    }
    const schemaParsed = JSON.parse(schemaJson) as SchemaWithAnchorsEntity;
    const textractJson = await fetch(
      `https://${process.env.REACT_APP_AWS_SCAN_DATA_BUCKET_NAME}.s3.us-east-1.amazonaws.com/${schemaParsed.originalFilename}.textract.json`,
    ).then(async (r) => r.json());
    const blocks: Block[] = textractJson.Blocks.filter((block: Block) => {
      return block.BlockType === "LINE";
    });
    setState((s) => {
      return {
        ...s,
        currentCompanyId: schemaParsed.companyUuid ?? null,
        currentContactId: schemaParsed.contactUuid ?? null,
        fingerprintBlocks: schemaParsed.fingerprintAnchors.map((anchor) => {
          if (isNil(anchor.blockId)) {
            const block = blocks.find((b) => b.Text === anchor.text);
            return {
              ...anchor,
              blockId: block?.Id,
            };
          }
          return anchor;
        }),
        packageTables: schemaParsed.packageTables,
        textractType: schemaParsed.textractType,
        transformBlocks: schemaParsed.transformAnchors.map((anchor) => {
          if (isNil(anchor.blockId)) {
            const block = blocks.find((b) => b.Text === anchor.text);
            return {
              ...anchor,
              blockId: block?.Id,
            };
          }
          return anchor;
        }),
        schemaFields: schemaParsed.schemaFields.map((schemaField) => {
          return {
            ...schemaField,
            show: true,
          };
        }),
        schemaIsMultiPage: schemaParsed.isMultiPage,
        schemaName: schemaParsed.name,
        schemaScannedDocumentType: schemaParsed.scannedDocumentType,
        schemaTextractJson: textractJson,
        selectedFileName: schemaParsed.originalFilename,
      };
    });
  };

  const updateSchema = async () => {
    if (stateRef.current.schemaName.length <= 2) {
      alert("Schema name must have length > 2");
      return;
    }

    if (
      !isNil(stateRef.current.selectedFileName) &&
      !isNil(stateRef.current.selectedSchema)
    ) {
      const updateSchemaInput: UpdateSchemaInput = {
        uuid: stateRef.current.selectedSchema,
        companyUuid: stateRef.current.currentCompanyId,
        contactUuid: stateRef.current.currentContactId,
        schema: {
          name: stateRef.current.schemaName,
          originalFilename: stateRef.current.selectedFileName,
          packageTables: stateRef.current.packageTables.map((p) => {
            return {
              blockId: p.blockId,
              headerRowIndex: p.headerRowIndex,
              title: p.title,
              packageTableColumns: p.packageTableColumns.map((c) => {
                return {
                  columnIndex: c.columnIndex,
                  header: c.header,
                  packageField: c.packageField,
                  required: c.required,
                };
              }),
            };
          }),
          isMultiPage: stateRef.current.schemaIsMultiPage,
          scannedDocumentType: stateRef.current.schemaScannedDocumentType,
          textractType: stateRef.current.schemaTextractType,
          fingerprintAnchors: stateRef.current.fingerprintBlocks.map(
            (anchor) => {
              return omitTypename(anchor);
            },
          ),
          transformAnchors: stateRef.current.transformBlocks.map((anchor) => {
            return omitTypename(anchor);
          }),
          schemaFields: stateRef.current.schemaFields.map((field) => {
            return {
              top: field.top,
              left: field.left,
              width: Math.abs(field.width),
              height: Math.abs(field.height),
              concatenateX: field.concatenateX,
              leftEdgeAnchor: omitUuid(omitTypename(field.leftEdgeAnchor)),
              rightEdgeAnchor: omitUuid(omitTypename(field.rightEdgeAnchor)),
              topEdgeAnchor: omitUuid(omitTypename(field.topEdgeAnchor)),
              bottomEdgeAnchor: omitUuid(omitTypename(field.bottomEdgeAnchor)),
              fieldExtractors: field.fieldExtractors.map((f) => {
                return {
                  type: f.type,
                  value: f.value,
                  mapping: f.mapping,
                };
              }),
              fieldMatchers: field.fieldMatchers.map((f) => {
                return {
                  type: f.type,
                  value: f.value,
                };
              }),
            };
          }),
        },
      };

      await updateSchemaMutation({
        variables: { updateSchemaInput },
      });
      alert("Schema updated!");
    }
  };

  const deleteSchema = async () => {
    if (isNil(stateRef.current.selectedSchema)) {
      alert("no schema selected, cant delete");
      return;
    }
    const res = await deleteSchemaMutation({
      variables: {
        uuid: stateRef.current.selectedSchema,
      },
    });
    if (res.data?.deleteSchema === null) {
      alert(
        "Unable to delete schema because schema company mappings are still present",
      );
      return;
    }
    setState((s) => {
      return {
        ...s,
        selectedSchema: null,
      };
    });
  };

  const handleDeleteSchemaRun = async () => {
    if (isNil(stateRef.current.currentSchemaRunTestSnapshot)) {
      alert("no schema run selected, cant delete");
      return;
    }
    await deleteSchemaRunMutation({
      variables: {
        deleteSchemaRunInput: {
          uuid: stateRef.current.currentSchemaRunTestSnapshot,
        },
      },
    });
    setState((s) => {
      return {
        ...s,
        currentSchemaRunTestSnapshot: null,
      };
    });
  };

  const updateFile = async (newFileName: string) => {
    setState({
      ...stateRef.current,
      selectedFileName: newFileName,
    });
  };

  const getTableTitle = (block: Block): string | undefined => {
    if (block.BlockType !== "TABLE") {
      return undefined;
    }
    const tableTitleId = block.Relationships?.find(
      (relationship) => relationship.Type === "TABLE_TITLE",
    )?.Ids?.[0];
    const tableTitleBlock = isNil(tableTitleId)
      ? undefined
      : state.textractBlocksById.get(tableTitleId);
    const childIds = tableTitleBlock?.Relationships?.[0]?.Ids ?? [];
    const childBlocks = filterNotNil(
      childIds.map((id) => {
        return state.textractBlocksById.get(id);
      }),
    );
    return filterNotNil(childBlocks.map((b) => b.Text)).join(" ");
  };

  const blocks: Block[] = state.textractBlocks.filter((block) => {
    if (state.addBlockMode === AddBlockMode.ADD_PACKAGE_TABLE) {
      return block.BlockType === "TABLE";
    }
    return block.BlockType === "LINE";
  });
  const blocksFilteredByEntityType = blocks.filter((block) => {
    if (
      isNil(state.currentEntityType) ||
      state.currentEntityType === "SHOW ALL"
    ) {
      return true;
    }
    return block.EntityTypes?.includes(state.currentEntityType as EntityType);
  });

  const handleMouseDown = (event: KonvaEventObject<MouseEvent>) => {
    const targetClassName = Object.getPrototypeOf(event.target).className;
    if (targetClassName === "Image") {
      setState((s) => ({
        ...s,
        selectedRectangleId: null,
      }));
    }
    if (targetClassName === "Rect") {
      // Dragging or resizing
      return;
    }
    if (
      (state.appMode === AppMode.CREATE || state.appMode === AppMode.EDIT) &&
      state.addBlockMode === AddBlockMode.DRAW_FIELD_BOXES
    ) {
      if (!isNil(state.addEdgeAnchorMode)) {
        return;
      }
      const pointerPosition = event.target.getStage()?.getPointerPosition();
      if (pointerPosition) {
        const { x, y } = pointerPosition;
        setState((s) => {
          return {
            ...s,
            schemaFieldBeingDrawn: {
              left: x,
              top: y,
              width: 0,
              height: 0,
              uuid: v4(),
              concatenateX: false,
              show: true,
              fieldBoundaries: [],
              fieldExtractors: [],
              fieldMatchers: [],
            },
          };
        });
      }
    }
  };

  const handleMouseUp = (event: KonvaEventObject<MouseEvent>) => {
    const pointerPosition = event.target.getStage()?.getPointerPosition();
    if (state.schemaFieldBeingDrawn && pointerPosition) {
      const sx = state.schemaFieldBeingDrawn.left;
      const sy = state.schemaFieldBeingDrawn.top;
      const { x, y } = pointerPosition;
      const width = x - sx;
      const height = y - sy;
      if (Math.abs(width) < 10 || Math.abs(height) < 10) {
        setState((s) => {
          return {
            ...s,
            schemaFieldBeingDrawn: null,
          };
        });
      } else {
        const schemaField: SchemaField = {
          left: Math.min(sx, x),
          top: Math.min(sy, y),
          width: Math.abs(x - sx),
          height: Math.abs(y - sy),
          concatenateX: false,
          show: true,
          uuid: state.schemaFieldBeingDrawn.uuid,
          leftEdgeAnchor: null,
          rightEdgeAnchor: null,
          topEdgeAnchor: null,
          bottomEdgeAnchor: null,
          fieldExtractors: [],
          fieldMatchers: [],
        };
        setState((s) => {
          return {
            ...s,
            schemaFieldBeingDrawn: null,
            schemaFields: [...s.schemaFields, schemaField],
          };
        });
      }
    }
  };

  const handleMouseMove = (event: KonvaEventObject<MouseEvent>) => {
    const pointerPosition = event.target.getStage()?.getPointerPosition();
    const { schemaFieldBeingDrawn } = state;
    if (schemaFieldBeingDrawn && pointerPosition) {
      const sx = schemaFieldBeingDrawn.left;
      const sy = schemaFieldBeingDrawn.top;
      const { x, y } = pointerPosition;
      setState((s) => {
        return {
          ...s,
          schemaFieldBeingDrawn: {
            left: sx,
            top: sy,
            width: x - sx,
            height: y - sy,
            concatenateX: false,
            show: true,
            uuid: schemaFieldBeingDrawn.uuid,
            fieldBoundaries: [],
            fieldExtractors: [],
            fieldMatchers: [],
          },
        };
      });
    }
  };

  const scaleSchemaField = (
    schemaFieldEntity: SchemaFieldEntity,
    xyTransform: XyTransformEntity,
    topEdgeAnchor: EdgeAnchorEntity | null = null,
    bottomEdgeAnchor: EdgeAnchorEntity | null = null,
    leftEdgeAnchor: EdgeAnchorEntity | null = null,
    rightEdgeAnchor: EdgeAnchorEntity | null = null,
  ): SchemaFieldEntity => {
    if (state.scaleXY) {
      let left = schemaFieldEntity.left + xyTransform.xTranslation * WIDTH;
      let top = schemaFieldEntity.top + xyTransform.yTranslation * HEIGHT;
      const leftDelta = left - xyTransform.xMin * WIDTH;
      const topDelta = top - xyTransform.yMin * HEIGHT;
      left -= leftDelta * (1 - xyTransform.xScale);
      top -= topDelta * (1 - xyTransform.yScale);
      const width = schemaFieldEntity.width * xyTransform.xScale;
      let height = schemaFieldEntity.height * xyTransform.yScale;

      const blocksByTextMap = new Map<string, Block[]>();
      state.textractBlocks.forEach((block: Block) => {
        if (block.BlockType !== "LINE" || isNil(block.Text)) {
          return;
        }
        if (blocksByTextMap.has(block.Text)) {
          blocksByTextMap.get(block.Text)?.push(block);
        } else {
          blocksByTextMap.set(block.Text, [block]);
        }
      });

      // TODO: Figure out how to make the anchor block scale back and forth
      // as the Scale X/Y button is toggled. Setting it on the field sets it in
      // stone so it doesn't toggle

      // Need to calculate top first! Bottom (height) depends on top
      if (!isNil(topEdgeAnchor)) {
        const anchor = topEdgeAnchor;
        const matchingBlock = blocksByTextMap.get(anchor.text)?.[0];
        if (isNil(matchingBlock)) {
          console.error(
            "Could not find matching edge anchor for:",
            anchor.text,
          );
        } else {
          let originalPoint = 0;
          let newPoint = 0;
          const { edge } = anchor;
          if (edge === Edge.Top) {
            newPoint = matchingBlock.Geometry?.BoundingBox?.Top! * HEIGHT;
            originalPoint = anchor.top * HEIGHT;
          } else if (edge === Edge.Bottom) {
            newPoint =
              (matchingBlock.Geometry?.BoundingBox?.Top! +
                matchingBlock.Geometry?.BoundingBox?.Height!) *
              HEIGHT;
            originalPoint = (anchor.top + anchor.height) * HEIGHT;
          }
          const originalDistance = schemaFieldEntity.top - originalPoint;
          const newDistance = originalDistance * xyTransform.yScale;
          // Formula:
          // Point on the edge anchor + distance between field and edge anchor
          // = top of the field
          top = newPoint + newDistance;
        }
      }
      if (!isNil(bottomEdgeAnchor)) {
        const anchor = bottomEdgeAnchor;
        const matchingBlock = blocksByTextMap.get(anchor.text)?.[0];
        if (isNil(matchingBlock)) {
          console.error(
            "Could not find matching edge anchor for:",
            anchor.text,
          );
        } else {
          let originalPoint = 0;
          let newPoint = 0;
          const { edge } = anchor;
          if (edge === Edge.Top) {
            newPoint = matchingBlock.Geometry?.BoundingBox?.Top! * HEIGHT;
            originalPoint = anchor.top * HEIGHT;
          } else if (edge === Edge.Bottom) {
            newPoint =
              (matchingBlock.Geometry?.BoundingBox?.Top! +
                matchingBlock.Geometry?.BoundingBox?.Height!) *
              HEIGHT;
            originalPoint = (anchor.top + anchor.height) * HEIGHT;
          }
          const originalDistance =
            originalPoint - schemaFieldEntity.top - schemaFieldEntity.height;
          const newDistance = originalDistance * xyTransform.yScale;
          // Formula:
          // Top of the field + height of the field + distance between field
          // and edge anchor = point on the edge anchor
          height = newPoint - top - newDistance;
        }
      }
      if (!isNil(leftEdgeAnchor)) {
        // TODO: Implement X edge anchoring
      }
      if (!isNil(rightEdgeAnchor)) {
        // TODO: Implement X edge anchoring
      }

      return {
        ...schemaFieldEntity,
        left,
        top,
        width,
        height,
      };
    }
    return schemaFieldEntity;
  };
  const scaleBoundingBox = (
    boundingBox: {
      left: number;
      top: number;
      width: number;
      height: number;
    },
    xyTransform: XyTransformEntity,
  ): BoundingBox | undefined => {
    if (
      isNil(state.scaleXY) ||
      isNil(boundingBox.left) ||
      isNil(boundingBox.top) ||
      isNil(boundingBox.width) ||
      isNil(boundingBox.height)
    ) {
      return {
        Left: boundingBox.left,
        Top: boundingBox.top,
        Width: boundingBox.width,
        Height: boundingBox.height,
      };
    }
    const left = boundingBox.left + xyTransform.xTranslation;
    const top = boundingBox.top + xyTransform.yTranslation;
    const leftDelta = left - xyTransform.xMin;
    const topDelta = top - xyTransform.yMin;
    return {
      Left: left - leftDelta * (1 - xyTransform.xScale),
      Top: top - topDelta * (1 - xyTransform.yScale),
      Width: boundingBox.width * xyTransform.xScale,
      Height: boundingBox.height * xyTransform.yScale,
    };
  };

  const scaledSchemaFieldBeingDrawn =
    state.schemaFieldBeingDrawn &&
    scaleSchemaField(state.schemaFieldBeingDrawn, state.xyTransform);
  const rectBeingDrawn =
    state.showFields && scaledSchemaFieldBeingDrawn ? (
      <Rect
        key={scaledSchemaFieldBeingDrawn.uuid}
        x={scaledSchemaFieldBeingDrawn.left}
        y={scaledSchemaFieldBeingDrawn.top}
        width={scaledSchemaFieldBeingDrawn.width}
        height={scaledSchemaFieldBeingDrawn.height}
        opacity={0.3}
        fill="blue"
      />
    ) : null;

  const handleDuplicateSchemaField = (id: string) => {
    const schemaField = stateRef.current.schemaFields.find(
      (a) => a.uuid === id,
    );
    if (schemaField) {
      const fieldExtractors = schemaField.fieldExtractors.map(
        (fieldExtractor) => {
          let { mapping } = fieldExtractor;
          if (isPackageField({ fieldMapping: mapping })) {
            mapping = incrementPackageField({
              fieldMapping: mapping,
            });
          }
          return {
            ...fieldExtractor,
            mapping,
          };
        },
      );
      const duplicate: SchemaField = {
        height: schemaField.height,
        uuid: v4(),
        left: schemaField.left,
        top: schemaField.top,
        width: schemaField.width,
        show: schemaField.show,
        concatenateX: schemaField.concatenateX,
        leftEdgeAnchor: schemaField.leftEdgeAnchor,
        rightEdgeAnchor: schemaField.rightEdgeAnchor,
        topEdgeAnchor: schemaField.topEdgeAnchor,
        bottomEdgeAnchor: schemaField.bottomEdgeAnchor,
        fieldExtractors,
        fieldMatchers: [...schemaField.fieldMatchers],
      };
      setState((s) => {
        return {
          ...s,
          schemaFields: [...s.schemaFields, duplicate],
        };
      });
    }
  };

  const handleExportSchema = () => {
    const schema = state.schemas.find((s) => s.uuid === state.selectedSchema);
    if (schema) {
      console.log(schema);
      alert("Check console!");
    }
  };

  const handleAddSchemaFieldEdgeAnchor = ({
    schemaFieldId,
    addEdgeAnchorMode,
    block,
  }: {
    schemaFieldId: string;
    addEdgeAnchorMode: AddEdgeAnchorMode;
    block: Block;
  }) => {
    const schemaField = stateRef.current.schemaFields.find(
      (s) => s.uuid === schemaFieldId,
    );
    if (isNil(schemaField)) {
      return;
    }
    let edge: Edge = Edge.Top;
    // Use the opposite edge as the default - so it comes up to the edge anchor
    // but doesn't overlap
    switch (addEdgeAnchorMode) {
      case AddEdgeAnchorMode.BOTTOM: {
        edge = Edge.Top;
        break;
      }
      case AddEdgeAnchorMode.TOP: {
        edge = Edge.Bottom;
        break;
      }
      case AddEdgeAnchorMode.LEFT: {
        edge = Edge.Right;
        break;
      }
      case AddEdgeAnchorMode.RIGHT: {
        edge = Edge.Left;
        break;
      }
      default: {
        exhaustive(addEdgeAnchorMode);
      }
    }
    const edgeAnchor = {
      blockId: block.Id,
      edge,
      left: block.Geometry?.BoundingBox?.Left!,
      top: block.Geometry?.BoundingBox?.Top!,
      height: block.Geometry?.BoundingBox?.Height!,
      width: block.Geometry?.BoundingBox?.Width!,
      text: block.Text!,
      uuid: v4(),
    };
    switch (addEdgeAnchorMode) {
      case AddEdgeAnchorMode.TOP: {
        schemaField.topEdgeAnchor = edgeAnchor;
        break;
      }
      case AddEdgeAnchorMode.BOTTOM: {
        schemaField.bottomEdgeAnchor = edgeAnchor;
        break;
      }
      case AddEdgeAnchorMode.LEFT: {
        schemaField.leftEdgeAnchor = edgeAnchor;
        break;
      }
      case AddEdgeAnchorMode.RIGHT: {
        schemaField.rightEdgeAnchor = edgeAnchor;
        break;
      }
      default: {
        exhaustive(addEdgeAnchorMode);
      }
    }
    setState((s) => {
      return {
        ...s,
        addEdgeAnchorMode: null,
        addEdgeAnchorSchemaFieldId: null,
      };
    });
  };

  const handleRemoveEdgeAnchor = ({
    schemaFieldId,
    side,
  }: {
    schemaFieldId: string;
    side: Side;
  }) => {
    const schemaField = stateRef.current.schemaFields.find(
      (s) => s.uuid === schemaFieldId,
    );
    if (isNil(schemaField)) {
      return;
    }
    switch (side) {
      case Side.BOTTOM: {
        schemaField.bottomEdgeAnchor = null;
        break;
      }
      case Side.TOP: {
        schemaField.topEdgeAnchor = null;
        break;
      }
      case Side.LEFT: {
        schemaField.leftEdgeAnchor = null;
        break;
      }
      case Side.RIGHT: {
        schemaField.rightEdgeAnchor = null;
        break;
      }
      default: {
        exhaustive(side);
      }
    }
    setState((s) => {
      return { ...s };
    });
  };

  const handleRemovePackageTable = (id: string) => {
    const packageTables = stateRef.current.packageTables.filter(
      (p) => p.uuid !== id,
    );
    setState((s) => {
      return {
        ...s,
        packageTables,
      };
    });
  };

  const handleSetEdgeAnchorEdge = ({
    schemaFieldId,
    side,
    edge,
  }: {
    schemaFieldId: string;
    side: Side;
    edge: Edge;
  }) => {
    const schemaField = stateRef.current.schemaFields.find(
      (s) => s.uuid === schemaFieldId,
    );
    if (isNil(schemaField)) {
      return;
    }
    switch (side) {
      case Side.TOP: {
        if (!isNil(schemaField.topEdgeAnchor)) {
          schemaField.topEdgeAnchor.edge = edge;
        }
        break;
      }
      case Side.BOTTOM: {
        if (!isNil(schemaField.bottomEdgeAnchor)) {
          schemaField.bottomEdgeAnchor.edge = edge;
        }
        break;
      }
      case Side.LEFT: {
        if (!isNil(schemaField.leftEdgeAnchor)) {
          schemaField.leftEdgeAnchor.edge = edge;
        }
        break;
      }
      case Side.RIGHT: {
        if (!isNil(schemaField.rightEdgeAnchor)) {
          schemaField.rightEdgeAnchor.edge = edge;
        }
        break;
      }
      default: {
        exhaustive(side);
      }
    }
    setState((s) => {
      return { ...s };
    });
  };

  const handleRemoveFieldExtractor = ({
    schemaFieldId,
    fieldExtractorId,
  }: {
    schemaFieldId: string;
    fieldExtractorId: string;
  }) => {
    const schemaFieldIndex = stateRef.current.schemaFields.findIndex(
      (s) => s.uuid === schemaFieldId,
    );
    const schemaField = stateRef.current.schemaFields[schemaFieldIndex];
    const fieldExtractorIndex = schemaField?.fieldExtractors.findIndex(
      (f) => f.uuid === fieldExtractorId,
    );
    if (
      isNil(schemaField) ||
      isNil(fieldExtractorIndex) ||
      fieldExtractorIndex === -1
    ) {
      return;
    }
    const fieldExtractors = [
      ...schemaField.fieldExtractors.slice(0, fieldExtractorIndex),
      ...schemaField.fieldExtractors.slice(fieldExtractorIndex + 1),
    ];
    setState((s) => ({
      ...s,
      showEditFieldExtractorModal: false,
      schemaFields: [
        ...stateRef.current.schemaFields.slice(0, schemaFieldIndex),
        {
          ...schemaField,
          fieldExtractors,
        },
        ...stateRef.current.schemaFields.slice(schemaFieldIndex + 1),
      ],
    }));
    setState((s) => ({
      ...s,
      showEditFieldExtractorModal: false,
      currentFieldExtractorId: null,
      currentSchemaFieldId: null,
    }));
  };

  const handleRemoveFieldMatcher = ({
    schemaFieldId,
    fieldMatcherId,
  }: {
    schemaFieldId: string;
    fieldMatcherId: string;
  }) => {
    const schemaFieldIndex = stateRef.current.schemaFields.findIndex(
      (s) => s.uuid === schemaFieldId,
    );
    if (schemaFieldIndex === -1) {
      return;
    }
    const schemaField = stateRef.current.schemaFields[schemaFieldIndex];
    // @ts-expect-error
    const fieldMatcherIndex = schemaField.fieldMatchers.findIndex(
      (f) => f.uuid === fieldMatcherId,
    );
    if (fieldMatcherIndex === -1) {
      return;
    }
    const fieldMatchers = [
      // @ts-expect-error
      ...schemaField.fieldMatchers.slice(0, fieldMatcherIndex),
      // @ts-expect-error
      ...schemaField.fieldMatchers.slice(fieldMatcherIndex + 1),
    ];
    // @ts-expect-error
    setState((s) => ({
      ...s,
      showEditFieldMatcherModal: false,
      schemaFields: [
        ...stateRef.current.schemaFields.slice(0, schemaFieldIndex),
        {
          ...schemaField,
          fieldMatchers,
        },
        ...stateRef.current.schemaFields.slice(schemaFieldIndex + 1),
      ],
    }));
    setState((s) => ({
      ...s,
      showEditFieldMatcherModal: false,
      currentFieldMatcherId: null,
      currentSchemaFieldId: null,
    }));
  };

  const handleSetSchemaFieldShow = (id: string, show: boolean) => {
    const schemaFieldIndex = stateRef.current.schemaFields.findIndex(
      (s) => s.uuid === id,
    );
    if (schemaFieldIndex === -1) {
      return;
    }
    const schemaField = stateRef.current.schemaFields[schemaFieldIndex];
    // @ts-ignore
    setState((s) => ({
      ...s,
      showEditFieldExtractorModal: false,
      schemaFields: [
        ...stateRef.current.schemaFields.slice(0, schemaFieldIndex),
        {
          ...schemaField,
          show,
        },
        ...stateRef.current.schemaFields.slice(schemaFieldIndex + 1),
      ],
    }));
  };

  const handleSetShowFieldExtractorModal = ({
    schemaFieldId,
    fieldExtractorId,
  }: {
    schemaFieldId: string;
    fieldExtractorId: string;
  }) => {
    setState((s) => ({
      ...s,
      showEditFieldExtractorModal: true,
      currentFieldExtractorId: fieldExtractorId,
      currentSchemaFieldId: schemaFieldId,
    }));
  };

  const handleSetShowEditFieldMatcherModal = ({
    schemaFieldId,
    fieldMatcherId,
  }: {
    schemaFieldId: string;
    fieldMatcherId: string;
  }) => {
    setState((s) => ({
      ...s,
      showEditFieldMatcherModal: true,
      currentFieldMatcherId: fieldMatcherId,
      currentSchemaFieldId: schemaFieldId,
    }));
  };

  const handleNormalizeDocument = async (
    normalizeDocumentInput: NormalizeDocumentInput,
  ) => {
    await normalizeDocument({
      variables: {
        normalizeDocumentInput,
      },
    });
    alert("Normalization complete!");
    await allScannedDocumentsRefetch();
  };

  const handleReanalyzePage = async () => {
    if (
      !isNil(stateRef.current.currentTestFilePage) &&
      !isNil(stateRef.current.selectedSchema)
    ) {
      const reanalyzePageInput: ReanalyzePageInput = {
        documentName: stateRef.current.currentTestFilePage,
        schemaUuid: stateRef.current.selectedSchema,
      };
      await reanalyzePage({
        variables: {
          reanalyzePageInput,
        },
      });
      alert("Re-analysis complete!");
    }
  };

  const handleReanalyzeSchema = async () => {
    const schema = stateRef.current.schemas.find(
      (s) => s.uuid === stateRef.current.selectedSchema,
    );
    if (isNil(schema)) {
      alert(`Could not find schema: ${stateRef.current.selectedSchema}`);
      return;
    }
    const reanalyzeSchemaFileInput: ReanalyzeSchemaFileInput = {
      pngFilename: schema.originalFilename,
      textractType: stateRef.current.schemaTextractType,
    };
    await reanalyzeSchemaFile({
      variables: {
        reanalyzeSchemaFileInput,
      },
    });
    alert("Re-analysis complete!");
  };

  const handleRemoveField = (id: string) => {
    setState((s) => {
      return {
        ...s,
        schemaFields: s.schemaFields.filter((f) => {
          return f.uuid !== id;
        }),
      };
    });
  };

  const handlerRemoveFingerprintAnchor = (id: string) => {
    setState((s) => {
      return {
        ...s,
        fingerprintBlocks: s.fingerprintBlocks.filter((b) => {
          return b.blockId !== id;
        }),
      };
    });
  };

  const handleRemoveTransformAnchor = (id: string) => {
    setState((s) => {
      return {
        ...s,
        transformBlocks: s.transformBlocks.filter((b) => {
          return b.blockId !== id;
        }),
      };
    });
  };

  const handleRotateDocument = async (
    rotateDocumentInput: RotateDocumentInput,
  ) => {
    await rotateDocument({
      variables: {
        rotateDocumentInput,
      },
    });
    alert("Rotation complete!");
    allScannedDocumentsRefetch();
  };

  const handleSetAddBlockMode = (addBlockMode: AddBlockMode) => {
    setState((s) => {
      return {
        ...s,
        addBlockMode,
        addEdgeAnchorMode: null,
        addEdgeAnchorSchemaFieldId: null,
      };
    });
  };

  const handleSetAddEdgeAnchorMode = (
    mode: AddEdgeAnchorMode,
    fieldId: string,
  ) => {
    setState((s) => {
      return {
        ...s,
        addBlockMode: AddBlockMode.DRAW_FIELD_BOXES,
        addEdgeAnchorMode: mode,
        addEdgeAnchorSchemaFieldId: fieldId,
      };
    });
  };

  const handleSetAppMode = (appMode: AppMode) => {
    schemaRunTestSnapshotsRefetch();
    allScannedDocumentsRefetch();
    setState((s) => {
      return {
        ...s,
        currentCompanyId: null,
        fingerprintBlocks: [],
        schemaFields: [],
        schemaIsMultiPage: false,
        schemaName: "",
        selectedSchema: null,
        selectedFileName: null,
        transformBlocks: [],
        textractBlocks: [],
        appMode,
      };
    });
  };
  const handleSetUploadFileType = (uploadFileType: UploadFileType) => {
    setState((s) => {
      return {
        ...s,
        uploadFileType,
      };
    });
  };

  const handleSetSelectedSchema = (uuid: string) => {
    const schema = stateRef.current.schemas.find((s) => s.uuid === uuid);
    const companyUuids = state.companies.map((c) => c.uuid);
    let currentCompanyId: string | null = null;
    let currentContactId: string | null = null;
    // When we wipe the database on local dev, the company / contact uuid
    // get out of sync
    if (companyUuids.includes(schema?.companyUuid ?? "")) {
      currentCompanyId = schema?.companyUuid ?? null;
      currentContactId = schema?.contactUuid ?? null;
    }

    if (stateRef.current.appMode === AppMode.EDIT) {
      setState((s) => ({
        ...s,
        currentCompanyId,
        currentContactId,
        selectedFileName: schema?.originalFilename ?? null,
        schemaName: schema?.name ?? "",
        selectedSchema: uuid,
      }));
    } else {
      setState((s) => ({
        ...s,
        currentCompanyId,
        currentContactId,
        schemaName: schema?.name ?? "",
        selectedSchema: uuid,
      }));
    }
    setSelectedSchemaUuid(uuid);
  };

  useEffect(() => {
    if (!isNil(selectedSchemaUuid)) {
      handleSetSelectedSchema(selectedSchemaUuid);
    }
  }, []);

  const handleTestSchema = async (testSchemaInput: TestSchemaInput) => {
    const testSchemaResult = await testSchema({
      variables: { testSchemaInput },
    });
    window.open(`/schema-runs/${testSchemaResult.data?.testSchema}`, "_blank");
  };

  const handleImportTestSnapshot = async () => {
    const testSnapshotJson = prompt("Paste JSON");
    if (isEmpty(testSnapshotJson) || isNil(testSnapshotJson)) {
      return;
    }
    const schemaParsed = JSON.parse(testSnapshotJson) as SchemaRunFragment;
    const importTestSnapshotResult = await importTestSnapshotMutation({
      variables: {
        importTestSnapshotInput: {
          testSnapshot: {
            bucket: schemaParsed.bucket,
            key: schemaParsed.key,

            schemaName: schemaParsed.schema.name,
            extractedFields: schemaParsed.extractedFields.map(
              (extractedField) => {
                return {
                  field: extractedField.field,
                  value: extractedField.value,
                };
              },
            ),
            fingerprintAnchorMatches: schemaParsed.fingerprintAnchorMatches.map(
              (fingerprintAnchorMatch) => {
                return {
                  text: fingerprintAnchorMatch.text,
                  substring: fingerprintAnchorMatch.substring,
                };
              },
            ),
            transformAnchorMatches: schemaParsed.transformAnchorMatches.map(
              (transformAnchorMatch) => {
                return {
                  text: transformAnchorMatch.text,
                };
              },
            ),
            schemaRunPages: schemaParsed.schemaRunPages.map((schemaRunPage) => {
              return {
                bucket: schemaRunPage.bucket,
                key: schemaRunPage.key,
                page: schemaRunPage.page,
                extractedFields: schemaRunPage.extractedFields.map(
                  (extractedField) => {
                    return {
                      field: extractedField.field,
                      value: extractedField.value,
                    };
                  },
                ),
                fingerprintAnchorMatches:
                  schemaRunPage.fingerprintAnchorMatches.map(
                    (fingerprintAnchorMatch) => {
                      return {
                        text: fingerprintAnchorMatch.text,
                        substring: fingerprintAnchorMatch.substring,
                      };
                    },
                  ),
                transformAnchorMatches:
                  schemaRunPage.transformAnchorMatches.map(
                    (transformAnchorMatch) => {
                      return {
                        text: transformAnchorMatch.text,
                      };
                    },
                  ),
              };
            }),
          },
        },
      },
    });
    if (isNil(importTestSnapshotResult.data?.importTestSnapshot)) {
      alert("Import failed :(");
    } else {
      alert("Import successful!");
    }
  };

  const handleTestOneSnapshot = async () => {
    if (isNil(stateRef.current.currentSchemaRunTestSnapshot)) {
      console.error("No test snapshot selected");
    } else {
      const testOneSnapshotResult = await testOneSnapshotMutation({
        variables: {
          testOneSnapshotInput: {
            schemaRunUuid: stateRef.current.currentSchemaRunTestSnapshot,
          },
        },
      });
      window.open(
        `/schema-test-lists/${testOneSnapshotResult.data?.testOneSnapshot}`,
        "_blank",
      );
    }
  };

  const handleTestAllSnapshots = async () => {
    const testAllSnapshotsResult = await testAllSnapshotsMutation();
    window.open(
      `/schema-test-lists/${testAllSnapshotsResult.data?.testAllSnapshots}`,
      "_blank",
    );
  };

  const handleSetCurrentSchemaTestRunSnapshot = (id: string) => {
    const currentSchemaRunTestSnapshot = state.schemaRunTestSnapshots.find(
      (s) => s.uuid === id,
    );
    // TODO
    const currentTestFilePages =
      currentSchemaRunTestSnapshot?.schemaRunPages.map(
        (schemaRunPage) => schemaRunPage.key,
      ) ?? [];
    const filename = isNil(currentSchemaRunTestSnapshot)
      ? null
      : filenameFromKey(currentSchemaRunTestSnapshot.key);
    // @ts-ignore
    setState((s) => {
      return {
        ...s,
        currentSchemaRunTestSnapshot: id,
        currentTestFilename: filename,
        currentTestFilePage: currentTestFilePages[0],
        currentTestFilePages,
        selectedSchema: currentSchemaRunTestSnapshot?.schema.uuid ?? null,
      };
    });
  };

  const handleSetCurrentTestFilename = (filename: string) => {
    const currentTestFilePages = state.testFilenames
      .filter((f) => f?.toLowerCase()?.endsWith(".png"))
      .filter((f) => f?.includes(filename) === true)
      .sort();
    setState((s) => {
      return {
        ...s,
        currentTestFilename: filename,
        currentTestFilePage: currentTestFilePages[0],
        currentTestFilePages,
      };
    });
  };

  const handleSetCurrentTestFilePage = (filename: string) => {
    setState((s) => {
      return {
        ...s,
        currentTestFilePage: filename,
      };
    });
  };

  const handleSetFingerprintAnchorRequired = (id: string, value: boolean) => {
    setState((s) => {
      const index = s.fingerprintBlocks.findIndex((f) => f.blockId === id);
      const fingerprintAnchor = s.fingerprintBlocks[index];
      if (isNil(fingerprintAnchor)) {
        return s;
      }
      return {
        ...s,
        fingerprintBlocks: [
          ...s.fingerprintBlocks.slice(0, index),
          {
            ...fingerprintAnchor,
            required: value,
          },
          ...s.fingerprintBlocks.slice(index + 1),
        ],
      };
    });
  };

  const handleSetSchemaScannedDocumentType = (
    scannedDocumentType: ScannedDocumentType,
  ) => {
    setState((s) => {
      return {
        ...s,
        schemaScannedDocumentType: scannedDocumentType,
      };
    });
  };

  const handleSetSchemaTextractType = (textractType: TextractType) => {
    setState((s) => {
      return {
        ...s,
        schemaTextractType: textractType,
      };
    });
  };

  const handleUploadSchemaTestFile = async ({
    file,
  }: {
    file: File;
  }): Promise<void> => {
    switch (state.uploadFileType) {
      case null: {
        console.error(
          "State upload file type is null, could not upload schema test file",
        );
        return;
      }
      case UploadFileType.SCHEMA: {
        const putUrl = await schemaFilePutUrl({
          variables: {
            schemaFilePutUrlInput: {
              filename: file.name,
            },
          },
        });
        if (!isNil(putUrl.data)) {
          await fetch(putUrl.data?.schemaFilePutUrl, {
            method: "PUT",
            body: file,
            headers: { "Content-Type": file.type },
          });
          await textractSchemaFile({
            variables: {
              textractSchemaFileInput: {
                filename: file.name,
                textractType: stateRef.current.schemaTextractType,
              },
            },
          });
        }
        return;
      }
      case UploadFileType.TEST: {
        if (isNil(stateRef.current.selectedSchema)) {
          console.error("No schema selected, cannot upload test file");
        } else {
          const putUrl = await schemaTestFilePutUrl({
            variables: {
              schemaTestFilePutUrlInput: {
                filename: file.name,
              },
            },
          });
          if (!isNil(putUrl.data)) {
            await fetch(putUrl.data?.schemaTestFilePutUrl, {
              method: "PUT",
              body: file,
              headers: { "Content-Type": file.type },
            });
            await textractSchemaTestFile({
              variables: {
                textractSchemaTestFileInput: {
                  filename: file.name,
                  schemaUuid: stateRef.current.selectedSchema,
                },
              },
            });
          }
        }
        break;
      }
      default: {
        exhaustive(state.uploadFileType);
      }
    }
  };

  return (
    <div className="App" style={{ display: "flex" }}>
      <Sidebar
        addBlockMode={state.addBlockMode}
        addEdgeAnchorMode={state.addEdgeAnchorMode}
        appMode={state.appMode}
        allFileNames={state.allFilesInS3}
        createSchema={createSchema}
        createSchemaLoading={createSchemaLoading}
        currentCompanyId={state.currentCompanyId}
        currentContactId={state.currentContactId}
        currentFileName={state.selectedFileName}
        currentSchemaRunTestSnapshot={state.currentSchemaRunTestSnapshot}
        currentTestFilename={state.currentTestFilename}
        currentTestFilePage={state.currentTestFilePage}
        currentTestFilePages={state.currentTestFilePages}
        deleteSchema={deleteSchema}
        deleteSchemaLoading={deleteSchemaLoading}
        deleteSchemaRun={handleDeleteSchemaRun}
        deleteSchemaRunLoading={deleteSchemaRunLoading}
        duplicateSchemaField={handleDuplicateSchemaField}
        exportSchema={handleExportSchema}
        fields={state.schemaFields}
        fingerprintAnchors={state.fingerprintBlocks}
        importSchema={handleImportSchema}
        importTestSnapshot={handleImportTestSnapshot}
        importTestSnapshotLoading={importTestSnapshotLoading}
        reanalyzePage={handleReanalyzePage}
        reanalyzePageLoading={reanalyzePageLoading}
        reanalyzeSchema={handleReanalyzeSchema}
        reanalyzeSchemaLoading={reanalyzeSchemaFileLoading}
        removeFieldExtractor={handleRemoveFieldExtractor}
        removeFieldMatcher={handleRemoveFieldMatcher}
        setSchemaFieldShow={handleSetSchemaFieldShow}
        setShowEditFieldExtractorModal={handleSetShowFieldExtractorModal}
        setShowEditFieldMatcherModal={handleSetShowEditFieldMatcherModal}
        normalizeDocument={handleNormalizeDocument}
        normalizeDocumentLoading={normalizeDocumentLoading}
        packageTables={stateRef.current.packageTables}
        removeField={handleRemoveField}
        removeEdgeAnchor={handleRemoveEdgeAnchor}
        removeFingerprintAnchor={handlerRemoveFingerprintAnchor}
        removePackageTable={handleRemovePackageTable}
        removeTransformAnchor={handleRemoveTransformAnchor}
        rotateDocument={handleRotateDocument}
        scaleXY={state.scaleXY}
        schemaIsMultiPage={state.schemaIsMultiPage}
        schemaName={state.schemaName}
        schemaRunTestSnapshots={state.schemaRunTestSnapshots}
        schemaScannedDocumentType={state.schemaScannedDocumentType}
        schemaTextractType={state.schemaTextractType}
        schemas={state.schemas}
        selectedRectangleId={state.selectedRectangleId}
        setAddBlockMode={handleSetAddBlockMode}
        setAddEdgeAnchorMode={handleSetAddEdgeAnchorMode}
        setAppMode={handleSetAppMode}
        setCurrentContactId={(id) => {
          setState({ ...stateRef.current, currentContactId: id });
        }}
        setCurrentCompany={(id) => {
          setState({
            ...stateRef.current,
            currentCompanyId: id,
            currentContactId: null,
          });
        }}
        setCurrentFileName={updateFile}
        setCurrentSchemaRunTestSnapshot={handleSetCurrentSchemaTestRunSnapshot}
        setCurrentTestFilename={handleSetCurrentTestFilename}
        setCurrentTestFilePage={handleSetCurrentTestFilePage}
        setEdgeAnchorEdge={handleSetEdgeAnchorEdge}
        setFingerprintAnchorRequired={handleSetFingerprintAnchorRequired}
        setScaleXY={(scale) => {
          setState((s) => {
            return {
              ...s,
              scaleXY: scale,
            };
          });
        }}
        setSchemaIsMultiPage={(isMultiPage) => {
          setState((s) => {
            return {
              ...s,
              schemaIsMultiPage: isMultiPage,
            };
          });
        }}
        setSchemaName={(name) => {
          setState((s) => {
            return {
              ...s,
              schemaName: name,
            };
          });
        }}
        setSchemaScannedDocumentType={handleSetSchemaScannedDocumentType}
        setSchemaTextractType={handleSetSchemaTextractType}
        setSelectedRectangleId={(id: string) => {
          setState((s) => ({ ...s, selectedRectangleId: id }));
        }}
        selectedSchemaUuid={stateRef.current.selectedSchema}
        setSelectedSchema={handleSetSelectedSchema}
        setShowAddFieldExtractorModal={(show: boolean, id: string) => {
          setState((s) => {
            return {
              ...s,
              currentSchemaFieldId: id,
              showAddFieldExtractorModal: show,
            };
          });
        }}
        setShowAddFieldMatcherModal={(show: boolean, id: string) => {
          setState((s) => {
            return {
              ...s,
              currentSchemaFieldId: id,
              showAddFieldMatcherModal: show,
            };
          });
        }}
        setShowEditPackageTableModal={(id: string) => {
          setState((s) => {
            return {
              ...s,
              currentPackageTableId: id,
              showEditPackageTableModal: true,
            };
          });
        }}
        setShowPackageTables={(show: boolean) => {
          setState((s) => {
            return {
              ...s,
              showPackageTables: show,
            };
          });
        }}
        setShowEditFingerprintAnchorModal={(id: string) => {
          setState((s) => {
            return {
              ...s,
              currentFingerprintAnchorId: id,
              showEditFingerprintAnchorModal: true,
            };
          });
        }}
        setShowFields={(show: boolean) => {
          setState((s) => {
            return {
              ...s,
              showFields: show,
            };
          });
        }}
        setShowFingerprintAnchors={(show: boolean) => {
          setState((s) => {
            return {
              ...s,
              showFingerprintAnchors: show,
            };
          });
        }}
        setShowTransformAnchors={(show: boolean) => {
          setState((s) => {
            return {
              ...s,
              showTransformAnchors: show,
            };
          });
        }}
        setShowSelectedFileLines={(show: boolean) => {
          setState((s) => {
            return {
              ...s,
              showSelectedFileLines: show,
            };
          });
        }}
        setShowUploadFileModal={(show: boolean) => {
          setState((s) => {
            return {
              ...s,
              showUploadFileModal: show,
            };
          });
        }}
        setUploadFileType={handleSetUploadFileType}
        showFields={state.showFields}
        showFingerprintAnchors={state.showFingerprintAnchors}
        showPackageTables={state.showPackageTables}
        showTransformAnchors={state.showTransformAnchors}
        showSelectedFileLines={state.showSelectedFileLines}
        testFilenames={state.testFilenames.filter((filename) =>
          filename?.toLowerCase()?.endsWith(".pdf"),
        )}
        testAllSnapshots={handleTestAllSnapshots}
        testAllSnapshotsLoading={testAllSnapshotsLoading}
        testOneSnapshot={handleTestOneSnapshot}
        testOneSnapshotLoading={testOneSnapshotLoading}
        testSchema={handleTestSchema}
        testSchemaLoading={testSchemaLoading}
        transformAnchors={state.transformBlocks}
        updateSchema={updateSchema}
        updateSchemaLoading={updateSchemaLoading}
      />
      <div
        style={{
          width: "75vw",
          position: "relative",
          height: "calc(100vh - 50px)",
          overflow: "auto",
        }}
      >
        <Stage
          width={WIDTH}
          height={HEIGHT}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
        >
          <Layer>
            {!isNil(state.selectedFileName) &&
              state.appMode !== AppMode.TEST && (
                <URLImage
                  url={`https://${process.env.REACT_APP_AWS_SCAN_DATA_BUCKET_NAME}.s3.us-east-1.amazonaws.com/${state.selectedFileName}`}
                />
              )}
            {!isNil(state.currentTestFilePage) &&
              state.appMode === AppMode.TEST && (
                <URLImage
                  url={`https://${process.env.REACT_APP_AWS_SCAN_DATA_BUCKET_NAME}.s3.us-east-1.amazonaws.com/${state.currentTestFilePage}`}
                />
              )}
            {!isNil(state.currentTestFilePage) &&
              state.appMode === AppMode.TESTS && (
                <URLImage
                  url={`https://${process.env.REACT_APP_AWS_SCAN_DATA_BUCKET_NAME}.s3.us-east-1.amazonaws.com/${state.currentTestFilePage}`}
                />
              )}
            {!isNil(state.showSelectedFileLines) &&
              blocksFilteredByEntityType.map((block) => {
                return (
                  <Rect
                    key={block.Id}
                    x={(block.Geometry?.BoundingBox?.Left ?? 0) * WIDTH}
                    y={(block.Geometry?.BoundingBox?.Top ?? 0) * HEIGHT}
                    width={(block.Geometry?.BoundingBox?.Width ?? 0) * WIDTH}
                    height={(block.Geometry?.BoundingBox?.Height ?? 0) * HEIGHT}
                    opacity={0.3}
                    fill="orange"
                    onClick={() => {
                      if (
                        state.appMode === AppMode.CREATE ||
                        state.appMode === AppMode.EDIT
                      ) {
                        switch (state.addBlockMode) {
                          case AddBlockMode.ADD_FINGERPRINT_BLOCK: {
                            const fingerprintBlockIds =
                              state.fingerprintBlocks.map((b) => b.blockId);
                            if (!fingerprintBlockIds.includes(block.Id)) {
                              setState((s) => ({
                                ...s,
                                fingerprintBlocks: s.fingerprintBlocks.concat({
                                  blockId: block.Id,
                                  left: block.Geometry?.BoundingBox?.Left!,
                                  top: block.Geometry?.BoundingBox?.Top!,
                                  height: block.Geometry?.BoundingBox?.Height!,
                                  width: block.Geometry?.BoundingBox?.Width!,
                                  text: block.Text!,
                                  substring: null,
                                  required: false,
                                }),
                              }));
                            }
                            break;
                          }
                          case AddBlockMode.ADD_TRANSFORM_BLOCK: {
                            const transformBlockIds = state.transformBlocks.map(
                              (b) => b.blockId,
                            );
                            if (!transformBlockIds.includes(block.Id)) {
                              setState((s) => ({
                                ...s,
                                transformBlocks: s.transformBlocks.concat({
                                  blockId: block.Id,
                                  left: block.Geometry?.BoundingBox?.Left!,
                                  top: block.Geometry?.BoundingBox?.Top!,
                                  height: block.Geometry?.BoundingBox?.Height!,
                                  width: block.Geometry?.BoundingBox?.Width!,
                                  text: block.Text!,
                                }),
                              }));
                            }
                            break;
                          }
                          case AddBlockMode.DRAW_FIELD_BOXES: {
                            if (
                              !isNil(state.addEdgeAnchorMode) &&
                              !isNil(state.addEdgeAnchorSchemaFieldId)
                            ) {
                              handleAddSchemaFieldEdgeAnchor({
                                addEdgeAnchorMode: state.addEdgeAnchorMode,
                                schemaFieldId: state.addEdgeAnchorSchemaFieldId,
                                block,
                              });
                            }
                            break;
                          }
                          case AddBlockMode.ADD_PACKAGE_TABLE: {
                            console.log("block:", block);
                            let tableTitle: string | undefined;
                            if (block.BlockType === "TABLE") {
                              tableTitle = getTableTitle(block);

                              console.log(tableTitle);
                            }
                            const tableChildrenIds = block.Relationships?.find(
                              (relationship) => relationship.Type === "CHILD",
                            )?.Ids;
                            if (isNil(tableChildrenIds)) {
                              console.error("No table children found:", block);
                              alert(
                                "Error - no table children found, could not add table",
                              );
                              break;
                            }
                            const childBlocks = filterNotNil(
                              tableChildrenIds.map((id) => {
                                return state.textractBlocksById.get(id);
                              }),
                            );
                            let columnHeaders = childBlocks.filter((b) => {
                              return b.EntityTypes?.includes("COLUMN_HEADER");
                            });

                            console.log("column headers:", columnHeaders);
                            // The headers may span multiple rows so take the
                            // last row
                            let headerRowIndex = columnHeaders.at(-1)?.RowIndex;
                            if (isNil(headerRowIndex)) {
                              console.warn("No column headers found:", block);
                              const headerRowIndexString = prompt(
                                "No column headers found, manually choose header row index (starts at 1)",
                              );
                              if (!isNil(headerRowIndexString)) {
                                headerRowIndex = Number.parseInt(
                                  headerRowIndexString,
                                  10,
                                );
                                columnHeaders = childBlocks.filter((b) => {
                                  return b.RowIndex === headerRowIndex;
                                });
                              }
                            }
                            const packageTableColumns: PackageTableColumnEntity[] =
                              filterNotNil(
                                columnHeaders.map((b) => {
                                  const childIds =
                                    b.Relationships?.[0]?.Ids ?? [];
                                  if (childIds.length === 0) {
                                    console.error(
                                      "column header had no children:",
                                      block,
                                    );
                                    return null;
                                  }
                                  const children = filterNotNil(
                                    childIds.map((id) => {
                                      return state.textractBlocksById.get(id);
                                    }),
                                  );
                                  if (isNil(block.ColumnIndex)) {
                                    console.error(
                                      "header had nil column index:",
                                      block,
                                    );
                                    return null;
                                  }
                                  return {
                                    columnIndex: block.ColumnIndex,
                                    header: filterNotNil(
                                      children.map((c) => c.Text),
                                    ).join(" "),
                                    packageField: null,
                                    required: false,
                                    uuid: v4(),
                                  };
                                }),
                              );

                            console.log(
                              "package table columns:",
                              packageTableColumns,
                            );
                            const blockId = block.Id;
                            if (!isNil(blockId) && !isNil(headerRowIndex)) {
                              setState((s) => {
                                return {
                                  ...s,
                                  packageTables: s.packageTables.concat({
                                    blockId,
                                    // Not sure why the nil check doesn't work
                                    headerRowIndex: headerRowIndex,
                                    packageTableColumns,
                                    title: tableTitle,
                                    uuid: v4(),
                                  }),
                                };
                              });
                            }
                            break;
                          }
                          default: {
                            exhaustive(state.addBlockMode);
                          }
                        }
                      }
                    }}
                  />
                );
              })}
            {state.showFingerprintAnchors &&
              state.fingerprintBlocks.map((block) => {
                const scaled = scaleBoundingBox(block, state.xyTransform);
                return (
                  <Rectangle
                    key={block.blockId}
                    show
                    allowTransform={false}
                    isSelected={state.selectedRectangleId === block.blockId}
                    x={(scaled?.Left ?? 0) * WIDTH}
                    y={(scaled?.Top ?? 0) * HEIGHT}
                    width={(scaled?.Width ?? 0) * WIDTH}
                    height={(scaled?.Height ?? 0) * HEIGHT}
                    opacity={0.3}
                    fill={
                      state.selectedRectangleId === block.blockId
                        ? "yellow"
                        : "red"
                    }
                    onSelect={() => {
                      setState((s) => ({
                        ...s,
                        selectedRectangleId: block.blockId ?? null,
                      }));
                    }}
                    onChange={() => {}}
                  />
                );
              })}
            {state.showTransformAnchors &&
              state.transformBlocks.map((block) => {
                const scaled = scaleBoundingBox(block, state.xyTransform);
                return (
                  <Rectangle
                    key={block.blockId}
                    show
                    allowTransform={false}
                    isSelected={state.selectedRectangleId === block.blockId}
                    x={(scaled?.Left ?? 0) * WIDTH}
                    y={(scaled?.Top ?? 0) * HEIGHT}
                    width={(scaled?.Width ?? 0) * WIDTH}
                    height={(scaled?.Height ?? 0) * HEIGHT}
                    opacity={0.3}
                    fill={
                      state.selectedRectangleId === block.blockId
                        ? "yellow"
                        : "green"
                    }
                    onSelect={() => {
                      setState((s) => ({
                        ...s,
                        selectedRectangleId: block.blockId ?? null,
                      }));
                    }}
                    onChange={() => {}}
                  />
                );
              })}
            {state.showFields &&
              state.schemaFields.map((field, idx) => {
                const edgeAnchors = filterNotNil([
                  field.topEdgeAnchor,
                  field.bottomEdgeAnchor,
                  field.leftEdgeAnchor,
                  field.rightEdgeAnchor,
                ]);
                // Disable scaling if there are edge anchors in that direction.
                // Assume the edge anchor gives correct positioning
                const scaled = scaleSchemaField(
                  field,
                  state.xyTransform,
                  field.topEdgeAnchor,
                  field.bottomEdgeAnchor,
                  field.leftEdgeAnchor,
                  field.rightEdgeAnchor,
                );
                return (
                  <>
                    {edgeAnchors.map((edgeAnchor) => {
                      if (isNil(edgeAnchor.blockId)) {
                        return null;
                      }
                      return (
                        <Rectangle
                          key={edgeAnchor.blockId}
                          show
                          allowTransform={false}
                          isSelected={
                            state.selectedRectangleId === edgeAnchor.blockId
                          }
                          x={edgeAnchor.left * WIDTH}
                          y={edgeAnchor.top * HEIGHT}
                          width={edgeAnchor.width * WIDTH}
                          height={edgeAnchor.height * HEIGHT}
                          opacity={0.3}
                          fill={
                            state.selectedRectangleId === edgeAnchor.blockId
                              ? "yellow"
                              : "cyan"
                          }
                          onSelect={() => {
                            if (
                              !isNil(state.addEdgeAnchorMode) &&
                              !isNil(state.addEdgeAnchorSchemaFieldId)
                            ) {
                              const block = state.blocksById.get(
                                edgeAnchor.blockId!,
                              );
                              if (!isNil(block)) {
                                handleAddSchemaFieldEdgeAnchor({
                                  addEdgeAnchorMode: state.addEdgeAnchorMode,
                                  schemaFieldId:
                                    state.addEdgeAnchorSchemaFieldId,
                                  block,
                                });
                              }
                            } else {
                              setState((s) => ({
                                ...s,
                                selectedRectangleId: edgeAnchor.blockId ?? null,
                              }));
                            }
                          }}
                          onChange={() => {}}
                        />
                      );
                    })}
                    <Rectangle
                      key={field.uuid}
                      allowTransform={state.appMode === AppMode.EDIT}
                      show={field.show}
                      isSelected={state.selectedRectangleId === field.uuid}
                      x={scaled.left}
                      y={scaled.top}
                      width={scaled.width}
                      height={scaled.height}
                      opacity={field.show ? 0.3 : 0.2}
                      fill={
                        field.show
                          ? state.selectedRectangleId === field.uuid
                            ? "yellow"
                            : "blue"
                          : "gray"
                      }
                      onSelect={() => {
                        if (field.show) {
                          setState((s) => ({
                            ...s,
                            selectedRectangleId: field.uuid ?? null,
                          }));
                        }
                      }}
                      onChange={({
                        x,
                        y,
                        width,
                        height,
                      }: {
                        x: number;
                        y: number;
                        width?: number;
                        height?: number;
                      }) => {
                        const changedField: SchemaField = {
                          uuid: field.uuid,
                          left: x,
                          top: y,
                          show: true,
                          concatenateX: field.concatenateX,
                          width: width ?? field.width,
                          height: height ?? field.height,
                          leftEdgeAnchor: field.leftEdgeAnchor,
                          rightEdgeAnchor: field.rightEdgeAnchor,
                          topEdgeAnchor: field.topEdgeAnchor,
                          bottomEdgeAnchor: field.bottomEdgeAnchor,
                          fieldExtractors: [...field.fieldExtractors],
                          fieldMatchers: [...field.fieldMatchers],
                        };
                        setState((s) => ({
                          ...s,
                          schemaFields: [
                            ...s.schemaFields.slice(0, idx),
                            changedField,
                            ...s.schemaFields.slice(idx + 1),
                          ],
                          selectedRectangleId: field.uuid,
                        }));
                      }}
                    />
                  </>
                );
              })}
            {rectBeingDrawn}
          </Layer>
        </Stage>
      </div>
      {state.showAddFieldExtractorModal && (
        <AddFieldExtractorModal
          companyUuid={state.currentCompanyId}
          isOpen={state.showAddFieldExtractorModal}
          handleCreate={({
            type,
            value,
            mapping,
          }: {
            type: ExtractionType;
            value: string;
            mapping: FieldMapping;
          }) => {
            const schemaField = stateRef.current.schemaFields.find(
              (s) => s.uuid === stateRef.current.currentSchemaFieldId,
            );
            if (!schemaField) {
              return;
            }
            schemaField.fieldExtractors.push({
              mapping,
              type,
              uuid: v4(),
              value,
            });
            setState((s) => ({
              ...s,
              showAddFieldExtractorModal: false,
            }));
          }}
          onClose={() => {
            setState((s) => ({
              ...s,
              currentSchemaFieldId: null,
              showAddFieldExtractorModal: false,
            }));
          }}
        />
      )}
      {state.showEditFieldExtractorModal &&
        !isNil(state.currentSchemaFieldId) &&
        !isNil(state.currentFieldExtractorId) && (
          <EditFieldExtractorModal
            companyUuid={state.currentCompanyId}
            isOpen={state.showEditFieldExtractorModal}
            fieldExtractor={state.schemaFields
              .find((s) => s.uuid === state.currentSchemaFieldId)
              ?.fieldExtractors.find(
                (f) => f.uuid === state.currentFieldExtractorId,
              )}
            handleSave={({
              type,
              value,
              mapping,
            }: {
              type: ExtractionType;
              value: string;
              mapping: FieldMapping;
            }) => {
              const schemaFieldIndex = stateRef.current.schemaFields.findIndex(
                (s) => s.uuid === stateRef.current.currentSchemaFieldId,
              );
              if (schemaFieldIndex === -1) {
                return;
              }
              const schemaField =
                stateRef.current.schemaFields[schemaFieldIndex];
              const fieldExtractorIndex =
                schemaField?.fieldExtractors.findIndex(
                  (f) => f.uuid === stateRef.current.currentFieldExtractorId,
                );
              if (
                isNil(fieldExtractorIndex) ||
                isNil(schemaField) ||
                fieldExtractorIndex === -1
              ) {
                return;
              }
              const fieldExtractor =
                schemaField.fieldExtractors[fieldExtractorIndex];
              const fieldExtractors = [
                // @ts-ignore
                ...schemaField.fieldExtractors.slice(0, fieldExtractorIndex),
                {
                  ...fieldExtractor,
                  type,
                  value,
                  mapping,
                },
                // @ts-ignore
                ...schemaField.fieldExtractors.slice(fieldExtractorIndex + 1),
              ];
              // @ts-ignore
              setState((s) => ({
                ...s,
                showEditFieldExtractorModal: false,
                schemaFields: [
                  ...stateRef.current.schemaFields.slice(0, schemaFieldIndex),
                  {
                    ...schemaField,
                    fieldExtractors,
                  },
                  ...stateRef.current.schemaFields.slice(schemaFieldIndex + 1),
                ],
              }));
            }}
            onClose={() => {
              setState((s) => ({
                ...s,
                currentSchemaFieldId: null,
                showEditFieldExtractorModal: false,
              }));
            }}
          />
        )}
      {state.showAddFieldMatcherModal && (
        <AddFieldMatcherModal
          isOpen={state.showAddFieldMatcherModal}
          handleCreate={({
            type,
            value,
          }: {
            type: MatcherType;
            value: string;
          }) => {
            const schemaField = stateRef.current.schemaFields.find(
              (s) => s.uuid === stateRef.current.currentSchemaFieldId,
            );
            if (!schemaField) {
              return;
            }
            schemaField.fieldMatchers.push({
              type,
              uuid: v4(),
              value,
            });
            setState((s) => ({
              ...s,
              showAddFieldMatcherModal: false,
            }));
          }}
          onClose={() => {
            setState((s) => ({
              ...s,
              currentSchemaFieldId: null,
              showAddFieldMatcherModal: false,
            }));
          }}
        />
      )}
      {state.showEditFieldMatcherModal &&
        !isNil(state.currentSchemaFieldId) &&
        !isNil(state.currentFieldMatcherId) && (
          <EditFieldMatcherModal
            isOpen={state.showEditFieldMatcherModal}
            fieldMatcher={state.schemaFields
              .find((s) => s.uuid === state.currentSchemaFieldId)
              ?.fieldMatchers.find(
                (f) => f.uuid === state.currentFieldMatcherId,
              )}
            handleSave={({
              type,
              value,
            }: {
              type: MatcherType;
              value: string;
            }) => {
              const schemaFieldIndex = stateRef.current.schemaFields.findIndex(
                (s) => s.uuid === stateRef.current.currentSchemaFieldId,
              );
              if (schemaFieldIndex === -1) {
                return;
              }
              const schemaField =
                stateRef.current.schemaFields[schemaFieldIndex];
              const fieldMatcherIndex = schemaField?.fieldMatchers.findIndex(
                (f) => f.uuid === stateRef.current.currentFieldMatcherId,
              );
              if (
                isNil(fieldMatcherIndex) ||
                isNil(schemaField) ||
                fieldMatcherIndex === -1
              ) {
                return;
              }
              const fieldMatcher = schemaField.fieldMatchers[fieldMatcherIndex];
              const fieldMatchers = [
                ...schemaField.fieldMatchers.slice(0, fieldMatcherIndex),
                {
                  ...fieldMatcher,
                  type,
                  value,
                },
                ...schemaField.fieldMatchers.slice(fieldMatcherIndex + 1),
              ];
              // @ts-expect-error
              setState((s) => ({
                ...s,
                showEditFieldMatcherModal: false,
                schemaFields: [
                  ...stateRef.current.schemaFields.slice(0, schemaFieldIndex),
                  {
                    ...schemaField,
                    fieldMatchers,
                  },
                  ...stateRef.current.schemaFields.slice(schemaFieldIndex + 1),
                ],
              }));
            }}
            onClose={() => {
              setState((s) => ({
                ...s,
                currentSchemaFieldId: null,
                showEditFieldMatcherModal: false,
              }));
            }}
          />
        )}
      {state.showEditFingerprintAnchorModal &&
        !isNil(state.currentFingerprintAnchorId) && (
          <EditFingerprintAnchorModal
            isOpen={stateRef.current.showEditFingerprintAnchorModal}
            substring={
              stateRef.current.fingerprintBlocks.find(
                (f) => state.currentFingerprintAnchorId === f.blockId,
              )?.substring
            }
            text={
              stateRef.current.fingerprintBlocks.find(
                (f) => state.currentFingerprintAnchorId === f.blockId,
              )?.text ?? ""
            }
            handleSave={({ substring }: { substring: string }) => {
              const index = stateRef.current.fingerprintBlocks.findIndex(
                (f) =>
                  f.blockId === stateRef.current.currentFingerprintAnchorId,
              );
              if (index === -1) {
                console.error(
                  "Couldn't find anchor:",
                  stateRef.current.currentFingerprintAnchorId,
                );
                return;
              }
              const fingerprintAnchor =
                stateRef.current.fingerprintBlocks[index];
              // @ts-ignore
              setState((s) => ({
                ...s,
                currentFingerprintAnchorId: null,
                fingerprintBlocks: [
                  ...stateRef.current.fingerprintBlocks.slice(0, index),
                  {
                    ...fingerprintAnchor,
                    substring,
                  },
                  ...stateRef.current.fingerprintBlocks.slice(index + 1),
                ],
                showEditFingerprintAnchorModal: false,
              }));
            }}
            onClose={() => {
              setState((s) => ({
                ...s,
                currentFingerprintAnchorId: null,
                showEditFingerprintAnchorModal: false,
              }));
            }}
          />
        )}
      {state.showTestResultModal && (
        <Modal
          style={{
            content: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
          }}
          isOpen={state.showTestResultModal}
          onRequestClose={() => {
            setState((s) => {
              return {
                ...s,
                showTestResultModal: false,
              };
            });
          }}
        >
          <div style={{ height: "100%", width: "100%" }}>
            {JSON.stringify(testSchemaData?.testSchema, null, 2)}
          </div>
        </Modal>
      )}
      <EditPackageTableModal
        isOpen={state.showEditPackageTableModal}
        handleSave={(columns) => {
          const packageTable = stateRef.current.packageTables.find(
            (p) => p.uuid === stateRef.current.currentPackageTableId,
          );
          if (isNil(packageTable)) {
            return;
          }
          packageTable.packageTableColumns = [...columns];
          setState((s) => {
            return {
              ...s,
            };
          });
        }}
        packageTableColumns={
          stateRef.current.packageTables.find(
            (p) => p.uuid === stateRef.current.currentPackageTableId,
          )?.packageTableColumns ?? []
        }
        onClose={() => {
          setState((s) => {
            return {
              ...s,
              currentPackageTableId: null,
              showEditPackageTableModal: false,
            };
          });
        }}
      />
      <UploadFileModal
        isOpen={state.showUploadFileModal}
        handleUpload={handleUploadSchemaTestFile}
        onClose={() => {
          setState((s) => ({
            ...s,
            showUploadFileModal: false,
            uploadFileType: null,
          }));
        }}
      />
    </div>
  );
};

export default Docs;
