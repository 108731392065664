import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import { isEmpty, isNil } from "lodash";
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
} from "react";
import { shallow } from "zustand/shallow";
import { useSchemaCompanyMappingsQuery } from "../../../../generated/graphql";
import useSchemaStore from "../schema-store";
import { type Schema } from "../types";
import useScannedOrders from "../use-scanned-orders";
import AddSchemaCompanyMappingModal from "./add-schema-company-mapping-modal";
import SchemaCompanyMappingRow from "./schema-company-mapping-row";
import SchemaCompanyMappingViewer from "./schema-company-mapping-viewer";

const SchemaCompanyMappingsModal = ({
  open,
  setOpen,
  schema,
  selectedCompanyUuid,
}: {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly schema: Schema;
  readonly selectedCompanyUuid?: string;
}) => {
  const [
    selectedSchemaDocuments,
    deselectAllSchemaDocuments,
    matchSchemaDocument,
  ] = useSchemaStore(
    (state) => [
      state.selectedSchemaDocuments,
      state.deselectAllSchemaDocuments,
      state.matchSchemaDocument,
    ],
    shallow,
  );
  const [showAddSchemaMappingModal, setShowAddSchemaMappingModal] =
    useState(false);
  const [selectedSchemaCompanyMappingId, setSelectedSchemaCompanyMappingId] =
    useState<string>();
  const { data: schemaCompanyMappingsData } = useSchemaCompanyMappingsQuery({
    variables: {
      schemaUuid: schema.uuid,
    },
  });
  const {
    createScannedOrders,
    errorMessage,
    setErrorMessage,
    loadingCreateScannedOrders,
  } = useScannedOrders();

  const schemaCompanyMappings =
    schemaCompanyMappingsData?.schemaCompanyMappings;
  const selectedSchemaCompanyMapping = schemaCompanyMappings?.find(
    ({ id }) => id === selectedSchemaCompanyMappingId,
  );
  useEffect(() => {
    if (isNil(selectedSchemaCompanyMappingId)) {
      setSelectedSchemaCompanyMappingId(schemaCompanyMappings?.[0]?.id);
    }
  }, [schemaCompanyMappings, selectedSchemaCompanyMappingId]);

  useEffect(() => {
    if (!isNil(selectedCompanyUuid)) {
      setSelectedSchemaCompanyMappingId(
        schemaCompanyMappings?.find(
          (s) => s.companyUuid === selectedCompanyUuid,
        )?.id,
      );
    }
  }, [schemaCompanyMappings]);

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            minWidth: "90%", // Set your width here
          },
        },
        zIndex: 800,
      }}
      onClose={() => {
        setOpen(false);
        deselectAllSchemaDocuments();
      }}
    >
      {showAddSchemaMappingModal && (
        <AddSchemaCompanyMappingModal
          open={showAddSchemaMappingModal}
          setOpen={setShowAddSchemaMappingModal}
          schemaUuid={schema.uuid}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={5000}
        open={!isNil(errorMessage)}
        onClose={() => {
          setErrorMessage(undefined);
        }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Stack justifyContent="space-between" direction="row">
        <DialogTitle sx={{ fontWeight: "bold" }}>
          Company / contact mappings
        </DialogTitle>
        <Stack direction="row" alignItems="center" spacing={3}>
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              disabled={
                loadingCreateScannedOrders || isEmpty(selectedSchemaDocuments)
              }
              onClick={async () =>
                createScannedOrders({
                  schemaDocuments: selectedSchemaDocuments,
                })
              }
            >
              Scan{" "}
              {selectedSchemaDocuments.length > 0
                ? `(${selectedSchemaDocuments.length})`
                : ""}
            </Button>
            <Button
              variant="contained"
              disabled={isEmpty(selectedSchemaDocuments)}
              onClick={() => {
                for (const { schemaDocument } of selectedSchemaDocuments) {
                  matchSchemaDocument(schema, schemaDocument);
                }
              }}
            >
              Match{" "}
              {selectedSchemaDocuments.length > 0
                ? `(${selectedSchemaDocuments.length})`
                : ""}
            </Button>
          </Stack>
          <Stack spacing={0} sx={{ pt: 2, pr: 3 }} alignItems="flex-end">
            <DialogContentText>{schema.name}</DialogContentText>
            <Typography variant="caption" color="secondary">
              {schema.uuid}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Divider />
      <DialogContent sx={{ minHeight: 800, pt: 0, px: 0 }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Company</TableCell>
                  <TableCell>Contact</TableCell>
                  <TableCell>Terminal</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>
                    <Button
                      sx={{ float: "right" }}
                      onClick={() => {
                        setShowAddSchemaMappingModal(true);
                      }}
                    >
                      Add
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {schemaCompanyMappingsData?.schemaCompanyMappings.map(
                  (schemaCompanyMapping) => (
                    <SchemaCompanyMappingRow
                      key={schemaCompanyMapping.id}
                      schemaCompanyMapping={schemaCompanyMapping}
                      selectedSchemaCompanyMappingId={
                        selectedSchemaCompanyMapping?.id
                      }
                      setSelectedSchemaCompanyMappingId={
                        setSelectedSchemaCompanyMappingId
                      }
                    />
                  ),
                )}
              </TableBody>
            </Table>
          </Grid>
          <Divider flexItem orientation="vertical" sx={{ height: 800 }} />
          <Grid item xs={5.92}>
            {!isNil(selectedSchemaCompanyMapping) && (
              <SchemaCompanyMappingViewer
                schema={schema}
                schemaCompanyMapping={selectedSchemaCompanyMapping}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SchemaCompanyMappingsModal;
