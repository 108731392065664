import React, { type ChangeEvent } from "react";
import { AppMode } from "../../enums/app-mode";

const SelectToolMode = ({
  appMode,
  setAppMode,
}: {
  readonly appMode: AppMode;
  readonly setAppMode: (mode: AppMode) => void;
}) => {
  const onAppModeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setAppMode(event.target.value as AppMode);
  };

  return (
    <div style={{ padding: "1rem" }}>
      <div>
        <h3>Select tool mode</h3>
      </div>
      <select
        value={appMode}
        style={{ height: "30px", padding: "5px" }}
        onChange={onAppModeChange}
      >
        <option key={AppMode.CREATE} value={AppMode.CREATE}>
          Create schema
        </option>
        <option key={AppMode.EDIT} value={AppMode.EDIT}>
          Edit schema
        </option>
        <option key={AppMode.TEST} value={AppMode.TEST}>
          Test schema
        </option>
        <option key={AppMode.TESTS} value={AppMode.TESTS}>
          Tests
        </option>
      </select>
    </div>
  );
};

export default React.memo(SelectToolMode);
