import type Konva from "konva";
import { Fragment, useEffect, useRef } from "react";
import { Rect, Transformer } from "react-konva";

type RectangleProps = {
  readonly allowTransform: boolean;
  readonly isSelected: boolean;
  readonly onSelect: any;
  readonly onChange: ({
    x,
    y,
    width,
    height,
  }: {
    x: number;
    y: number;
    width?: number;
    height?: number;
  }) => void;
  readonly x: number;
  readonly y: number;
  readonly width: number;
  readonly height: number;
  readonly opacity?: number;
  readonly fill?: string;
  readonly show: boolean;
};
/**
 * A Rectangle with a Transformer attached, allowing for resize and draggable.
 * See link for explanation on details
 *
 * Taken from: https://konvajs.org/docs/react/Transformer.html
 */
const Rectangle = ({
  allowTransform,
  isSelected,
  onSelect,
  onChange,
  x,
  y,
  width,
  height,
  opacity,
  fill,
  show,
}: // onChange
RectangleProps) => {
  const shapeRef = useRef<Konva.Rect>(null);
  const trRef = useRef<Konva.Transformer>(null);

  useEffect(() => {
    if (
      isSelected && // we need to attach transformer manually
      shapeRef.current &&
      trRef.current
    ) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer()?.batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <Rect
        ref={shapeRef}
        x={x}
        y={y}
        width={width}
        height={height}
        opacity={opacity}
        fill={fill}
        draggable={show && allowTransform}
        onClick={onSelect}
        onTap={onSelect}
        onDragEnd={(e) => {
          if (!allowTransform) {
            return;
          }
          onChange({
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={() => {
          if (!allowTransform) {
            return;
          }
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          if (node) {
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();

            // we will reset it back
            node.scaleX(1);
            node.scaleY(1);
            onChange({
              x: node.x(),
              y: node.y(),
              // set minimal value
              width: Math.max(5, node.width() * scaleX),
              height: Math.max(node.height() * scaleY),
            });
          }
        }}
      />
      {allowTransform && isSelected && (
        <Transformer
          ref={trRef}
          keepRatio={false}
          rotateEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default Rectangle;
