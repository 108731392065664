import { isEmpty, isNil } from "lodash";

function camelCaseToSnakeCase(inputString: string) {
  return inputString.replaceAll(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();
}

function pascalCaseToDashCase(inputString: string) {
  return inputString.replaceAll(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
}

const getGraphqlType = (type: string) => {
  type = type.replace("?", "");
  if (
    type.includes("BigInt") ||
    type.includes("Decimal") ||
    type.includes("Int")
  ) {
    return "Number";
  }
  if (type.includes("DateTime")) {
    return "Date";
  }
  return type;
};

const getTypescriptType = (type: string, enumNames: string[]) => {
  type = type.replace("?", "");
  if (type.includes("DateTime")) {
    return "Date";
  }
  if (type.includes("Decimal")) {
    return "Decimal";
  }
  if (type.includes("Int")) {
    return "bigint";
  }
  return enumNames.some((name) => name.toLowerCase() === type.toLowerCase())
    ? type
    : type.toLowerCase();
};

const getIsNull = (type: string) => {
  return type.includes("?");
};

const isRelation = (parts: string[]) => {
  for (const part of parts) {
    if (part.includes("@relation")) {
      return true;
    }
  }
  return false;
};

const hasRelation = (model: string, relation: string) => {
  return model.includes(`@relation(fields: [${relation}Id]`);
};

const relationIsNull = (model: string, relation: string) => {
  return model.replaceAll(" ", "").includes(`${relation}IdBigInt?`);
};

const isId = (field: string) => {
  return field.includes("Id") || field === "id";
};

const shouldSkip = (parts: string[], skipIds: boolean) => {
  if (!isNil(parts[0]) && parts[0].startsWith("//")) return true;
  for (const part of parts) {
    if (
      part.includes("@relation") ||
      (part.includes("[]") && parts.length === 2) ||
      part.includes("@@") ||
      (skipIds && isId(part))
    ) {
      return true;
    }
  }
  return false;
};

const shouldAddDecorator = (field: string, isCreateDto: boolean) => {
  if (isCreateDto && field === "uuid") return false;
  return !isId(field) && field !== "createdAt" && field !== "updatedAt";
};

function extractSubstringBetween(
  inputString: string,
  startString: string,
  endString: string,
) {
  const pattern = new RegExp(`${startString}(.*?)${endString}`);
  const matches = inputString.match(pattern);

  if (matches) {
    return matches[1];
  }
  return null;
}

export const getModelNamePlural = (model: string) => {
  const modelName = extractSubstringBetween(model, "model ", " {");
  return `${modelName}s`;
};
export const getModelNamePluralSnakeCase = (model: string) => {
  const modelName = getModelNamePlural(model);
  return camelCaseToSnakeCase(modelName);
};

export const getModelNameSingular = (model: string) => {
  const modelName = extractSubstringBetween(model, "model ", " {");
  return modelName ?? "";
};

export const fillInFields = (
  code: string,
  modelData: string[],
  enumNames: string[],
  isCreateDto = false,
  isUpdateDto = false,
) => {
  for (const line of modelData) {
    const parts = line.split(" ").filter((part) => !isEmpty(part.trim()));
    const [field, type] = parts;
    if (
      isNil(field) ||
      isNil(type) ||
      shouldSkip(parts, isUpdateDto) ||
      (isRelation(parts) && !hasRelation(code, field))
    )
      continue;
    const isNull = isUpdateDto && field === "uuid" ? false : getIsNull(type);
    const graphqlType = getGraphqlType(type);
    const typescriptType = getTypescriptType(type, enumNames);

    if (shouldAddDecorator(field, isCreateDto)) {
      code += `\t@Field(() => ${graphqlType}${
        isNull ? `, { nullable: true }` : ""
      })\n`;
    }

    if (
      !isUpdateDto ||
      (isUpdateDto &&
        !isId(field) &&
        field !== "createdAt" &&
        field !== "updatedAt")
    ) {
      code += `\t${field}${
        isUpdateDto && field !== "uuid" ? "?" : ""
      }: ${typescriptType}${isNull ? " | null" : ""};\n\n`;
    }
  }

  if (isCreateDto || isUpdateDto) {
    for (const line of modelData) {
      const parts = line.split(" ").filter((part) => !isEmpty(part.trim()));
      const [field, type] = parts;
      if (isNil(type) || isNil(field)) continue;
      const isNull = getIsNull(type) || isUpdateDto;

      if (
        isRelation(parts) &&
        hasRelation(modelData.join(""), field) &&
        field !== "user" &&
        field !== "company"
      ) {
        code += `\t@Field(() => String${
          isNull ? `, { nullable: true }` : ""
        })\n`;
        code += `\t${field}Uuid${isUpdateDto ? "?" : ""}: string${
          isNull ? " | null" : ""
        };\n\n`;
      }
    }
  }

  return code;
};

export const getEntityCode = (model: string, enumNames: string[]) => {
  const modelName = getModelNameSingular(model);
  let modelData = model.split("\n");
  modelData = modelData.slice(1, -1);

  let code = `import { ObjectType, Field, registerEnumType } from '@nestjs/graphql';\n`;
  code += `import { ${enumNames.join(", ")}${
    enumNames.length > 0 ? "," : ""
  } ${modelName} } from '@prisma/client';\n\n`;

  for (const name of enumNames) {
    code += `registerEnumType(${name}, { name: '${name}' });\n`;
  }
  code += "\n";

  code += `@ObjectType()\nexport class ${modelName}Entity implements ${modelName} {\n`;

  if (!isNil(modelData)) {
    code = fillInFields(code, modelData, enumNames, false);
  }

  code += "}\n";

  return code;
};

export const getCreateDTOCode = (model: string, enumNames: string[]) => {
  const modelName = getModelNameSingular(model);
  let modelData = model.split("\n");
  modelData = modelData.slice(1, -1);

  let code = `import { InputType, Field } from '@nestjs/graphql';
import { ${enumNames.join(", ")}${
    enumNames.length > 0 ? "," : ""
  } ${modelName} } from '@prisma/client';
  
@InputType()\nexport class ${modelName}CreateInput implements ${modelName} {\n`;

  if (!isNil(modelData)) {
    code = fillInFields(code, modelData, enumNames, true);
  }

  code += "}\n";

  return code;
};

export const getUpdateDTOCode = (model: string, enumNames: string[]) => {
  const modelName = getModelNameSingular(model);
  let modelData = model.split("\n");
  modelData = modelData.slice(1, -1);

  let code = `import { InputType, Field } from '@nestjs/graphql';
import { ${enumNames.join(", ")}${
    enumNames.length > 0 ? "," : ""
  } ${modelName} } from '@prisma/client';
  
@InputType()\nexport class ${modelName}UpdateInput extends PartialType(${modelName}CreateInput) {\n`;

  if (!isNil(modelData)) {
    code = fillInFields(code, modelData, enumNames, false, true);
  }

  code += "}\n";

  return code;
};

export const getModuleCode = (model: string) => {
  const modelName = getModelNamePlural(model);
  const dashCaseModelName = pascalCaseToDashCase(modelName);

  const code = `
  import { Module } from '@nestjs/common';
  import { PrismaModule } from '../../prisma.module';
  import { ${modelName}Repository } from './${dashCaseModelName}.repository';
  import { ${modelName}Resolver } from './${dashCaseModelName}.resolver';
  import { ${modelName}Service } from './${dashCaseModelName}.service';

  @Module({
    imports: [PrismaModule],
    providers: [
      ${modelName}Resolver,
      ${modelName}Service,
      ${modelName}Repository,
    ],
  })
  export class ${modelName}Module {}`;
  return code;
};

export const getRepositoryCreateUpdateFieldsText = (
  model: string,
  isCreate: boolean,
) => {
  const modelName = getModelNameSingular(model);
  const camelCaseModelName = `${modelName[0]?.toLowerCase()}${modelName?.slice(
    1,
  )}`;
  let modelData = model.split("\n");
  modelData = modelData.slice(1, -1);
  let code = "";

  for (const line of modelData) {
    const parts = line.split(" ").filter((part) => !isEmpty(part.trim()));
    const [field, type] = parts;
    if (
      isNil(field) ||
      isNil(type) ||
      field === "company" ||
      field === "user" ||
      (shouldSkip(parts, true) && !isRelation(parts))
    )
      continue;

    const dtoString = `${camelCaseModelName}${
      isCreate ? "Create" : "Update"
    }Input`;

    if (isRelation(parts) && !hasRelation(model, field)) continue;

    if (isRelation(parts)) {
      code += relationIsNull(model, field)
        ? `        ${field}: !isNil(${dtoString}.${field}Uuid)
          ? {
              connect: {
                uuid: ${dtoString}.${field}Uuid,
              },
            }
          : undefined,\n`
        : `        ${field}: {
              connect: {
                uuid: ${dtoString}.${field}Uuid,
              },
            },
            `;
    } else if (shouldAddDecorator(field, true)) {
      code += `        ${field}: ${dtoString}.${field},\n`;
    }
  }

  return code.slice(0, -1);
};

export const getRepositoryCode = (model: string) => {
  const modelName = extractSubstringBetween(model, "model ", " {");
  if (isNil(modelName)) return "";
  const dashCaseModelName = pascalCaseToDashCase(modelName);
  const camelCaseModelName = `${modelName[0]?.toLowerCase()}${modelName?.slice(
    1,
  )}`;
  const hasCompanyRelation = hasRelation(model, "company");
  const hasUserRelation = hasRelation(model, "user");
  const hasContactRelation = hasRelation(model, "contact");
  const userRelationIsNull = relationIsNull(model, "user");

  let code = `import { Injectable } from '@nestjs/common';
import { ${modelName} } from '@prisma/client';
import { isNil } from 'lodash';
import { PrismaService } from '../../prisma.service';
import { ${modelName}CreateInput } from './dto/${dashCaseModelName}-create.input';
import { ${modelName}UpdateInput } from './dto/${dashCaseModelName}-update.input';

@Injectable()
export class ${modelName}sRepository {
  constructor(private readonly prismaService: PrismaService) {}

  async findByUuid({ uuid }: { uuid: string }) {
    return this.prismaService.${camelCaseModelName}.findUnique({
      where: { uuid },
    });
  }

  async findByUuidOrThrow({
    uuid,
  }: {
    uuid: string;
  }): Promise<${modelName}> {
    return this.prismaService.${camelCaseModelName}.findUniqueOrThrow({
      where: { uuid },
    });
  }\n`;

  if (hasCompanyRelation) {
    code += `
    
  async findByCompanyUuidOrThrow({
    companyUuid,
  }: {
    companyUuid: string;
  }): Promise<${modelName}[]> {
    return this.prismaService.company
      .findUniqueOrThrow({ where: { uuid: companyUuid } })
      .${camelCaseModelName}();
  }`;
  }

  if (hasUserRelation) {
    code += `
    
  async findByUserUuidOrThrow({
    userUuid,
  }: {
    userUuid: string;
  }): Promise<${modelName}[]> {
    return this.prismaService.user
      .findUniqueOrThrow({ where: { uuid: userUuid } })
      .${camelCaseModelName}();
  }`;
  }

  if (hasContactRelation) {
    code += `
    
  async findByContactUuidOrThrow({
    contactUuid,
  }: {
    contactUuid: string;
  }): Promise<${modelName}[]> {
    return this.prismaService.contact
      .findUniqueOrThrow({ where: { uuid: contactUuid } })
      .${camelCaseModelName}();
  }`;
  }

  code += `
  
  async create({
    ${hasCompanyRelation ? "companyUuid," : ""}
    ${hasUserRelation ? "userUuid," : ""}
    ${camelCaseModelName}CreateInput,
  }: {
    ${hasCompanyRelation ? "companyUuid: string;" : ""}
    ${
      hasUserRelation ? `userUuid${userRelationIsNull ? "?" : ""}: string;` : ""
    }
    ${camelCaseModelName}CreateInput: ${modelName}CreateInput;
  }): Promise<${modelName}> {
    return this.prismaService.${camelCaseModelName}.create({
      data: {
        ${
          hasCompanyRelation
            ? `company: {
          connect: {
            uuid: companyUuid,
          },
        },`
            : ""
        }
        ${
          hasUserRelation
            ? userRelationIsNull
              ? `user:
          !isNil(userUuid) ? {
                connect: {
                  uuid: userUuid,
                },
              }
            : undefined,`
              : `user: {
          connect: {
            uuid: userUuid,
          },
        },`
            : ""
        }
${getRepositoryCreateUpdateFieldsText(model, true)}
      },
    });
  }

  async update({
    ${camelCaseModelName}UpdateInput,
  }: {
    ${camelCaseModelName}UpdateInput: ${modelName}UpdateInput;
  }): Promise<${modelName}> {
    return this.prismaService.${camelCaseModelName}.update({
      where: { uuid: ${camelCaseModelName}UpdateInput.uuid },
      data: {
${getRepositoryCreateUpdateFieldsText(model, false)}
      },
    });
  }

  async deleteByUuid({ uuid }: { uuid: string }) {
    return this.prismaService.${camelCaseModelName}.delete({
      where: {
        uuid,
      },
    });
  }
}`;

  return code;
};

export const getServiceCode = (model: string) => {
  const modelNamePlural = getModelNamePlural(model);
  const modelNameSingular = getModelNameSingular(model);
  const dashCaseModelNameSingular = pascalCaseToDashCase(modelNameSingular);
  const dashCaseModelNamePlural = pascalCaseToDashCase(modelNamePlural);
  const camelCaseModelNameSingular = `${modelNameSingular[0]?.toLowerCase()}${modelNameSingular?.slice(
    1,
  )}`;
  const camelCaseModelNamePlural = `${modelNamePlural[0]?.toLowerCase()}${modelNamePlural?.slice(
    1,
  )}`;
  const hasCompanyRelation = hasRelation(model, "company");
  const hasUserRelation = hasRelation(model, "user");
  const hasContactRelation = hasRelation(model, "contact");
  const userRelationIsNull = relationIsNull(model, "user");

  const code = `import { Injectable, Logger } from '@nestjs/common';
import { PrismaService } from '../../prisma.service';
import { ${modelNamePlural}Repository } from './${dashCaseModelNamePlural}.repository';
import { ${modelNameSingular}CreateInput } from './dto/${dashCaseModelNameSingular}-create.input';
import { ${modelNameSingular}UpdateInput } from './dto/${dashCaseModelNameSingular}-update.input';

@Injectable()
export class ${modelNamePlural}Service {
  private logger = new Logger(${modelNamePlural}Service.name);

  constructor(
    private readonly prismaService: PrismaService,
    private readonly ${camelCaseModelNamePlural}Repository: ${modelNamePlural}Repository,
  ) {}

${
  hasCompanyRelation
    ? `  findByCompanyUuidOrThrow({ companyUuid }: { companyUuid: string }) {
    return this.${camelCaseModelNamePlural}Repository.findByCompanyUuidOrThrow({
      companyUuid,
    });
  }
  `
    : ""
}
 ${
   hasUserRelation
     ? ` findByUserUuidOrThrow({ userUuid }: { userUuid: string }) {
    return this.${camelCaseModelNamePlural}Repository.findByUserUuidOrThrow({
      userUuid,
    });
  }
  `
     : ""
 } 
 ${
   hasContactRelation
     ? `findByContactUuidOrThrow({ contactUuid }: { contactUuid: string }) {
    return this.${camelCaseModelNamePlural}Repository.findByContactUuidOrThrow({
      contactUuid,
    });
  }
  `
     : ""
 } 
  findByUuidOrThrow({ uuid }: { uuid: string }) {
    return this.${camelCaseModelNamePlural}Repository.findByUuidOrThrow({ uuid });
  }

  create({
    ${hasCompanyRelation ? "companyUuid," : ""}
    ${hasUserRelation ? "userUuid," : ""}
    ${camelCaseModelNameSingular}CreateInput,
  }: {
    ${hasCompanyRelation ? "companyUuid: string;" : ""}
    ${
      hasUserRelation ? `userUuid${userRelationIsNull ? "?" : ""}: string;` : ""
    }
    ${camelCaseModelNameSingular}CreateInput: ${modelNameSingular}CreateInput;
  }) {
    return this.${camelCaseModelNamePlural}Repository.create({
      ${hasCompanyRelation ? "companyUuid," : ""}
      ${hasUserRelation ? "userUuid," : ""}
      ${camelCaseModelNameSingular}CreateInput,
    });
  }

  update({
    ${camelCaseModelNameSingular}UpdateInput,
  }: {
    ${camelCaseModelNameSingular}UpdateInput: ${modelNameSingular}UpdateInput;
  }) {
    return this.${camelCaseModelNamePlural}Repository.update({
      ${camelCaseModelNameSingular}UpdateInput,
    });
  }

  async remove({ uuid }: { uuid: string }) {
    return this.${camelCaseModelNamePlural}Repository.deleteByUuid({ uuid });
  }
}

  
  `;

  return code;
};

export const getResolverCode = (model: string) => {
  const modelName = extractSubstringBetween(model, "model ", " {");
  if (isNil(modelName)) return "";
  const dashCaseModelName = pascalCaseToDashCase(modelName);
  const camelCaseModelName = `${modelName[0]?.toLowerCase()}${modelName?.slice(
    1,
  )}`;
  const hasCompanyRelation = hasRelation(model, "company");
  const hasUserRelation = hasRelation(model, "user");

  return `import { Resolver, Query, Mutation, Args } from '@nestjs/graphql';
import { CurrentUser } from '../../auth/current-user.decorator';
import { SessionUser } from '../../auth/session-user';
import { ${modelName}sService } from './${dashCaseModelName}s.service';
import { ${modelName}CreateInput } from './dto/${dashCaseModelName}-create.input';
import { ${modelName}UpdateInput } from './dto/${dashCaseModelName}-update.input';
import { ${modelName}Entity } from './entities/${dashCaseModelName}.entity';

@Resolver(() => ${modelName}Entity)
export class ${modelName}sResolver {
  constructor(
    private readonly ${camelCaseModelName}sService: ${modelName}sService,
  ) {}
  
  ${
    hasCompanyRelation
      ? `@Query(() => [${modelName}Entity], { name: '${camelCaseModelName}s' })
  findByCompanyUuidOrThrow(@CurrentUser() sessionUser: SessionUser) {
    return this.${camelCaseModelName}sService.findByCompanyUuidOrThrow({
      companyUuid: sessionUser.companyUuid,
    });
  }`
      : ""
  }
  
  ${
    hasUserRelation
      ? `@Query(() => [${modelName}Entity], { name: '${camelCaseModelName}sByUser' })
  findByUserUuidOrThrow(@CurrentUser() sessionUser: SessionUser) {
    return this.${camelCaseModelName}sService.findByUserUuidOrThrow({
      userUuid: sessionUser.userUuid,
    });
  }`
      : ""
  }
  
  @Mutation(() => ${modelName}Entity)
  create${modelName}(
    @CurrentUser() sessionUser: SessionUser,
    @Args('${camelCaseModelName}CreateInput')
    ${camelCaseModelName}CreateInput: ${modelName}CreateInput,
  ) {
    return this.${camelCaseModelName}sService.create({
      ${hasCompanyRelation ? `companyUuid: sessionUser.companyUuid,` : ""}
      ${hasUserRelation ? `userUuid: sessionUser.userUuid,` : ""}
      ${camelCaseModelName}CreateInput,
    });
  }

  @Mutation(() => ${modelName}Entity)
  update${modelName}(
    @Args('${camelCaseModelName}Input')
    ${camelCaseModelName}UpdateInput: ${modelName}UpdateInput,
  ) {
    return this.${camelCaseModelName}sService.update({
      ${camelCaseModelName}UpdateInput,
    });
  }

  @Mutation(() => ${modelName}Entity)
  remove${modelName}(@Args('uuid', { type: () => String }) uuid: string) {
    return this.${camelCaseModelName}sService.remove({ uuid });
  }
}
`;
};
