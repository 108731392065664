import { isNil } from "lodash";

export const filterNotNil = <T>(array: Array<T | undefined | null>): T[] => {
  return array.flatMap((v) => (isNil(v) ? [] : [v]));
};

/**
 * Break an array into {size} chunks
 * @param array
 * @param size
 */
export function chunkArray<T>(array: T[], size: number): T[][] {
  const result = [];
  for (let index = 0; index < array.length; index += size) {
    const chunk = array.slice(index, index + size);
    result.push(chunk);
  }
  return result;
}
