import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { sentenceCase } from "change-case";
import dayjs from "dayjs";
import { isEmpty, isNil, values } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import {
  RateType,
  TariffGroupType,
  TariffType,
  TariffZoneType,
  useFuelProfilesQuery,
  useImportCrownTariffMutation,
  useMeQuery,
  useServicesQuery,
  useShallowContactsQuery,
  useTariffZoneGroupsQuery,
  useVehicleTypesQuery,
} from "../../generated/graphql";
import { DragAndDropUpload } from "../common/drag-and-drop-upload";
import {
  type ImportTariffFormValuesV2,
  useImportTariffForm,
} from "./use-import-tariff-form";

const SDK_KEY = process.env.REACT_APP_STATSIG_SDK_KEY ?? "";
const ENVIRONMENT = process.env.REACT_APP_APP_ENV;

const unsupportedTariffZoneTypes = new Set([
  TariffZoneType.Zipcode,
  TariffZoneType.Universal,
]);
const unsupportedTariffGroupTypes = new Set([TariffGroupType.Transfer]);

const TariffImporter = () => {
  const [importCrownTariff, { loading: importCrownTariffLoading }] =
    useImportCrownTariffMutation();

  const { data: meData } = useMeQuery({
    fetchPolicy: "cache-first",
  });
  const { data: contactsData } = useShallowContactsQuery();
  const { data: tariffZoneGroupsData } = useTariffZoneGroupsQuery({
    variables: {
      findTariffZoneGroupsInput: {
        isArchived: false,
      },
    },
  });
  const { form } = useImportTariffForm();
  const {
    reset,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = form;

  const [successVisible, setSuccessVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const DEFAULT_MESSAGE = "Error importing tariff";
  const [errorMessage, setErrorMessage] = useState(DEFAULT_MESSAGE);

  const companyUuid = meData?.me?.company?.uuid ?? "";
  const tariffChainsEnabled: boolean = watch("tariffChainsEnabled");

  useEffect(() => {
    reset({
      contactUuids: [],
      serviceUuids: [],
      vehicleTypeUuids: [],
      autoSelectAllServices: false,
      autoSelectAllVehicleTypes: false,
      name: "",
      tariffZoneType: TariffZoneType.Location,
      tariffType: TariffType.PerHundredPounds,
      rateType: RateType.Flat,
      tariffUrl: "",
      tariffGroupType: TariffGroupType.Ordinary,
      useActualWeight: true,
      startDate: dayjs(new Date("2022-01-01")),
      endDate: dayjs(new Date("2030-01-01")),
      fuelProfileUuid: null,
      tariffZoneGroupId: null,
    });
  }, [reset]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // This is jank af but I'm not standing up statsig properly
        // to check for one feature flag. :elkay-lzs8wslk-wall-mount-drinking-fountain-with-bottle-fillar-station-light-gray-granite:
        setLoading(true);
        const response = await fetch("https://api.statsig.com/v1/check_gate", {
          method: "POST",
          headers: {
            "statsig-api-key": SDK_KEY,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user: {
              userID: "",
              custom: { companyUuid },
              statsigEnvironment: { tier: ENVIRONMENT },
            },
            gateNames: ["tariff_chains_enabled"],
          }),
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setValue(
          "tariffChainsEnabled",
          data?.tariff_chains_enabled?.value ?? false,
        );
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [companyUuid]);

  const contactUuids = watch("contactUuids");
  const autoSelectAllServices = watch("autoSelectAllServices");
  const autoSelectAllVehicleTypes = watch("autoSelectAllVehicleTypes");
  const tariffType = watch("tariffType");
  const tariffGroupType = watch("tariffGroupType");
  const tariffZoneType = watch("tariffZoneType");

  const tariffCanHaveZoneGroup = useMemo(() => {
    // Zone groups are only applicable for ordinary, zone-based tariffs
    // (They're also applicable for zone-based point-to-point tariffs, but
    // these cannot currently be created in the UI to begin with, so that's
    // not yet a concern).
    if (
      tariffGroupType !== TariffGroupType.Ordinary &&
      tariffGroupType !== TariffGroupType.PointToPoint
    ) {
      return false;
    }
    // Zone groups are only applicable for zone-based tariffs
    if (tariffZoneType !== TariffZoneType.Location) {
      return false;
    }
    return true;
  }, [tariffGroupType, tariffZoneType]);

  useEffect(() => {
    if (!tariffCanHaveZoneGroup) {
      setValue("tariffZoneGroupId", null);
    }
  }, [tariffCanHaveZoneGroup]);

  const { data: fuelProfilesData } = useFuelProfilesQuery();

  const { data: globalServicesData } = useServicesQuery({
    fetchPolicy: "cache-first",
  });
  const currentServices = useMemo(() => {
    return (globalServicesData?.services ?? [])
      .filter((service) => {
        return service.isActive;
      })
      .map((service) => {
        return {
          name: service.name,
          uuid: service.uuid,
        };
      });
  }, [globalServicesData?.services]);

  const { data: globalVehicleTypesData } = useVehicleTypesQuery({
    fetchPolicy: "cache-first",
  });
  const currentVehicleTypes = useMemo(() => {
    return (globalVehicleTypesData?.vehicleTypes ?? []).map((vehicleType) => {
      return {
        name: vehicleType.name,
        uuid: vehicleType.uuid,
      };
    });
  }, [globalVehicleTypesData?.vehicleTypes]);

  useEffect(() => {
    setValue("serviceUuids", []);
    if (isEmpty(contactUuids)) {
      setValue("autoSelectAllServices", false);
    }
  }, [contactUuids]);

  useEffect(() => {
    if (autoSelectAllServices as boolean) {
      setValue("serviceUuids", []);
    }
  }, [autoSelectAllServices]);
  useEffect(() => {
    if (autoSelectAllVehicleTypes as boolean) {
      setValue("vehicleTypeUuids", []);
    }
  }, [autoSelectAllVehicleTypes]);

  useEffect(() => {
    if (isNil(tariffType) || tariffType === TariffType.NoUnits) {
      setValue("rateType", RateType.Flat);
    }
  }, [tariffType]);

  const onSubmit = async (data: ImportTariffFormValuesV2) => {
    const {
      contactUuids: contactUuidsFromForm,
      serviceUuids,
      vehicleTypeUuids,
      name,
      tariffZoneType: tariffZoneTypeFromForm,
      rateType,
      tariffGroupType: tariffGroupTypeFromForm,
      tariffUrl,
      useActualWeight,
      startDate,
      endDate,
      fuelProfileUuid,
      tariffZoneGroupId,
      tariffType: tariffTypeFromForm,
    } = data;
    try {
      const response = await importCrownTariff({
        variables: {
          importCrownTariffInput: {
            contactUuids: contactUuidsFromForm,
            serviceUuids: serviceUuids ?? [],
            vehicleTypeUuids: vehicleTypeUuids ?? [],
            name,
            tariffZoneType: tariffZoneTypeFromForm,
            tariffType: tariffTypeFromForm,
            rateType,
            tariffGroupType: tariffGroupTypeFromForm,
            tariffZoneGroupId,
            tariffUrl,
            useActualWeight,
            autoSelectAllServices: data.autoSelectAllServices,
            autoSelectAllVehicleTypes: data.autoSelectAllVehicleTypes,
            startDate,
            endDate,
            fuelProfileUuid,
          },
        },
      });
      const error = response.data?.importCrownTariff?.error;
      if (isNil(error)) {
        setErrorMessage(DEFAULT_MESSAGE);
        setSuccessVisible(true);
      } else {
        setErrorMessage(error);
        setErrorVisible(true);
      }
    } catch (error) {
      setErrorVisible(true);
      setErrorMessage(`Error: ${error}`);
    }
  };

  const contactOptions = useMemo(
    () =>
      contactsData?.contacts?.map((contact) => {
        return {
          value: contact.uuid,
          label: contact.displayName,
        };
      }) ?? [],
    [contactsData?.contacts],
  );

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Grid container spacing={1} sx={{ padding: 4 }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={successVisible}
      >
        <Alert
          severity="success"
          onClose={() => {
            setSuccessVisible(false);
          }}
        >
          Successfully imported tariff
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={errorVisible}
      >
        <Alert
          severity="error"
          onClose={() => {
            setErrorVisible(false);
          }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
        <Typography variant="h4">Crown Tariff Importer</Typography>
        <Typography sx={{ size: 6, fontStyle: "italic" }}>
          For those with brains 🧠
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="tariffUrl"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <DragAndDropUpload
                fileUrl={value}
                setPresignedUrl={(url: string) => {
                  onChange(url);
                }}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">
          Company: {meData?.me?.company?.name}
        </Typography>
        <Typography variant="subtitle1">
          PLEASE MAKE SURE YOU LOGGED IN WITH THE COMPANY ACCOUNT THAT YOU WANT
          TO CREATE THE TARIFF FOR
        </Typography>
        {!tariffChainsEnabled && (
          <Controller
            name="contactUuids"
            control={control}
            render={({ field: { onChange }, fieldState: { error } }) => {
              return (
                <>
                  <InputLabel id="age">Customers</InputLabel>
                  <ReactSelect
                    isClearable
                    isMulti
                    menuPosition="fixed"
                    options={contactOptions}
                    placeholder="Select contact"
                    closeMenuOnSelect={false}
                    onChange={(option) => {
                      if (!isNil(option)) {
                        onChange(option.map((o) => o.value));
                      }
                    }}
                  />
                  {!isNil(error) && (
                    <FormHelperText sx={{ color: "#D32F2F" }}>
                      {error.message}
                    </FormHelperText>
                  )}
                </>
              );
            }}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="serviceUuids"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <InputLabel id="age">
                Services
                {isEmpty(contactUuids) &&
                  ` (Tariff will apply to these services${
                    tariffChainsEnabled ? "" : " for all customers"
                  })`}
              </InputLabel>
              <ReactSelect
                isClearable
                isMulti
                menuPosition="fixed"
                options={currentServices.map((service) => {
                  return {
                    value: service.uuid,
                    label: service.name,
                  };
                })}
                value={
                  autoSelectAllServices === true
                    ? null
                    : currentServices
                        .filter((service) => value?.includes(service.uuid))
                        .map((service) => ({
                          value: service.uuid,
                          label: service.name,
                        }))
                }
                placeholder="Select services"
                closeMenuOnSelect={false}
                isDisabled={autoSelectAllServices}
                onChange={(option) => {
                  if (!isNil(option)) {
                    onChange(option.map((o) => o.value));
                  }
                }}
              />
              {!isNil(errors.serviceUuids) && (
                <FormHelperText sx={{ color: "#D32F2F" }}>
                  {errors.serviceUuids.message}
                </FormHelperText>
              )}
            </>
          )}
        />
        <Controller
          control={control}
          name="autoSelectAllServices"
          defaultValue={false}
          render={({ field }) => (
            <FormControl fullWidth sx={{ paddingLeft: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox checked={field.value} onChange={field.onChange} />
                }
                label="All services"
                disabled={isEmpty(contactUuids) && !tariffChainsEnabled}
              />
            </FormControl>
          )}
        />
      </Grid>
      {tariffChainsEnabled && (
        <Grid item xs={12}>
          <Controller
            name="vehicleTypeUuids"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <InputLabel>
                  Vehicle types (Tariff will apply to these vehicle types)
                </InputLabel>
                <ReactSelect
                  isClearable
                  isMulti
                  menuPosition="fixed"
                  options={currentVehicleTypes.map((vehicleType) => {
                    return {
                      value: vehicleType.uuid,
                      label: vehicleType.name,
                    };
                  })}
                  value={
                    autoSelectAllVehicleTypes === true
                      ? null
                      : currentVehicleTypes
                          .filter((vehicleType) =>
                            value?.includes(vehicleType.uuid),
                          )
                          .map((vehicleType) => ({
                            value: vehicleType.uuid,
                            label: vehicleType.name,
                          }))
                  }
                  placeholder="Select vehicle types"
                  closeMenuOnSelect={false}
                  isDisabled={autoSelectAllVehicleTypes}
                  onChange={(option) => {
                    if (!isNil(option)) {
                      onChange(option.map((o) => o.value));
                    }
                  }}
                />
                {!isNil(errors.vehicleTypeUuids) && (
                  <FormHelperText sx={{ color: "#D32F2F" }}>
                    {errors.vehicleTypeUuids.message}
                  </FormHelperText>
                )}
              </>
            )}
          />
          <Controller
            control={control}
            name="autoSelectAllVehicleTypes"
            defaultValue={false}
            render={({ field }) => (
              <FormControl fullWidth sx={{ paddingLeft: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox checked={field.value} onChange={field.onChange} />
                  }
                  label="All vehicle types"
                />
              </FormControl>
            )}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <InputLabel>Tariff Name</InputLabel>
              <TextField
                fullWidth
                size="small"
                error={!isNil(errors.name)}
                value={value}
                onChange={onChange}
              />
              {!isNil(errors.name) && (
                <FormHelperText sx={{ color: "#D32F2F" }}>
                  {errors.name.message}
                </FormHelperText>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item xs={2}>
        <Controller
          name="startDate"
          control={control}
          render={({ field: { onChange, value } }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <InputLabel>Start date</InputLabel>
              <DatePicker
                value={value}
                onChange={(newDate) => {
                  onChange(newDate);
                }}
              />
            </LocalizationProvider>
          )}
        />
      </Grid>
      <Grid item xs={2}>
        <Controller
          name="endDate"
          control={control}
          render={({ field: { onChange, value } }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <InputLabel>End date</InputLabel>
              <DatePicker
                value={value}
                onChange={(newDate) => {
                  onChange(newDate);
                }}
              />
            </LocalizationProvider>
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="fuelProfileUuid"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <InputLabel id="age">Fuel profile</InputLabel>
              <Select
                size="small"
                value={value}
                sx={{ width: "100%" }}
                error={!isNil(errors.fuelProfileUuid)}
                onChange={onChange}
              >
                {(fuelProfilesData?.fuelProfiles ?? []).map((fuelProfile) => {
                  return (
                    <MenuItem key={fuelProfile.uuid} value={fuelProfile.uuid}>
                      {fuelProfile.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {!isNil(errors.fuelProfileUuid) && (
                <FormHelperText sx={{ color: "#D32F2F" }}>
                  {errors.fuelProfileUuid.message}
                </FormHelperText>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item xs={2}>
        <Controller
          name="tariffZoneType"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <InputLabel id="age">Tariff zone type</InputLabel>
              <Select
                size="small"
                value={value}
                sx={{ width: "100%" }}
                error={!isNil(errors.tariffZoneType)}
                onChange={onChange}
              >
                {values(TariffZoneType).map((type) => {
                  return (
                    <MenuItem
                      key={type}
                      value={type}
                      disabled={unsupportedTariffZoneTypes.has(type)}
                    >
                      {sentenceCase(type)}
                      {unsupportedTariffZoneTypes.has(type) && " (unsupported)"}
                    </MenuItem>
                  );
                })}
              </Select>
              {!isNil(errors.tariffZoneType) && (
                <FormHelperText sx={{ color: "#D32F2F" }}>
                  {errors.tariffZoneType.message}
                </FormHelperText>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item xs={2}>
        <Controller
          name="tariffType"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <InputLabel id="age">Unit type</InputLabel>
              <Select
                size="small"
                value={value}
                sx={{ width: "100%" }}
                error={!isNil(errors.tariffType)}
                onChange={onChange}
              >
                {values(TariffType).map((type) => {
                  return (
                    <MenuItem key={type} value={type}>
                      {sentenceCase(type)}
                    </MenuItem>
                  );
                })}
              </Select>
              {!isNil(errors.tariffType) && (
                <FormHelperText sx={{ color: "#D32F2F" }}>
                  {errors.tariffType.message}
                </FormHelperText>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item xs={2}>
        <Controller
          name="rateType"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <InputLabel id="age">Rate type</InputLabel>
              <Select
                size="small"
                value={value}
                sx={{ width: "100%" }}
                error={!isNil(errors.tariffType)}
                onChange={onChange}
              >
                {values(RateType)
                  .filter(
                    (rt) =>
                      (!isNil(tariffType) &&
                        tariffType !== TariffType.NoUnits) ||
                      rt === "FLAT",
                  )
                  .map((rateType) => {
                    return (
                      <MenuItem key={rateType} value={rateType}>
                        {rateType === RateType.QuantityBased
                          ? "Multiplier"
                          : sentenceCase(rateType)}
                      </MenuItem>
                    );
                  })}
              </Select>
              {!isNil(errors.tariffType) && (
                <FormHelperText sx={{ color: "#D32F2F" }}>
                  {errors.tariffType.message}
                </FormHelperText>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item xs={2}>
        <Controller
          name="tariffGroupType"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <InputLabel id="age">Tariff group type</InputLabel>
              <Select
                size="small"
                value={value}
                sx={{ width: "100%" }}
                error={!isNil(errors.tariffGroupType)}
                onChange={onChange}
              >
                {values(TariffGroupType).map((type) => {
                  return (
                    <MenuItem
                      key={type}
                      value={type}
                      disabled={unsupportedTariffGroupTypes.has(type)}
                    >
                      {sentenceCase(type)}
                      {unsupportedTariffGroupTypes.has(type) &&
                        " (unsupported)"}
                    </MenuItem>
                  );
                })}
              </Select>
              {!isNil(errors.tariffGroupType) && (
                <FormHelperText sx={{ color: "#D32F2F" }}>
                  {errors.tariffGroupType.message}
                </FormHelperText>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          control={control}
          name="useActualWeight"
          defaultValue={false}
          render={({ field }) => (
            <FormControl fullWidth sx={{ paddingLeft: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox checked={field.value} onChange={field.onChange} />
                }
                label="Use Actual Weight"
              />
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={3}>
        <Controller
          name="tariffZoneGroupId"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <InputLabel id="age">Zone Group</InputLabel>
              <ReactSelect
                isClearable
                menuPosition="fixed"
                value={
                  isNil(value)
                    ? null
                    : {
                        label: tariffZoneGroupsData?.tariffZoneGroups?.find(
                          (option) => option.id === value,
                        )?.name,
                        value,
                      }
                }
                options={
                  tariffZoneGroupsData?.tariffZoneGroups?.map((option) => ({
                    label: option.name,
                    value: option.id,
                  })) ?? []
                }
                placeholder="Select zone group"
                isDisabled={!tariffCanHaveZoneGroup}
                onChange={(option) => {
                  onChange(option?.value ?? null);
                }}
              />
              {!isNil(errors.tariffZoneGroupId) && (
                <FormHelperText sx={{ color: "#D32F2F" }}>
                  {errors.tariffZoneGroupId.message}
                </FormHelperText>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
        <Button
          disabled={importCrownTariffLoading}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          Create Tariff
        </Button>
      </Grid>
    </Grid>
  );
};

export default TariffImporter;
