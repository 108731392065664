import { Alert, Box, Button, Chip, Stack } from "@mui/material";
import { constantCase } from "change-case";
import { isNil } from "lodash";
import type React from "react";
import { useMemo, useState } from "react";
import { Collapse } from "react-collapse";
import Select from "react-select";
import { AddBlockMode } from "../../../enums/add-block-mode";
import { AddEdgeAnchorMode } from "../../../enums/add-edge-anchor-mode";
import { AppMode } from "../../../enums/app-mode";
import { Side } from "../../../enums/side";
import { UploadFileType } from "../../../enums/upload-file-type";
import {
  Edge,
  type EdgeAnchorEntity,
  ExtractionType,
  type RotateDocumentInput,
  ScannedDocumentType,
  type SchemaRunTestSnapshotsQuery,
  type SchemasQuery,
  type TestSchemaInput,
  type NormalizeDocumentInput,
  TextractType,
} from "../../../generated/graphql";
import { type FingerprintAnchor } from "../../../types/fingerprint-anchor";
import { type PackageTable } from "../../../types/package-table";
import { type SchemaField } from "../../../types/schema-field";
import { type TransformAnchor } from "../../../types/transform-anchor";
import { enumKeys } from "../../../utils/enum";
import ContactSelect from "../../common/contact-select";
import CompanySelect, { GLOBAL_SCHEMA_NO_COMPANY } from "../company-select";
import SchemaCompanyMappingsModal from "../schema-testing/schema-company-mappings/schema-company-mappings-modal";
import SelectToolMode from "../select-tool-mode";

const styles = {
  sideBarBox: {
    border: "1px solid black",
    borderRadius: "2px",
    padding: "5px",
    marginTop: "5px",
    marginBottom: "5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  } as React.CSSProperties,
};

type Props = {
  readonly addBlockMode: AddBlockMode;
  readonly addEdgeAnchorMode: AddEdgeAnchorMode | null;
  readonly allFileNames: Array<string | undefined>;
  readonly appMode: AppMode;
  readonly createSchema: () => void;
  readonly createSchemaLoading: boolean;
  readonly currentCompanyId: string | null;
  readonly currentContactId: string | null;
  readonly currentFileName: string | null;
  readonly currentSchemaRunTestSnapshot: string | null;
  readonly currentTestFilename: string | null;
  readonly currentTestFilePage: string | null | undefined;
  readonly currentTestFilePages: Array<string | undefined>;
  readonly deleteSchema: () => void;
  readonly deleteSchemaLoading: boolean;
  readonly deleteSchemaRun: () => void;
  readonly deleteSchemaRunLoading: boolean;
  readonly duplicateSchemaField: (id: string) => void;
  readonly exportSchema: () => void;
  readonly fields: SchemaField[];
  readonly fingerprintAnchors: FingerprintAnchor[];
  readonly importSchema: () => void;
  readonly importTestSnapshot: () => void;
  readonly importTestSnapshotLoading: boolean;
  readonly normalizeDocument: (
    normalizeDocumentInput: NormalizeDocumentInput,
  ) => Promise<void>;
  readonly normalizeDocumentLoading: boolean;
  readonly packageTables: PackageTable[];
  readonly reanalyzePage: () => Promise<void>;
  readonly reanalyzePageLoading: boolean;
  readonly reanalyzeSchema: () => Promise<void>;
  readonly reanalyzeSchemaLoading: boolean;
  readonly removeField: (id: string) => void;
  readonly removeEdgeAnchor: ({
    schemaFieldId,
    side,
  }: {
    schemaFieldId: string;
    side: Side;
  }) => void;
  readonly removeFieldExtractor: ({
    schemaFieldId,
    fieldExtractorId,
  }: {
    schemaFieldId: string;
    fieldExtractorId: string;
  }) => void;
  readonly removeFieldMatcher: ({
    schemaFieldId,
    fieldMatcherId,
  }: {
    schemaFieldId: string;
    fieldMatcherId: string;
  }) => void;
  readonly removeFingerprintAnchor: (id: string) => void;
  readonly removePackageTable: (id: string) => void;
  readonly removeTransformAnchor: (id: string) => void;
  readonly rotateDocument: (
    rotateDocumentInput: RotateDocumentInput,
  ) => Promise<void>;
  readonly scaleXY: boolean;
  readonly schemaIsMultiPage: boolean;
  readonly schemaName: string;
  readonly schemaScannedDocumentType: ScannedDocumentType;
  readonly schemaTextractType: TextractType;
  readonly schemaRunTestSnapshots: SchemaRunTestSnapshotsQuery["schemaRunTestSnapshots"];
  readonly schemas: SchemasQuery["schemas"];
  readonly selectedRectangleId: string | null;
  readonly selectedSchemaUuid: string | null;
  readonly setAddBlockMode: (mode: AddBlockMode) => void;
  readonly setAddEdgeAnchorMode: (
    mode: AddEdgeAnchorMode,
    fieldId: string,
  ) => void;
  readonly setAppMode: (mode: AppMode) => void;
  readonly setCurrentCompany: (id: string | null) => void;
  readonly setCurrentContactId: (id: string | null) => void;
  readonly setCurrentFileName: (fileName: string) => void;
  readonly setCurrentSchemaRunTestSnapshot: (id: string) => void;
  readonly setCurrentTestFilename: (filename: string) => void;
  readonly setCurrentTestFilePage: (filename: string) => void;
  readonly setEdgeAnchorEdge: ({
    side,
    edge,
    schemaFieldId,
  }: {
    side: Side;
    edge: Edge;
    schemaFieldId: string;
  }) => void;
  readonly setFingerprintAnchorRequired: (id: string, value: boolean) => void;
  readonly setScaleXY: (value: boolean) => void;
  readonly setSchemaFieldShow: (uuid: string, value: boolean) => void;
  readonly setSchemaIsMultiPage: (isMultiPage: boolean) => void;
  readonly setSchemaName: (name: string) => void;
  readonly setSchemaScannedDocumentType: (
    scannedDocumentType: ScannedDocumentType,
  ) => void;
  readonly setSchemaTextractType: (textractType: TextractType) => void;
  readonly setSelectedRectangleId: (id: string) => void;
  readonly setSelectedSchema: (uuid: string) => void;
  readonly setShowAddFieldExtractorModal: (show: boolean, id: string) => void;
  readonly setShowAddFieldMatcherModal: (show: boolean, id: string) => void;
  readonly setShowEditFieldExtractorModal: ({
    schemaFieldId,
    fieldExtractorId,
  }: {
    schemaFieldId: string;
    fieldExtractorId: string;
  }) => void;
  readonly setShowEditFieldMatcherModal: ({
    schemaFieldId,
    fieldMatcherId,
  }: {
    schemaFieldId: string;
    fieldMatcherId: string;
  }) => void;
  readonly setShowEditFingerprintAnchorModal: (id: string) => void;
  readonly setShowEditPackageTableModal: (id: string) => void;
  readonly setShowFields: (show: boolean) => void;
  readonly setShowFingerprintAnchors: (show: boolean) => void;
  readonly setShowPackageTables: (show: boolean) => void;
  readonly setShowSelectedFileLines: (show: boolean) => void;
  readonly setShowTransformAnchors: (show: boolean) => void;
  readonly setShowUploadFileModal: (show: boolean) => void;
  readonly setUploadFileType: (type: UploadFileType) => void;
  readonly showFields: boolean;
  readonly showFingerprintAnchors: boolean;
  readonly showPackageTables: boolean;
  readonly showSelectedFileLines: boolean;
  readonly showTransformAnchors: boolean;
  readonly testFilenames: Array<string | undefined>;
  readonly testOneSnapshot: () => void;
  readonly testOneSnapshotLoading: boolean;
  readonly testAllSnapshots: () => void;
  readonly testAllSnapshotsLoading: boolean;
  readonly testSchema: (testSchemaInput: TestSchemaInput) => Promise<void>;
  readonly testSchemaLoading: boolean;
  readonly transformAnchors: TransformAnchor[];
  readonly updateSchema: () => void;
  readonly updateSchemaLoading: boolean;
};

const Sidebar = ({
  addBlockMode,
  addEdgeAnchorMode,
  appMode,
  allFileNames,
  currentCompanyId,
  currentContactId,
  currentFileName,
  currentSchemaRunTestSnapshot,
  currentTestFilename,
  currentTestFilePage,
  currentTestFilePages,
  createSchema,
  createSchemaLoading,
  deleteSchema,
  deleteSchemaLoading,
  deleteSchemaRun,
  deleteSchemaRunLoading,
  duplicateSchemaField,
  exportSchema,
  fields,
  fingerprintAnchors,
  importSchema,
  importTestSnapshot,
  importTestSnapshotLoading,
  normalizeDocument,
  normalizeDocumentLoading,
  packageTables,
  reanalyzePage,
  reanalyzePageLoading,
  reanalyzeSchema,
  reanalyzeSchemaLoading,
  removeField,
  removeEdgeAnchor,
  removeFieldExtractor,
  removeFieldMatcher,
  removeFingerprintAnchor,
  removePackageTable,
  removeTransformAnchor,
  rotateDocument,
  scaleXY,
  schemaIsMultiPage,
  schemaName,
  schemaScannedDocumentType,
  schemaTextractType,
  schemaRunTestSnapshots,
  schemas,
  selectedRectangleId,
  selectedSchemaUuid,
  setAddBlockMode,
  setAddEdgeAnchorMode,
  setAppMode,
  setCurrentCompany,
  setCurrentContactId,
  setCurrentFileName,
  setCurrentSchemaRunTestSnapshot,
  setCurrentTestFilename,
  setCurrentTestFilePage,
  setEdgeAnchorEdge,
  setFingerprintAnchorRequired,
  setScaleXY,
  setSchemaFieldShow,
  setSchemaIsMultiPage,
  setSchemaName,
  setSchemaScannedDocumentType,
  setSchemaTextractType,
  setSelectedRectangleId,
  setSelectedSchema,
  setShowAddFieldExtractorModal,
  setShowAddFieldMatcherModal,
  setShowEditFieldExtractorModal,
  setShowEditFieldMatcherModal,
  setShowEditFingerprintAnchorModal,
  setShowEditPackageTableModal,
  setShowFields,
  setShowFingerprintAnchors,
  setShowPackageTables,
  setShowTransformAnchors,
  setShowSelectedFileLines,
  setShowUploadFileModal,
  setUploadFileType,
  showFields,
  showFingerprintAnchors,
  showPackageTables,
  showSelectedFileLines,
  showTransformAnchors,
  testAllSnapshots,
  testAllSnapshotsLoading,
  testFilenames,
  testOneSnapshot,
  testOneSnapshotLoading,
  testSchema,
  testSchemaLoading,
  transformAnchors,
  updateSchema,
  updateSchemaLoading,
}: Props) => {
  const allFileNameSelectOptions = useMemo(
    () =>
      allFileNames
        .filter((filename) => filename?.endsWith(".pdf") !== true)
        .map((fileName) => {
          return {
            value: fileName,
            label: fileName,
          };
        }),
    [allFileNames],
  );
  const testFileSelectOptions = useMemo(
    () =>
      testFilenames.map((fileName) => {
        return {
          value: fileName,
          label: isNil(fileName)
            ? ""
            : fileName.split("/")[fileName.split("/").length - 1],
        };
      }),
    [testFilenames],
  );
  const testFilePageSelectOptions = useMemo(
    () =>
      currentTestFilePages.map((fileName) => {
        return {
          value: fileName,
          label: isNil(fileName)
            ? ""
            : fileName.split("/")[fileName.split("/").length - 1],
        };
      }),
    [currentTestFilePages],
  );

  const getLabelHTML = (schemaOption: { value: string; label: string }) => {
    const schema = schemas.find((s) => s.uuid === schemaOption.value);
    if (!schema || schema.isActive) {
      return schemaOption.label;
    }
    return (
      <>
        <Chip label="Inactive" size="small" color="warning" />
        {"  "}
        {schema.name}
      </>
    );
  };

  const schemaOptions = schemas.map((schema) => {
    return {
      value: schema.uuid,
      label: schema.name,
    };
  });

  const schemaRunTestSnapshotOptions = schemaRunTestSnapshots
    .map((schemaRun) => {
      const split = schemaRun.key.split("/");
      const filename = split.at(-1);
      return {
        value: schemaRun.uuid,
        label: `${schemaRun.schema.name} - ${filename}`,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const currentFilenameOption = useMemo(
    () =>
      allFileNameSelectOptions.find(
        (option) => option.value === currentFileName,
      ),
    [allFileNameSelectOptions, currentFileName],
  );
  const currentTestFilenameOption = useMemo(
    () =>
      testFileSelectOptions.find(
        (option) => option.value === currentTestFilename,
      ),
    [testFileSelectOptions, currentTestFilename],
  );
  const currentTestFilePageOption = useMemo(
    () =>
      testFilePageSelectOptions.find(
        (option) => option.value === currentTestFilePage,
      ),
    [testFilePageSelectOptions, currentTestFilePage],
  );
  const currentSchemaRunTestSnapshotOption = useMemo(
    () =>
      schemaRunTestSnapshotOptions?.find(
        (option) => option.value === currentSchemaRunTestSnapshot,
      ),
    [schemaRunTestSnapshotOptions, currentSchemaRunTestSnapshot],
  );
  const scannedDocumentTypeOptions = enumKeys(ScannedDocumentType).map(
    (scannedDocumentType) => {
      return {
        value: ScannedDocumentType[scannedDocumentType],
        label: scannedDocumentType,
      };
    },
  );
  const textractTypeOptions = enumKeys(TextractType).map((textractType) => {
    return {
      value: TextractType[textractType],
      label: textractType,
    };
  });
  const currentSchemaScannedDocumentTypeOption = useMemo(
    () =>
      scannedDocumentTypeOptions?.find(
        (option) => option.value === schemaScannedDocumentType,
      ),
    [scannedDocumentTypeOptions, schemaScannedDocumentType],
  );

  const currentSchemaTextractTypeOption = useMemo(
    () =>
      textractTypeOptions?.find(
        (option) => option.value === schemaTextractType,
      ),
    [textractTypeOptions, schemaTextractType],
  );

  const edgeAnchorDiv = ({
    edgeAnchor,
    side,
    schemaFieldUuid,
  }: {
    side: Side;
    edgeAnchor: EdgeAnchorEntity;
    schemaFieldUuid: string;
  }) => {
    const edgeOptions: Edge[] = [];
    switch (side) {
      case "TOP":
      case "BOTTOM": {
        edgeOptions.push(Edge.Top, Edge.Bottom);
        break;
      }
      case "LEFT":
      case "RIGHT": {
        edgeOptions.push(Edge.Left, Edge.Right);
        break;
      }
      default: {
        break;
      }
    }
    return (
      <div key={edgeAnchor.uuid} style={styles.sideBarBox}>
        <div>
          <div>
            Side: <b>{constantCase(side)}</b>
          </div>
          <div>
            Text: <b>{edgeAnchor.text}</b>
          </div>
          <div>
            Edge:
            <select
              value={edgeAnchor.edge}
              style={{ marginLeft: "5px" }}
              onChange={(o) => {
                setEdgeAnchorEdge({
                  side,
                  edge: o.target.value as Edge,
                  schemaFieldId: schemaFieldUuid,
                });
              }}
            >
              {edgeOptions.map((edgeOption) => {
                return (
                  <option key={edgeOption} value={edgeOption}>
                    {edgeOption}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        {appMode === AppMode.EDIT && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <button
              onClick={() => {
                removeEdgeAnchor({
                  side,
                  schemaFieldId: schemaFieldUuid,
                });
              }}
            >
              Delete
            </button>
          </div>
        )}
      </div>
    );
  };

  const togglesSection = (
    <div style={{ padding: "1rem" }}>
      <div>
        <input
          type="checkbox"
          checked={scaleXY}
          onChange={(e) => setScaleXY(e.target.checked)}
        />
        Scale X/Y
      </div>
      <div>
        <input
          type="checkbox"
          checked={showSelectedFileLines}
          onChange={(e) => setShowSelectedFileLines(e.target.checked)}
        />
        File lines
      </div>
      <div>
        <input
          type="checkbox"
          checked={showFingerprintAnchors}
          onChange={(e) => setShowFingerprintAnchors(e.target.checked)}
        />
        Fingerprint anchors ({fingerprintAnchors.length})
      </div>
      <Collapse isOpened={showFingerprintAnchors}>
        <div>
          {fingerprintAnchors.map((fingerprintAnchor) => {
            const hasSubstring =
              !isNil(fingerprintAnchor.substring) &&
              fingerprintAnchor.substring.length > 0;
            return (
              <div
                key={fingerprintAnchor.blockId}
                style={{
                  border: "1px solid black",
                  borderRadius: "2px",
                  backgroundColor:
                    fingerprintAnchor.blockId === selectedRectangleId
                      ? "yellow"
                      : "white",
                  width: "100%",
                  padding: "2px",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
                onClick={() => {
                  if (!isNil(fingerprintAnchor.blockId)) {
                    setSelectedRectangleId(fingerprintAnchor.blockId);
                  }
                }}
              >
                <div style={{ marginBottom: "5px" }}>
                  {fingerprintAnchor.text}
                </div>
                {hasSubstring && (
                  <div style={{ marginBottom: "5px" }}>
                    <b>substring:</b> &amp;quot;
                    {fingerprintAnchor.substring}&amp;quot;
                  </div>
                )}
                {appMode === AppMode.EDIT && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "4px",
                      width: "100%",
                    }}
                  >
                    <button
                      onClick={() => {
                        setShowEditFingerprintAnchorModal(
                          fingerprintAnchor.blockId!,
                        );
                      }}
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => {
                        if (
                          globalThis.confirm(
                            "This will delete an anchor, are you sure?",
                          ) &&
                          !isNil(fingerprintAnchor.blockId)
                        ) {
                          removeFingerprintAnchor(fingerprintAnchor.blockId);
                        }
                      }}
                    >
                      Delete
                    </button>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={fingerprintAnchor.required}
                        onChange={(e) => {
                          if (!isNil(fingerprintAnchor.blockId)) {
                            setFingerprintAnchorRequired(
                              fingerprintAnchor.blockId,
                              e.target.checked,
                            );
                          }
                        }}
                      />
                      <span>Required</span>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </Collapse>
      <div>
        <input
          type="checkbox"
          checked={showTransformAnchors}
          onChange={(e) => setShowTransformAnchors(e.target.checked)}
        />
        Transform anchors ({transformAnchors.length})
      </div>
      <Collapse isOpened={showTransformAnchors}>
        <div>
          {transformAnchors.map((transformAnchor) => {
            return (
              <div
                key={transformAnchor.blockId}
                style={{
                  border: "1px solid black",
                  borderRadius: "2px",
                  backgroundColor:
                    transformAnchor.blockId === selectedRectangleId
                      ? "yellow"
                      : "white",
                  padding: "2px",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
                onClick={() =>
                  !isNil(transformAnchor.blockId) &&
                  setSelectedRectangleId(transformAnchor.blockId)
                }
              >
                <div style={{ marginBottom: "5px" }}>
                  {transformAnchor.text}
                </div>
                {appMode === AppMode.EDIT && (
                  <button
                    onClick={() => {
                      if (
                        globalThis.confirm(
                          "This will delete an anchor, are you sure?",
                        ) &&
                        !isNil(transformAnchor.blockId)
                      ) {
                        removeTransformAnchor(transformAnchor.blockId);
                      }
                    }}
                  >
                    Delete
                  </button>
                )}
              </div>
            );
          })}
        </div>
      </Collapse>
      <div>
        <input
          type="checkbox"
          checked={showFields}
          onChange={(e) => setShowFields(e.target.checked)}
        />
        Fields ({fields.length})
      </div>
      <Collapse isOpened={showFields}>
        <div>
          {fields.map((field) => {
            const hasEdgeAnchor =
              !isNil(field.leftEdgeAnchor) ||
              !isNil(field.rightEdgeAnchor) ||
              !isNil(field.topEdgeAnchor) ||
              !isNil(field.bottomEdgeAnchor);
            return (
              <div
                key={field.uuid}
                style={{
                  border: "1px solid black",
                  borderRadius: "2px",
                  backgroundColor:
                    field.uuid === selectedRectangleId ? "yellow" : "white",
                  padding: "2px",
                  marginTop: "5px",
                  marginBottom: "10px",
                }}
                onClick={(e) => {
                  const prototypeName = Object.getPrototypeOf(e.target)
                    .constructor.name;
                  // Don't select the element if clicking its buttons or checkboxes
                  if (prototypeName === "HTMLDivElement") {
                    setSelectedRectangleId(field.uuid);
                  }
                }}
              >
                {hasEdgeAnchor && <span>Edge anchors</span>}
                {!isNil(field.topEdgeAnchor) &&
                  edgeAnchorDiv({
                    edgeAnchor: field.topEdgeAnchor,
                    side: Side.TOP,
                    schemaFieldUuid: field.uuid,
                  })}
                {!isNil(field.bottomEdgeAnchor) &&
                  edgeAnchorDiv({
                    edgeAnchor: field.bottomEdgeAnchor,
                    side: Side.BOTTOM,
                    schemaFieldUuid: field.uuid,
                  })}
                {!isNil(field.leftEdgeAnchor) &&
                  edgeAnchorDiv({
                    edgeAnchor: field.leftEdgeAnchor,
                    side: Side.LEFT,
                    schemaFieldUuid: field.uuid,
                  })}
                {!isNil(field.rightEdgeAnchor) &&
                  edgeAnchorDiv({
                    edgeAnchor: field.rightEdgeAnchor,
                    side: Side.RIGHT,
                    schemaFieldUuid: field.uuid,
                  })}
                {field.fieldExtractors.length > 0 && <span>Extractors</span>}
                {field.fieldExtractors.map((fieldExtractor) => {
                  return (
                    <div key={fieldExtractor.uuid} style={styles.sideBarBox}>
                      <div>
                        <div>
                          <b>{fieldExtractor.mapping}</b>
                        </div>
                        <div>
                          Type: <b>{fieldExtractor.type}</b>
                        </div>
                        {fieldExtractor.type !== ExtractionType.Noop &&
                          fieldExtractor.type !== ExtractionType.SelectAll && (
                            <div>{`Value: "${fieldExtractor.value}"`}</div>
                          )}
                      </div>
                      {appMode === AppMode.EDIT && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <button
                            style={{
                              marginBottom: "5px",
                            }}
                            onClick={() =>
                              setShowEditFieldExtractorModal({
                                fieldExtractorId: fieldExtractor.uuid,
                                schemaFieldId: field.uuid,
                              })
                            }
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => {
                              removeFieldExtractor({
                                fieldExtractorId: fieldExtractor.uuid,
                                schemaFieldId: field.uuid,
                              });
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}
                {field.fieldMatchers.length > 0 && <span>Matchers</span>}
                {field.fieldMatchers.map((fieldMatcher) => {
                  return (
                    <div
                      key={fieldMatcher.uuid}
                      style={{
                        backgroundColor:
                          field.uuid === selectedRectangleId
                            ? "yellow"
                            : "white",
                        ...styles.sideBarBox,
                      }}
                    >
                      <div>
                        <div>
                          Type: <b>{fieldMatcher.type}</b>
                        </div>
                        <div>{`Value: "${fieldMatcher.value}"`}</div>
                      </div>
                      {appMode === AppMode.EDIT && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <button
                            style={{
                              marginBottom: "5px",
                            }}
                            onClick={() =>
                              setShowEditFieldMatcherModal({
                                fieldMatcherId: fieldMatcher.uuid,
                                schemaFieldId: field.uuid,
                              })
                            }
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => {
                              removeFieldMatcher({
                                fieldMatcherId: fieldMatcher.uuid,
                                schemaFieldId: field.uuid,
                              });
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}
                {appMode === AppMode.EDIT && (
                  <div>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "5px",
                      }}
                    >
                      <button
                        disabled={!isNil(addEdgeAnchorMode)}
                        style={{ marginRight: "5px" }}
                        onClick={() =>
                          setAddEdgeAnchorMode(
                            AddEdgeAnchorMode.TOP,
                            field.uuid,
                          )
                        }
                      >
                        Add top edge
                      </button>
                      <button
                        disabled={!isNil(addEdgeAnchorMode)}
                        style={{ marginRight: "5px" }}
                        onClick={() =>
                          setAddEdgeAnchorMode(
                            AddEdgeAnchorMode.BOTTOM,
                            field.uuid,
                          )
                        }
                      >
                        Add bottom edge
                      </button>
                      {/* <button */}
                      {/*  disabled={!isNil(addEdgeAnchorMode)} */}
                      {/*  style={{ marginRight: "5px" }} */}
                      {/*  onClick={() => */}
                      {/*    setAddEdgeAnchorMode( */}
                      {/*      AddEdgeAnchorMode.LEFT, */}
                      {/*      field.uuid */}
                      {/*    ) */}
                      {/*  } */}
                      {/* > */}
                      {/*  Add left edge */}
                      {/* </button> */}
                      {/* <button */}
                      {/*  disabled={!isNil(addEdgeAnchorMode)} */}
                      {/*  style={{ marginRight: "5px" }} */}
                      {/*  onClick={() => */}
                      {/*    setAddEdgeAnchorMode( */}
                      {/*      AddEdgeAnchorMode.RIGHT, */}
                      {/*      field.uuid */}
                      {/*    ) */}
                      {/*  } */}
                      {/* > */}
                      {/*  Add right edge */}
                      {/* </button> */}
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <button
                        style={{ marginRight: "5px" }}
                        onClick={() =>
                          setShowAddFieldExtractorModal(true, field.uuid)
                        }
                      >
                        Add extractor
                      </button>
                      <button
                        style={{ marginRight: "5px" }}
                        onClick={() =>
                          setShowAddFieldMatcherModal(true, field.uuid)
                        }
                      >
                        Add matcher
                      </button>
                      <button
                        style={{ marginRight: "5px" }}
                        onClick={() => {
                          if (
                            globalThis.confirm(
                              "This will delete a field, are you sure?",
                            ) &&
                            !isNil(field.uuid)
                          ) {
                            removeField(field.uuid);
                          }
                        }}
                      >
                        Delete
                      </button>
                      <button
                        style={{ marginRight: "5px" }}
                        onClick={() => {
                          if (!isNil(field)) {
                            duplicateSchemaField(field.uuid);
                          }
                        }}
                      >
                        Duplicate
                      </button>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <span>Show</span>
                        <input
                          type="checkbox"
                          checked={field.show}
                          onChange={(e) =>
                            setSchemaFieldShow(field.uuid, e.target.checked)
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </Collapse>
      <div>
        <input
          type="checkbox"
          checked={showPackageTables}
          onChange={(e) => setShowPackageTables(e.target.checked)}
        />
        Package tables ({packageTables.length})
      </div>
      <Collapse isOpened={showPackageTables}>
        {packageTables.map((packageTable) => {
          return (
            <div
              key={packageTable.uuid}
              style={styles.sideBarBox}
              onClick={() => setSelectedRectangleId(packageTable.blockId)}
            >
              <div>
                <div>
                  <b>Title:</b> {packageTable.title}
                </div>
                <div>
                  <b>Headers:</b>{" "}
                  {packageTable.packageTableColumns
                    .map((c) => c.header)
                    .join(", ")}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                  }}
                >
                  <button
                    onClick={() => {
                      setShowEditPackageTableModal(packageTable.uuid);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => {
                      removePackageTable(packageTable.uuid);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </Collapse>
    </div>
  );

  const companySelect = (
    <Box sx={{ px: 2, py: 1 }}>
      <CompanySelect
        allowNoCompany
        currentCompanyUuid={currentCompanyId}
        onChange={(option) => {
          if (!isNil(option)) {
            setCurrentCompany(
              option.value === GLOBAL_SCHEMA_NO_COMPANY ? null : option.value,
            );
            setCurrentContactId(null);
          }
        }}
      />
    </Box>
  );
  const fileSelect = (
    <Box sx={{ px: 2, py: 1 }}>
      <span>Select file</span>
      <Select
        options={allFileNameSelectOptions}
        placeholder="Select a file"
        value={currentFilenameOption}
        onChange={(option) => {
          if (!isNil(option) && !isNil(option.value)) {
            setCurrentFileName(option.value);
          }
        }}
      />
    </Box>
  );
  const testFileSelect = (
    <Box sx={{ px: 2 }}>
      <span>Select test file</span>
      <Select
        options={testFileSelectOptions}
        placeholder="Select test file"
        value={currentTestFilenameOption}
        onChange={(option) => {
          if (!isNil(option) && !isNil(option.value)) {
            setCurrentTestFilename(option.value);
          }
        }}
      />
    </Box>
  );
  const testFilePageSelect = (
    <Box sx={{ px: 2 }}>
      <span>Select page</span>
      <Select
        options={testFilePageSelectOptions}
        placeholder="Select page"
        value={currentTestFilePageOption}
        onChange={(option) => {
          if (!isNil(option) && !isNil(option.value)) {
            setCurrentTestFilePage(option.value);
          }
        }}
      />
    </Box>
  );
  const contactSelect = (
    <Box sx={{ px: 2 }}>
      <ContactSelect
        companyUuid={currentCompanyId}
        currentContactUuid={currentContactId}
        setContactUuid={setCurrentContactId}
      />
    </Box>
  );
  const selectedSchema = schemas.find((s) => s.uuid === selectedSchemaUuid);

  const schemaSelect = (
    <Box sx={{ px: 2, py: 1 }}>
      <span>Select schema</span>
      <Select
        value={
          isNil(selectedSchema)
            ? undefined
            : {
                value: selectedSchema.uuid,
                label: selectedSchema.name,
              }
        }
        options={schemaOptions}
        formatOptionLabel={getLabelHTML}
        placeholder="Select schema"
        onChange={(option) => {
          if (!isNil(option) && !isNil(option.value)) {
            setSelectedSchema(option.value);
          }
        }}
      />
    </Box>
  );

  const schemaNameInput = (
    <Box sx={{ px: 2, py: 1 }}>
      <div>Schema name</div>
      <input
        style={{ height: "20px", width: "200px", padding: "5px" }}
        type="text"
        value={schemaName}
        onChange={(e) => {
          setSchemaName(e.target.value);
        }}
      />
    </Box>
  );

  const textractTypeSelect = (
    <Box sx={{ px: 2, py: 1 }}>
      <span>Select Textract analysis type</span>
      <Select
        options={textractTypeOptions}
        placeholder="Analysis type"
        value={currentSchemaTextractTypeOption}
        onChange={(option) => {
          if (option?.value != null) {
            setSchemaTextractType(option.value);
          }
        }}
      />
    </Box>
  );

  const scannedDocumentTypeSelect = (
    <Box sx={{ px: 2, py: 1 }}>
      <span>Select document type</span>
      <Select
        options={scannedDocumentTypeOptions}
        placeholder="Analysis type"
        value={currentSchemaScannedDocumentTypeOption}
        onChange={(option) => {
          if (option?.value != null) {
            setSchemaScannedDocumentType(option.value);
          }
        }}
      />
    </Box>
  );

  const schemaRunTestSnapshotsSelect = (
    <div style={{ padding: "1rem" }}>
      <span>Select test</span>
      <Select
        options={schemaRunTestSnapshotOptions}
        placeholder="Select test"
        value={currentSchemaRunTestSnapshotOption}
        onChange={(option) => {
          if (!isNil(option) && !isNil(option.value)) {
            setCurrentSchemaRunTestSnapshot(option.value);
          }
        }}
      />
    </div>
  );

  const createModeButtons = (
    <>
      {companySelect}
      {fileSelect}
      {contactSelect}
      {schemaNameInput}
      {textractTypeSelect}
      {scannedDocumentTypeSelect}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem",
          }}
        >
          <button
            style={{ marginBottom: "5px" }}
            onClick={() => {
              setShowUploadFileModal(true);
              setUploadFileType(UploadFileType.SCHEMA);
            }}
          >
            Upload schema document
          </button>
          <button
            disabled={!isNil(currentFileName)}
            style={{ marginBottom: "5px" }}
            onClick={async () => {
              if (!isNil(currentFileName)) {
                const degrees = prompt("Number of degrees to rotate:");
                if (!isNil(degrees)) {
                  await rotateDocument({
                    documentName: currentFileName,
                    degrees: Number.parseFloat(degrees),
                    textractType: schemaTextractType,
                  });
                }
              }
            }}
          >
            Manually rotate document
          </button>
          <button style={{ marginBottom: "5px" }} onClick={importSchema}>
            Import schema
          </button>
          <button disabled={createSchemaLoading} onClick={createSchema}>
            Create schema
          </button>
        </div>
      </div>
    </>
  );

  const [schemaCompanyMappingsModalOpen, setSchemaCompanyMappingsModalOpen] =
    useState<boolean>(false);

  const schemaInactiveWarning = selectedSchema?.isActive === false && (
    <Alert severity="warning">
      This schema is inactive. It can be edited and tested but will not be
      considered for matching.
    </Alert>
  );

  const editModeButtons = (
    <>
      {!isNil(selectedSchema) && schemaCompanyMappingsModalOpen && (
        <SchemaCompanyMappingsModal
          open={schemaCompanyMappingsModalOpen}
          setOpen={setSchemaCompanyMappingsModalOpen}
          schema={selectedSchema}
        />
      )}
      {schemaSelect}
      {schemaInactiveWarning}

      <Stack direction="row" alignItems="center">
        {schemaNameInput}
        {!isNil(selectedSchema) && (
          <Button
            onClick={() => {
              setSchemaCompanyMappingsModalOpen(true);
            }}
          >
            View mappings
          </Button>
        )}
      </Stack>
      {textractTypeSelect}
      {scannedDocumentTypeSelect}
      {companySelect}
      {fileSelect}
      {contactSelect}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            checked={schemaIsMultiPage}
            onChange={(e) => setSchemaIsMultiPage(e.target.checked)}
          />
          <span>Is multiple pages?</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem",
          }}
        >
          <button
            disabled={updateSchemaLoading}
            style={{ marginBottom: "5px" }}
            onClick={updateSchema}
          >
            Update schema
          </button>
          <button
            disabled={reanalyzeSchemaLoading}
            style={{ marginBottom: "5px" }}
            onClick={reanalyzeSchema}
          >
            Reanalyze schema
          </button>
          <button style={{ marginBottom: "5px" }} onClick={importSchema}>
            Import schema
          </button>
          <button style={{ marginBottom: "5px" }} onClick={exportSchema}>
            Export schema
          </button>
          <button
            disabled={
              (isNil(selectedSchemaUuid) && isNil(currentFileName)) ||
              deleteSchemaLoading
            }
            onClick={async () => {
              if (
                !isNil(selectedSchemaUuid) &&
                globalThis.confirm(
                  "This will permanently delete the schema, are you sure?",
                )
              ) {
                deleteSchema();
                alert("Schema deleted!");
              }
            }}
          >
            Delete schema
          </button>
        </div>
        <div>
          <h2>Actions</h2>
        </div>
        <label>
          <input
            type="radio"
            value={AddBlockMode.ADD_FINGERPRINT_BLOCK}
            checked={addBlockMode === AddBlockMode.ADD_FINGERPRINT_BLOCK}
            onChange={(e) => {
              setAddBlockMode(e.target.value as AddBlockMode);
            }}
          />
          Add fingerprint anchor
        </label>
        <label>
          <input
            type="radio"
            value={AddBlockMode.ADD_TRANSFORM_BLOCK}
            checked={addBlockMode === AddBlockMode.ADD_TRANSFORM_BLOCK}
            onChange={(e) => {
              setAddBlockMode(e.target.value as AddBlockMode);
            }}
          />
          Add transform anchor
        </label>
        <label>
          <input
            type="radio"
            value={AddBlockMode.DRAW_FIELD_BOXES}
            checked={addBlockMode === AddBlockMode.DRAW_FIELD_BOXES}
            onChange={(e) => {
              setAddBlockMode(e.target.value as AddBlockMode);
            }}
          />
          Draw field boxes
        </label>
        <label>
          <input
            type="radio"
            value={AddBlockMode.ADD_PACKAGE_TABLE}
            checked={addBlockMode === AddBlockMode.ADD_PACKAGE_TABLE}
            onChange={(e) => {
              setAddBlockMode(e.target.value as AddBlockMode);
            }}
          />
          Add package table
        </label>
      </div>
      {togglesSection}
    </>
  );

  const testModeButtons = (
    <>
      {schemaSelect}
      {schemaInactiveWarning}
      {testFileSelect}
      {testFilePageSelect}
      <div
        style={{
          gap: "5px",
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
        }}
      >
        <button
          disabled={isNil(selectedSchemaUuid)}
          onClick={() => {
            setShowUploadFileModal(true);
            setUploadFileType(UploadFileType.TEST);
          }}
        >
          Upload documents for testing
        </button>
        <button
          disabled={reanalyzePageLoading}
          style={{ marginBottom: "5px" }}
          onClick={reanalyzePage}
        >
          Reanalyze document
        </button>
        <button
          disabled={
            !isNil(selectedSchemaUuid) ||
            !isNil(currentTestFilePage) ||
            normalizeDocumentLoading
          }
          onClick={async () => {
            if (!isNil(currentTestFilePage) && !isNil(selectedSchemaUuid)) {
              await normalizeDocument({
                documentName: currentTestFilePage,
                schemaUuid: selectedSchemaUuid,
              });
            }
          }}
        >
          Normalize (rotate) page
        </button>
        <button
          disabled={
            !isNil(selectedSchemaUuid) ||
            !isNil(currentTestFilePage) ||
            testSchemaLoading
          }
          onClick={async () => {
            if (!isNil(currentTestFilePage) && !isNil(selectedSchemaUuid)) {
              await testSchema({
                documentName: currentTestFilePage,
                schemaUuid: selectedSchemaUuid,
              });
            }
          }}
        >
          Test schema on page
        </button>
      </div>
      {togglesSection}
    </>
  );

  const testsModeButtons = (
    <>
      {schemaRunTestSnapshotsSelect}
      {testFilePageSelect}
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <button
          disabled={!isNil(currentSchemaRunTestSnapshot)}
          onClick={() => {
            schemaRunTestSnapshots.find(
              (s) => s.uuid === currentSchemaRunTestSnapshot,
            );
            alert("Check console!");
          }}
        >
          Export test
        </button>
        <button
          disabled={importTestSnapshotLoading}
          onClick={importTestSnapshot}
        >
          Import test
        </button>
        <button
          disabled={
            deleteSchemaRunLoading || !isNil(currentSchemaRunTestSnapshot)
          }
          onClick={() => {
            const confirm = globalThis.confirm(
              "This will permanently delete this test. Are you sure?",
            );
            if (confirm) {
              deleteSchemaRun();
            }
          }}
        >
          Delete selected test
        </button>
        <button disabled={testOneSnapshotLoading} onClick={testOneSnapshot}>
          Run selected test
        </button>
        <button disabled={testAllSnapshotsLoading} onClick={testAllSnapshots}>
          Run all tests
        </button>
      </div>
    </>
  );

  return (
    <div
      className="sidebar"
      style={{ width: "25vw", height: "calc(100vh - 50px)" }}
    >
      <div className="description" style={{ padding: "1rem", paddingTop: 0 }}>
        <h2 style={{ marginBottom: "1rem" }}>
          Memoize a document for scanning
        </h2>
        <small>
          This tool lets you create memoized document schemas and test them
          against documents
        </small>
      </div>
      <SelectToolMode appMode={appMode} setAppMode={setAppMode} />
      {appMode === AppMode.CREATE && createModeButtons}
      {appMode === AppMode.EDIT && editModeButtons}
      {appMode === AppMode.TEST && testModeButtons}
      {appMode === AppMode.TESTS && testsModeButtons}
    </div>
  );
};

export default Sidebar;
