import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { isNil } from "lodash";
import { RefreshIcon } from "primereact/icons/refresh";
import React, { useEffect, useState } from "react";
import { defaultStyles, JsonView } from "react-json-view-lite";
import { useStructuredExtractionRunByScannedOrderUuidLazyQuery } from "../../../../generated/graphql";
import StructuredExtractionSchemaEditor from "../structured-extraction-schema-overrides/structured-extraction-schema-editor";
import { type Schema } from "../types";
import OrderScanFormattedPayloadResult from "./order-scan-formatted-payload-result";

const ScannedOrderResultDialog = ({
  schema,
  jsonResult,
  resultDocUrls,
  onClose,
  onRetrigger,
}: {
  readonly schema: Schema | undefined | null;
  readonly jsonResult: any;
  readonly resultDocUrls: string[] | undefined;
  readonly onClose: () => void;
  readonly onRetrigger: () => void;
}) => {
  const [viewJson, setViewJson] = useState<boolean>(false);
  const [getStructuredExtractionRun, { data: structuredExtractionRunData }] =
    useStructuredExtractionRunByScannedOrderUuidLazyQuery();

  useEffect(() => {
    const scannedOrderUuid = jsonResult?.scannedOrder?.uuid;
    if (!isNil(scannedOrderUuid)) {
      getStructuredExtractionRun({
        variables: {
          uuid: scannedOrderUuid,
        },
      });
    }
  }, [jsonResult?.scannedOrder]);

  const order = jsonResult?.order;
  let structuredExtractionRunResult: any = "";
  try {
    structuredExtractionRunResult = JSON.parse(
      structuredExtractionRunData?.structuredExtractionRunByScannedOrder
        ?.rawResult ?? "{}",
    );
  } catch (error) {
    console.log(error);
  }

  const height = "calc(95vh - 85px)";

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      sx={{
        zIndex: 1600,
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "3000px", // Set your width here
            height: "95vh",
          },
        },
      }}
      open={!isNil(jsonResult) || !isNil(resultDocUrls)}
      onClose={onClose}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center">
            <Typography sx={{ fontWeight: "bold" }}>
              {schema?.name ?? "Schema"}
            </Typography>
          </Stack>
          <Stack alignItems="center">
            {!isNil(schema) &&
              structuredExtractionRunData?.structuredExtractionRunByScannedOrder
                ?.schemaUuid !== schema?.uuid && (
                <Typography color="error">Incorrect schema match</Typography>
              )}
            <Typography color="error" variant="caption">
              Matched to{" "}
              {
                structuredExtractionRunData
                  ?.structuredExtractionRunByScannedOrder?.schemaName
              }
            </Typography>
          </Stack>
          <IconButton
            onClick={() => {
              onRetrigger();
              onClose();
            }}
          >
            <RefreshIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack direction="row">
        {resultDocUrls?.map((url) => (
          <Box key={url} sx={{ minWidth: 700 }}>
            <object key={url} width="100%" height="100%" data={url}>
              {" "}
            </object>
          </Box>
        ))}
        {!isNil(jsonResult) && (
          <Stack direction="row" spacing={1}>
            <Stack
              sx={{
                overflowY: "scroll",
                height,
                minWidth: 450,
              }}
            >
              <Button
                sx={{ fontWeight: "bold" }}
                onClick={() => {
                  setViewJson((previous) => !previous);
                }}
              >
                {viewJson ? "Show text" : "Show JSON"}
              </Button>
              {viewJson ? (
                <JsonView
                  data={order}
                  shouldExpandNode={(level, value, field) =>
                    level >= 0 && field !== "failuresBySchema"
                  }
                  style={defaultStyles}
                />
              ) : (
                <OrderScanFormattedPayloadResult json={order} />
              )}
            </Stack>
            <Stack
              spacing={1}
              sx={{
                overflowY: "scroll",
                height,
                borderLeft: 1,
                borderColor: "lightgray",
              }}
            >
              <Typography sx={{ pl: 1, fontWeight: "bold" }}>
                GPT Output
              </Typography>
              <Box
                sx={{
                  height: "35%",
                  minHeight: 250,
                  overflowY: "scroll",
                }}
              >
                <JsonView
                  data={structuredExtractionRunResult}
                  shouldExpandNode={(level, value, field) =>
                    level >= 0 && field !== "failuresBySchema"
                  }
                  style={defaultStyles}
                />
              </Box>
              <Divider />
              <Box sx={{ pl: 1 }}>
                <StructuredExtractionSchemaEditor
                  schemaUuid={
                    schema?.uuid ?? "8a83d5de-3e97-451a-8cc6-23b444043456"
                  }
                />
              </Box>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Dialog>
  );
};

export default ScannedOrderResultDialog;
