import {
  Alert,
  Button,
  FormHelperText,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import { isEmpty, isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, type SubmitHandler } from "react-hook-form";
import ReactJson from "react-json-view";
import { useImportCrownContactsV2Mutation } from "../../generated/graphql";
import { DragAndDropUpload } from "../common/drag-and-drop-upload";
import CompanySelect from "../doc-scanning/company-select";
import useImportContactsForm, {
  type ContactImporterFormValues,
} from "./use-contact-importer-form";

const ContactsImporter = () => {
  const [importCrownContacts, { loading: importCrownContactsLoading }] =
    useImportCrownContactsV2Mutation();

  const {
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = useImportContactsForm();

  const [successVisible, setSuccessVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);

  const DEFAULT_MESSAGE = "Error importing contacts";
  const [errorMessage, setErrorMessage] = useState(DEFAULT_MESSAGE);
  const [results, setResults] = useState({});

  useEffect(() => {
    reset({
      contactsUrl: "",
    });
  }, [reset]);

  const onSubmit: SubmitHandler<ContactImporterFormValues> = async (data) => {
    const { companyUuid, contactsUrl } = data;

    try {
      const response = await importCrownContacts({
        variables: {
          companyUuid,
          csvUrl: contactsUrl,
          useNewImporter: true,
        },
      });
      const { errors: resErrors } = response;
      if (isEmpty(resErrors)) {
        setResults(response.data?.importCrownContactsV2 ?? {});
        setSuccessVisible(true);
      } else {
        setErrorMessage(
          resErrors?.map((err) => err.message).join(", ") ?? DEFAULT_MESSAGE,
        );
        setErrorVisible(true);
      }
    } catch (error) {
      setErrorVisible(true);
      setErrorMessage(`Error: ${error}`);

      console.error(error);
    }
  };

  return (
    <Grid container spacing={1} sx={{ padding: 4 }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={successVisible}
      >
        <Alert
          severity="success"
          onClose={() => {
            setSuccessVisible(false);
          }}
        >
          Successfully imported contacts
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={errorVisible}
      >
        <Alert
          severity="error"
          onClose={() => {
            setErrorVisible(false);
          }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
        <Typography variant="h4">Crown Contacts Importer</Typography>
        <Typography variant="h6">
          DEPRECATED -- please use the
          <a href="https://interview.retool.com/apps/459104da-81c8-11ef-96a0-573cbca83e45/Bulk%20contact%20editor%20by%20CSV?_releaseVersion=latest">
            Bulk contact editor by CSV tool
          </a>{" "}
          instead
        </Typography>
        <Typography variant="subtitle1">
          IMPORTANT: MAKE SURE CONTACT NAMES MATCH EXACTLY, OTHERWISE THEY WILL
          BE DUPLICATED
        </Typography>
        <Typography variant="subtitle1">
          {`If you would like to include all documents in invoice
                    download, please put INCLUDE_ALL in the 'Documents in
                    Invoice' column`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="companyUuid"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <CompanySelect
              error={error}
              currentCompanyUuid={value}
              onChange={(option) => {
                onChange(option?.value);
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="contactsUrl"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <>
                <DragAndDropUpload
                  fileUrl={value}
                  setPresignedUrl={(url: string) => {
                    onChange(url);
                  }}
                />
                {!isNil(errors.contactsUrl) && (
                  <FormHelperText sx={{ color: "#D32F2F" }}>
                    {errors.contactsUrl.message}
                  </FormHelperText>
                )}
              </>
            );
          }}
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
        <Button
          disabled={importCrownContactsLoading}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          Import Contacts
        </Button>
      </Grid>
      <Grid item xs={12}>
        <ReactJson src={results} />
      </Grid>
    </Grid>
  );
};

export default ContactsImporter;
