import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useLogout from "../hooks/use-logout";

const Home = () => {
  const navigate = useNavigate();
  const logout = useLogout();

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h4">Select the tool you want to use</Typography>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Button
          onClick={() => {
            navigate("/docs");
          }}
        >
          Doc Scanning
        </Button>
        <Button
          onClick={() => {
            navigate("/edi");
          }}
        >
          EDI
        </Button>
        <Button
          onClick={() => {
            navigate("/invoice-transmissions");
          }}
        >
          Invoice Emails
        </Button>
        <Button
          onClick={() => {
            navigate("/canary");
          }}
        >
          Canary
        </Button>
        <Button
          onClick={() => {
            navigate("/code-gen");
          }}
        >
          Code Gen
        </Button>
        <Button
          onClick={() => {
            navigate("/test-orders");
          }}
        >
          Test Orders
        </Button>
        <Button
          onClick={() => {
            navigate("/company-configurations");
          }}
        >
          Company Configurations
        </Button>
        <Button
          onClick={() => {
            navigate("/crown-charges-validator");
          }}
        >
          Crown Charges Validator
        </Button>
        <Button
          onClick={() => {
            navigate("/render");
          }}
        >
          Render
        </Button>
        <Button
          onClick={() => {
            navigate("/sftp-uploads");
          }}
        >
          SFTP Uploads
        </Button>
      </Box>
      <Typography variant="h6">Data importers</Typography>

      <Box sx={{ display: "flex", flexDirection: "row", mb: "auto" }}>
        <Button
          onClick={() => {
            navigate("/contacts-importer");
          }}
        >
          Contacts
        </Button>
        <Button
          onClick={() => {
            navigate("/tariff-importer");
          }}
        >
          Tariffs
        </Button>
        <Button
          onClick={() => {
            navigate("/accessorial-importer");
          }}
        >
          Accessorials
        </Button>
        <Button
          onClick={() => {
            navigate("/orders-importer");
          }}
        >
          Orders
        </Button>
        <Button
          onClick={() => {
            navigate("/users-importer");
          }}
        >
          Users
        </Button>
        <Button
          onClick={() => {
            navigate("/drivers-importer");
          }}
        >
          Drivers
        </Button>
      </Box>
      <Button
        variant="contained"
        sx={{ mt: "auto", alignSelf: "flex-start", mb: 2, ml: 2 }}
        onClick={logout}
      >
        Logout
      </Button>
    </Box>
  );
};

export default Home;
