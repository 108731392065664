import { Card, CardContent } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useOnScreen } from "../../components/common/use-on-screen";
import { type RenderServiceWithDeploysEntity } from "../../generated/graphql";
import RenderServiceTable from "./render-service-table";

const RenderServiceCard = ({
  service,
}: {
  readonly service: RenderServiceWithDeploysEntity;
}) => {
  const cardRef = useRef(null);
  const isVisible = useOnScreen(cardRef);
  const [shouldRender, setShouldRender] = useState<boolean>(false);
  useEffect(() => {
    if (isVisible) {
      setShouldRender(true);
    }
  }, [isVisible]);

  return (
    <Card ref={cardRef} variant="outlined" sx={{ minHeight: 400 }}>
      <CardContent sx={{ pt: 1, px: 1 }}>
        {shouldRender && <RenderServiceTable service={service} />}
      </CardContent>
    </Card>
  );
};

export default RenderServiceCard;
