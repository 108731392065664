import { useNavigate } from "react-router-dom";
import apolloClient from "../apollo-client";
import { useLogoutMutation } from "../generated/graphql";

const useLogout = () => {
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();

  const logoutFunction = async () => {
    // send logout request
    await logout();

    const cookieKey = "appSession";
    document.cookie = `${cookieKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

    // clear apollo cache
    await apolloClient.clearStore();

    navigate("/");
    globalThis?.location.reload();
  };
  return logoutFunction;
};

export default useLogout;
