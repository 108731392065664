import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { isNil, round } from "lodash";
import { Chart } from "primereact/chart";
import React, { useState, memo, useMemo } from "react";
import {
  type OrderVolumeByDateRangeReportQuery,
  ReportAggregationPeriod,
  useOrderVolumeByDateRangeReportQuery,
} from "../../../generated/graphql";
import DateRangePicker, { type DateOption } from "../../date-range-picker";
import { getOrderSourceBucketByOrderSource, OrderSource } from "./utils";

const OrderVolumeStatisticChangeStatistic = ({
  source,
  currentData,
  previousData,
}: {
  readonly source: OrderSource;
  readonly currentData: OrderVolumeByDateRangeReportQuery["orderVolumeByDateRangeReport"][0];
  readonly previousData: OrderVolumeByDateRangeReportQuery["orderVolumeByDateRangeReport"][0];
}) => {
  const currentCount =
    getOrderSourceBucketByOrderSource(source, currentData.sourceAmounts)
      ?.totalCount ?? 0;
  const previousCount =
    getOrderSourceBucketByOrderSource(source, previousData.sourceAmounts)
      ?.totalCount ?? 0;
  const percentageIncrease =
    currentCount === previousCount
      ? 0
      : round((currentCount / previousCount - 1) * 100, 2);
  let color = "black";
  if (percentageIncrease > 10) {
    color = "green";
  } else if (percentageIncrease < -10) {
    color = "red";
  } else if (percentageIncrease > 0) {
    color = "mediumseagreen";
  } else if (percentageIncrease < 0) {
    color = "orange";
  }
  return (
    <Stack>
      <Typography variant="caption" color="text.secondary">
        {source} ({currentCount})
      </Typography>
      <Stack direction="row" alignItems="center">
        {percentageIncrease > 0 && (
          <ArrowUpwardIcon sx={{ color, fontSize: "13px" }} />
        )}
        {percentageIncrease < 0 && (
          <ArrowDownwardIcon sx={{ color, fontSize: "13px" }} />
        )}
        <Typography sx={{ color }}>
          {percentageIncrease === 0 ? "-" : `${Math.abs(percentageIncrease)}%`}
        </Typography>
      </Stack>
    </Stack>
  );
};

const TotalOrderVolumeStatistics = ({
  companyUuid,
}: {
  readonly companyUuid: string;
}) => {
  const [dateOption, setDateOption] = useState<DateOption>({
    startDate: dayjs(new Date()).subtract(7, "days").toDate(),
    endDate: null,
  });
  const { data: dateRangeOrderVolumeData } =
    useOrderVolumeByDateRangeReportQuery({
      variables: {
        orderVolumeByDateRangeInput: {
          companyUuid,
          startDate: dateOption.startDate,
          endDate: dateOption.endDate,
          period: ReportAggregationPeriod.Year,
        },
      },
      fetchPolicy: "cache-first",
    });
  const previousDateStart = dayjs(dateOption.startDate)
    .subtract(
      dayjs(dateOption.endDate ?? new Date()).diff(
        dateOption.startDate,
        "days",
      ),
      "days",
    )
    .toDate();
  const { data: previousDateRangeOrderVolumeData } =
    useOrderVolumeByDateRangeReportQuery({
      variables: {
        orderVolumeByDateRangeInput: {
          companyUuid,
          startDate: previousDateStart,
          endDate: dateOption.startDate,
          period: ReportAggregationPeriod.Year,
        },
      },
      fetchPolicy: "cache-first",
    });

  const currentOrderVolumeData =
    dateRangeOrderVolumeData?.orderVolumeByDateRangeReport[0];
  const previousOrderVolumeData =
    previousDateRangeOrderVolumeData?.orderVolumeByDateRangeReport[0];

  const chartData = useMemo(() => {
    if (isNil(currentOrderVolumeData)) return {};
    return {
      labels: Object.values(OrderSource),
      datasets: [
        {
          label: "#",
          data: Object.values(OrderSource).map(
            (s) =>
              getOrderSourceBucketByOrderSource(
                s,
                currentOrderVolumeData.sourceAmounts,
              )?.totalCount ?? 0,
          ),
        },
      ],
    };
  }, [currentOrderVolumeData]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <DateRangePicker
        excludeAll
        dateOption={dateOption}
        onChange={setDateOption}
      />
      {!isNil(currentOrderVolumeData) &&
        !isNil(previousOrderVolumeData) &&
        Object.values(OrderSource).map((source) => (
          <OrderVolumeStatisticChangeStatistic
            key={source}
            source={source}
            currentData={currentOrderVolumeData}
            previousData={previousOrderVolumeData}
          />
        ))}
      <Chart
        height="70px"
        type="pie"
        data={chartData}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          layout: {
            padding: {
              left: 0,
              top: 0,
              bottom: 0,
              right: 5,
            },
          },
        }}
      />
    </Stack>
  );
};

export default memo(TotalOrderVolumeStatistics);
