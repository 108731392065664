import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPermissionsFlags } from "shared/roles";
import {
  LoginStatus,
  PermissionResource,
  useLoginInternalMutation,
} from "../generated/graphql";
import useUserRoles from "../hooks/use-user-roles";

type FormValue = {
  value: string;
  error: string;
};

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<FormValue>({ value: "", error: "" });
  const [password, setPassword] = useState<FormValue>({
    value: "",
    error: "",
  });
  const [error, setError] = useState<string>("");

  const { userRolesLoading, userPermissions } = useUserRoles();
  const { canRead: canReadInternalTools } = getPermissionsFlags(
    userPermissions,
    PermissionResource.InternalTools,
  );

  const [login, { loading: loginLoading }] = useLoginInternalMutation();

  useEffect(() => {
    if (userRolesLoading) {
      return;
    }
    if (canReadInternalTools) {
      navigate("/home");
    }
  }, [canReadInternalTools, userRolesLoading]);

  const handleLogin = async () => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (email && password) {
      const loginResult = await login({
        variables: {
          loginInputData: {
            email: email.value,
            password: password.value,
          },
        },
      });
      if (loginResult.data?.loginInternal?.status === LoginStatus.Success) {
        navigate("/home");
      } else {
        setError(loginResult.data?.loginInternal?.status as string);
      }
    }
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <TextField
        data-cy="email"
        id="outlined-basic"
        label="Email"
        onChange={(event) => {
          setEmail({ value: event.target.value, error: "" });
        }}
        sx={{ mb: 1 }}
        value={email.value}
        variant="outlined"
      />
      <TextField
        data-cy="password"
        id="outlined-basic"
        label="Password"
        onChange={(event) => {
          setPassword({ value: event.target.value, error: "" });
        }}
        sx={{ mb: 1 }}
        type="password"
        value={password.value}
        variant="outlined"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Button
          data-cy="login"
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          disabled={!email || !password}
          onClick={handleLogin}
          startIcon={loginLoading && <CircularProgress />}
          sx={{ mr: 1 }}
          variant="contained"
        >
          Login
        </Button>
      </Box>
      <Typography color="red" data-cy="error">
        {sentenceCase(error)}
      </Typography>
    </Box>
  );
};

export default LoginPage;
