import {
  Grid,
  FormHelperText,
  InputLabel,
  TextField,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { isNil } from "lodash";
import { useState } from "react";
import { Controller, type SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  TestOrdersDocument,
  useCreateTestOrderV2Mutation,
} from "../../generated/graphql";
import useCreateTestOrderForm, {
  type CreateTestOrderFormValues,
} from "./use-create-test-order-form";

const CreateTestOrderForm = () => {
  const navigate = useNavigate();
  const {
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = useCreateTestOrderForm();

  const [createSuccessVisible, setCreateSuccessVisible] = useState(false);
  const [createErrorVisible, setCreateErrorVisible] = useState(false);

  const [createTestOrderV2, { loading: createTestOrderV2Loading }] =
    useCreateTestOrderV2Mutation({ refetchQueries: [TestOrdersDocument] });

  const onSubmit: SubmitHandler<CreateTestOrderFormValues> = async (data) => {
    const { orderUuid, totalCharge, totalFreightCharge, totalFuelCharge } =
      data;
    try {
      const response = await createTestOrderV2({
        variables: {
          createTestOrderRequest: {
            orderUuid,
            totalCharge: Number.parseFloat(totalCharge),
            totalFreightCharge: Number.parseFloat(totalFreightCharge),
            totalFuelCharge: Number.parseFloat(totalFuelCharge),
          },
        },
      });
      if (response.data?.createTestOrderV2.success === true) {
        reset();
        setCreateSuccessVisible(true);
        navigate("/test-orders");
      } else {
        setCreateErrorVisible(true);
      }
    } catch {
      setCreateErrorVisible(true);
    }
  };

  return (
    <Grid container spacing={2} style={{ padding: 12 }}>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={createSuccessVisible}
        onClose={() => {
          setCreateSuccessVisible(false);
        }}
      >
        <Alert>Created</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={createErrorVisible}
        onClose={() => {
          setCreateErrorVisible(false);
        }}
      >
        <Alert severity="error">Failed to save</Alert>
      </Snackbar>
      <Grid item xs={4}>
        <Controller
          name="orderUuid"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <InputLabel>Order UUID</InputLabel>
              <TextField
                required
                name="orderUuid"
                size="small"
                value={value}
                error={!isNil(errors.orderUuid)}
                sx={{ width: "50%" }}
                onChange={onChange}
              />
              {!isNil(errors.orderUuid) && (
                <FormHelperText sx={{ color: "#D32F2F" }}>
                  {errors.orderUuid?.message ?? ""}
                </FormHelperText>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="totalCharge"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <InputLabel>Expected Total Charge</InputLabel>
              <TextField
                required
                name="totalCharge"
                size="small"
                type="number"
                value={value}
                error={!isNil(errors.totalCharge)}
                sx={{ width: "50%" }}
                onChange={onChange}
              />
              {!isNil(errors.totalCharge) && (
                <FormHelperText sx={{ color: "#D32F2F" }}>
                  {errors.totalCharge?.message ?? ""}
                </FormHelperText>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="totalFreightCharge"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <InputLabel>Expected Total Freight Charge</InputLabel>
              <TextField
                required
                name="totalFreightCharge"
                size="small"
                type="number"
                value={value}
                error={!isNil(errors.totalFreightCharge)}
                sx={{ width: "50%" }}
                onChange={onChange}
              />
              {!isNil(errors.totalFreightCharge) && (
                <FormHelperText sx={{ color: "#D32F2F" }}>
                  {errors.totalFreightCharge?.message ?? ""}
                </FormHelperText>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="totalFuelCharge"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <InputLabel>Expected Total Fuel Charge</InputLabel>
              <TextField
                required
                name="totalFuelCharge"
                size="small"
                type="number"
                value={value}
                error={!isNil(errors.totalFuelCharge)}
                sx={{ width: "50%" }}
                onChange={onChange}
              />
              {!isNil(errors.totalFuelCharge) && (
                <FormHelperText sx={{ color: "#D32F2F" }}>
                  {errors.totalFuelCharge?.message ?? ""}
                </FormHelperText>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          disabled={createTestOrderV2Loading}
          onClick={handleSubmit(onSubmit)}
        >
          Create
        </Button>
      </Grid>
    </Grid>
  );
};

export default CreateTestOrderForm;
