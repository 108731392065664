import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Button,
  Checkbox,
  Chip,
  Fade,
  Link,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ScannedDocumentType,
  type SearchSchemasQuery,
} from "../../../generated/graphql";
import SchemaCompanyMappingsModal from "../schema-testing/schema-company-mappings/schema-company-mappings-modal";

TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo("en-US");

const ScannedDocumentTypeChip = ({
  scannedDocumentType,
}: {
  readonly scannedDocumentType: ScannedDocumentType;
}) => {
  switch (scannedDocumentType) {
    case ScannedDocumentType.DeliveryAlert: {
      return <Chip size="small" color="success" label="Delivery Alert" />;
    }
    case ScannedDocumentType.DeliveryReceipt: {
      return <Chip size="small" color="info" label="Delivery Receipt" />;
    }
    case ScannedDocumentType.None: {
      return <Chip size="small" color="default" label="None" />;
    }
    case ScannedDocumentType.PickupAlert: {
      return <Chip size="small" color="warning" label="Pickup Alert" />;
    }
    default: {
      return <Chip size="small" label="NA" />;
    }
  }
};

const SchemaListRow = ({
  companyUuid,
  isSelected,
  schema,
  onSelect,
  onGoToSchema,
}: {
  readonly companyUuid: string | undefined;
  readonly isSelected: boolean;
  readonly schema: SearchSchemasQuery["searchSchemas"][0];
  readonly onSelect: () => void;
  readonly onGoToSchema: () => void;
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [schemaCompanyMappingsModalOpen, setSchemaCompanyMappingsModalOpen] =
    useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <TableRow
      key={schema.uuid}
      sx={{
        cursor: "pointer",
        backgroundColor: isHovered || isSelected ? "#f3f3f3" : undefined,
      }}
      onClick={() => {
        onSelect();
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <TableCell>
        <Checkbox checked={isSelected} />
      </TableCell>
      <TableCell>{dayjs(schema.createdAt).format("M/DD/YY HH:mm")}</TableCell>
      <TableCell>{timeAgo.format(new Date(schema.updatedAt))}</TableCell>
      <TableCell>
        <Link
          sx={{
            textDecoration: "none",
            cursor: "pointer",
          }}
          onClick={() => {
            onGoToSchema();
            navigate(`/docs/?tab=0`);
          }}
        >
          {schema.name}
        </Link>
      </TableCell>
      <TableCell>
        <ScannedDocumentTypeChip
          scannedDocumentType={schema.scannedDocumentType}
        />
      </TableCell>
      <TableCell>
        <Checkbox disabled checked={schema.isActive} />
      </TableCell>
      <TableCell>{schema.companyMappingsCount}</TableCell>
      <TableCell>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography sx={{ fontSize: 14 }}>
            {schema.testDocumentCount}
          </Typography>
          {schema.testDocumentCount === 0 && (
            <WarningAmberIcon sx={{ fontSize: 14, color: "red" }} />
          )}
        </Stack>
      </TableCell>
      <TableCell>
        <Fade in={isHovered}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setSchemaCompanyMappingsModalOpen(true);
            }}
          >
            View
          </Button>
        </Fade>
      </TableCell>
      {schemaCompanyMappingsModalOpen && (
        <SchemaCompanyMappingsModal
          open={schemaCompanyMappingsModalOpen}
          setOpen={setSchemaCompanyMappingsModalOpen}
          schema={schema}
          selectedCompanyUuid={companyUuid}
        />
      )}
    </TableRow>
  );
};

export default SchemaListRow;
