import { Button, Menu, MenuItem } from "@mui/material";
import { sentenceCase } from "change-case";
import { isNil } from "lodash";
import { useState } from "react";
import { ReportAggregationPeriod } from "../generated/graphql";

const TimeRangePeriodPicker = ({
  period,
  onChange,
}: {
  readonly period: ReportAggregationPeriod;
  readonly onChange: (period: ReportAggregationPeriod) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        Period: {sentenceCase(period)}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={!isNil(anchorEl)}
        sx={{ zIndex: 10_000 }}
        onClose={onClose}
      >
        <MenuItem
          onClick={() => {
            onChange(ReportAggregationPeriod.Day);
          }}
        >
          Day
        </MenuItem>
        <MenuItem
          onClick={() => {
            onChange(ReportAggregationPeriod.Week);
          }}
        >
          Week
        </MenuItem>
        <MenuItem
          onClick={() => {
            onChange(ReportAggregationPeriod.Month);
          }}
        >
          Month
        </MenuItem>
        <MenuItem
          onClick={() => {
            onChange(ReportAggregationPeriod.Year);
          }}
        >
          Year
        </MenuItem>
      </Menu>
    </>
  );
};

export default TimeRangePeriodPicker;
