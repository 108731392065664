import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
} from "@mui/material";
import { isNil } from "lodash";
import React, { type Dispatch, type SetStateAction, useState } from "react";
import Select from "react-select";
import { type StructuredExtractionOverride } from "../types";

const AddOverrideDialog = ({
  open,
  setOpen,
  setSchemaOverride,
  title,
  basePath,
  overrides,
}: {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly setSchemaOverride: Dispatch<SetStateAction<any>>;
  readonly title: string;
  readonly basePath: string[];
  readonly overrides: StructuredExtractionOverride[];
}) => {
  const options = overrides.map((o) => ({ ...o, value: o.label }));
  const [override, setOverride] = useState<StructuredExtractionOverride>();
  const onAdd = () => {
    if (isNil(override)) return;
    const path = basePath.concat(override.path);
    const newKey = path.at(-1);

    if (isNil(newKey)) return;

    setSchemaOverride((previous: any) => {
      let current = previous;
      for (const key of path.slice(0, -1)) {
        if (!(key in current)) {
          current[key] = {};
        }
        current = current[key];
      }
      current[newKey] = {
        ...current[newKey],
        description: override.default,
      };
      return previous;
    });
    setOverride(undefined);
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      sx={{ zIndex: 2000 }}
      open={open}
      maxWidth="xs"
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <Stack sx={{ height: 250, px: 3 }}>
        <Select
          options={options}
          placeholder="Select override"
          value={override ?? null}
          onChange={(option: StructuredExtractionOverride | null) => {
            if (!isNil(option)) {
              setOverride(option);
            }
          }}
        />
      </Stack>
      <DialogActions>
        <Button onClick={onAdd}>Add</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOverrideDialog;
