import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import { isEmpty, isNil } from "lodash";
import { useEffect } from "react";
import { Controller, type SubmitHandler } from "react-hook-form";
import {
  CustomChargeBillingMethod,
  type FindTestOrderFragment,
  TestOrderDocument,
  useCreateTestOrderCustomChargeMutation,
} from "../../generated/graphql";
import useCreateTestOrderCustomChargeForm, {
  type CreateTestOrderCustomChargeFormValues,
} from "./use-create-test-order-custom-charge-form";

type AddTestOrderCustomChargeModalProps = {
  readonly testOrder: FindTestOrderFragment;
  readonly open: boolean;

  readonly onClose: () => void;
};
const AddTestOrderCustomChargeModal = ({
  testOrder,
  open,
  onClose,
}: AddTestOrderCustomChargeModalProps) => {
  const { customCharges } = testOrder.order;

  console.log(`CC: ${JSON.stringify(customCharges)}`);
  const {
    reset,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useCreateTestOrderCustomChargeForm();

  useEffect(() => {
    const firstCustomCharge = customCharges?.[0];
    if (!isNil(firstCustomCharge)) {
      setValue("customChargeUuid", firstCustomCharge.uuid);
    }
  }, [customCharges]);

  const [
    createTestOrderCustomCharge,
    { loading: createTestOrderCustomChargeLoading },
  ] = useCreateTestOrderCustomChargeMutation({
    refetchQueries: [TestOrderDocument],
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit: SubmitHandler<CreateTestOrderCustomChargeFormValues> = async (
    data,
  ) => {
    const { customChargeUuid, total } = data;
    try {
      await createTestOrderCustomCharge({
        variables: {
          createTestOrderCustomChargeInput: {
            customChargeUuid,
            testOrderUuid: testOrder.uuid,
            total: Number.parseFloat(total),
          },
        },
      });
      handleClose();
    } catch {
      /**/
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Test Order Custom Charge</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="customChargeUuid"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel id="age">Custom Charge</InputLabel>
                  <Select
                    native
                    size="small"
                    value={value}
                    label="Secondary Unit of Measure"
                    error={!isNil(errors.customChargeUuid)}
                    sx={{ width: "100%" }}
                    disabled={isEmpty(customCharges)}
                    onChange={onChange}
                  >
                    {customCharges?.map((customCharge) => {
                      const name =
                        customCharge.billingMethod ===
                        CustomChargeBillingMethod.Accessorial
                          ? customCharge?.accessorialTemplate?.name
                          : customCharge.name;
                      return (
                        <option
                          key={customCharge.uuid}
                          value={customCharge.uuid}
                        >
                          {`${name} (${customCharge.uuid})`}
                        </option>
                      );
                    })}
                  </Select>
                  {!isNil(errors.customChargeUuid) && (
                    <FormHelperText sx={{ color: "#D32F2F" }}>
                      {errors.customChargeUuid.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="total"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel>Expected Total Charge</InputLabel>
                  <TextField
                    required
                    name="total"
                    size="small"
                    type="number"
                    value={value}
                    error={!isNil(errors.total)}
                    sx={{ width: "50%" }}
                    onChange={onChange}
                  />
                  {!isNil(errors.total) && (
                    <FormHelperText sx={{ color: "#D32F2F" }}>
                      {errors.total?.message ?? ""}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={createTestOrderCustomChargeLoading}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={createTestOrderCustomChargeLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTestOrderCustomChargeModal;
